angular
    .module('summit', [
        'ngMaterial',
        'ngAnimate',
        'ngSanitize',
        'ngCookies',
        'ui.router',
        'rx',
        'pascalprecht.translate',
        'summit.model',
        'summit.filter',
        'summit.service',
        'summit.content',
        'summit.directive',
        'duScroll'
    ])
    .config(['$locationProvider', function ($locationProvider) {
        $locationProvider.html5Mode(true);
        $locationProvider.hashPrefix('!');
    }])
    .config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise('/');
        $stateProvider
            .state('main', {
                url: '/',
                templateUrl: 'templates/main.html',
                controller: 'CtrlMain',
                controllerAs: 'main',
                abstract: true
            })
            .state('main.section', {
                url: '',
                templateUrl: 'templates/section.html',
                controller: 'CtrlMainSection',
                controllerAs: 'section'
            })
            .state('main.speaker', {
                url: 'speaker',
                templateUrl: 'templates/speaker.html',
                controller: 'CtrlMainSpeaker',
                controllerAs: 'speaker'
            })
            .state('main.team', {
                url: 'team',
                templateUrl: 'templates/team.html',
                controller: 'CtrlMainTeam',
                controllerAs: 'team'
            })
            // .state('main.media', {
            //     url: 'media',
            //     templateUrl: 'templates/media.html',
            //     controller: 'CtrlMainMedia',
            //     controllerAs: 'media'
            // })
            .state('main.partner', {
                url: 'partner',
                templateUrl: 'templates/partner.html',
                controller: 'CtrlMainPartner',
                controllerAs: 'partner'
            });
        // .state('main.teamApply', {
        //     url: 'apply',
        //     templateUrl: 'templates/apply.html',
        //     controller: 'CtrlMainApply',
        //     controllerAs: 'apply'
        // })
        // .state('main.applyConfirmation', {
        //     url: 'applyConfirmation',
        //     templateUrl: 'templates/applyConfirmation.html',
        //     controller: 'CtrlMainApplyConfirmation',
        //     controllerAs: 'applyConfirmation'
        // });
    }])
    .config(['$mdThemingProvider', function ($mdThemingProvider) {
        $mdThemingProvider.theme('default').primaryPalette('blue');
    }])
    .config(['$translateProvider', 'Locale', function ($translateProvider, Locale) {
        $translateProvider
            .translations('zh', Locale.zh)
            .translations('en', Locale.en)
            .preferredLanguage('zh')
            .useSanitizeValueStrategy('sanitizeParameters')
            .useLocalStorage();
    }])
    .run(['$rootScope', '$translate', '$window', 'weixin', function ($rootScope, $translate, $window, weixin) {
        var storage = $translate.storage();
        var key = $translate.storageKey();
        if (storage && key) {
            $rootScope.lang = storage.get(key) || 'zh';
        }
        $rootScope.$on('$translateChangeSuccess', function (event, data) {
            $rootScope.lang = data.language;
            $rootScope.$broadcast('lang', data.language);
        });
        $rootScope.switchLang = function () {
            $translate.use($rootScope.lang === 'zh' ? 'en' : 'zh');
        };
        var ua = $window.navigator.userAgent.toLowerCase();
        $rootScope.isIE = ua.indexOf('trident') > -1;
        $rootScope.isChrome = ua.indexOf('chrome') > -1;
        $rootScope.isSafari = ua.indexOf('safari') > -1;
        $rootScope.isWebKit = ua.indexOf('applewebkit') > -1;
        $rootScope.isWeixin = ua.indexOf('micromessenger') > -1;
        $rootScope.isIphone = ua.indexOf('iphone') > -1;
        $rootScope.isIpad = ua.indexOf('ipad') > -1;
        $rootScope.isOldVersion = false;
        $rootScope.currentSection = 'first';
        var versionRes = ua.match(/applewebkit\/(\d+\.\d+)/);
        if (versionRes && versionRes[1]) {
            var version = parseFloat(versionRes[1]);
            $rootScope.isOldVersion = (version < 537.36);
        }
        $rootScope.$on('$stateChangeStart', function () {
            return weixin;
        });
    }]);

angular.module('summit.model', []);

/**
 * Created by j3_z2Z on 6/30/2016.
 */

angular.module('summit.model').factory('Speaker', function () {

    var zh = {
        // 中文主会场嘉宾
        keynote: [
            {
                avatarUrl: 'assets/images/speakers/Cen-Wang.png',
                name: '王岑',
                description: '红杉资本中国基金 合伙人'
            }, {
                avatarUrl: 'assets/images/speakers/Xiang-Pan.jpg',
                name: '潘翔',
                description: '光速创投 合伙人'
            }, {
                avatarUrl: 'assets/images/speakers/Xiaoyu-Xu.jpg',
                name: '徐霄羽',
                description: '丰元资本 管理合伙人'
            }, {
                avatarUrl: 'assets/images/speakers/Jiansheng-Li.png',
                name: '李健生',
                description: '美中合作发展委员会执行主席 & 上海张江波士顿企业园董事长'
            }, {
                avatarUrl: 'assets/images/speakers/George-Chen.jpeg',
                name: 'George Chen',
                description: '荷马金融 联合创始人 & CIO'
            }, {
                avatarUrl: 'assets/images/speakers/Zhang-Lu.png',
                name: '张璐',
                description: 'Fusion Fund 创始合伙人 & 福布斯美国 30/30 '
            }, {
                avatarUrl: 'assets/images/speakers/Li-Feng.jpg',
                name: '李丰',
                description: '峰瑞资本 创始人'
            }, {
                avatarUrl: 'assets/images/speakers/Hao-Zou.png',
                name: '邹昊',
                description: '福布斯全球 30/30 & 斯坦福客座教授'
            }, {
                avatarUrl: 'assets/images/speakers/Hongcai-Guo.jpg',
                name: '郭宏才',
                description: '著名天使投资人'
            }, {
                avatarUrl: 'assets/images/speakers/Yiting-Liu.jpg',
                name: '刘亦婷',
                description: '秦岭资本合伙人'
            }, {
                avatarUrl: 'assets/images/speakers/Yi-Guo.jpg',
                name: '郭易',
                description: '万通证券 COO'
            }, {
                avatarUrl: 'assets/images/speakers/Zhang-Hongmei.jpg',
                name: '张红梅',
                description: '阳光印网创始人&CEO'
            }, {
                avatarUrl: 'assets/images/speakers/Lex Fenwick.jpg',
                name: 'Lex Fenwick',
                description: 'Bloomberg & Dow Jones 前 CEO，Heckyl Chairman and CEO'
            }, {
                avatarUrl: 'assets/images/speakers/Shaoyuan-Fu.jpeg',
                name: '付少远',
                description: 'National Securities Corp.资深注册投资顾问'
            }, {
                avatarUrl: 'assets/images/speakers/Brittany-McDonough.jpeg',
                name: 'Brittany McDonough',
                description: 'MassChallenge 全球合作总监'
            }, {
                avatarUrl: 'assets/images/speakers/Zhang-Yao.jpg',
                name: '张尧',
                description: '萝卜太辣创始人&CEO'
            }, {
                avatarUrl: 'assets/images/speakers/David-Chang.jpeg',
                name: 'David Chang',
                description: 'PersonalVC 联合创始人 & 哈佛商学院的 EIR'
                   }],
        // 中文分会场嘉宾
        panel: [
            {
                avatarUrl: 'assets/images/speakers/Dinghong-Chen.jpeg',
                name: '陈丁鸿',
                description: '好未来北美投资 负责人'
            }, {
                avatarUrl: 'assets/images/speakers/Tao-Wu.jpeg',
                name: '邬涛',
                description: '微软人工智能和研究部门 高级数据分析经理'
            }, {
                avatarUrl: 'assets/images/speakers/Yining-Cui.jpeg',
                name: '崔易宁',
                description: '易美留学 创始人'
            }, {
                avatarUrl: 'assets/images/speakers/Cole-Scanlon.png',
                name: 'Cole Scanlon',
                description: 'Fair Opportunity Project 联合创始人'
            },{
                avatarUrl: 'assets/images/speakers/Yide-Jiang.jpeg',
                name: '蒋一得',
                description: '晶泰科技 首席战略官'
            }, {
                avatarUrl: 'assets/images/speakers/Qiang-Wang.jpeg',
                name: '王强',
                description: 'Menusifu 联合创始人 & CEO'
            }, {
                avatarUrl: 'assets/images/speakers/Millie-Liu.png',
                name: 'Millie Liu',
                description: 'Procyon Ventures 联合创始人'
            }, {
                avatarUrl: 'assets/images/speakers/Ziming-Zheng.jpeg',
                name: '郑自明',
                description: 'TripAdvisor Inc. 软件工程师'
            }, {
                avatarUrl: 'assets/images/speakers/April-Huang.png',
                name: 'April Huang',
                description: '北美省钱快报东岸负责人'
            }, {
                avatarUrl: 'assets/images/speakers/Liang-Enze.jpg',
                name: '梁恩泽',
                description: 'Marcum Bernstein & Pinchuk LLP 董事'
            }, {
                avatarUrl: 'assets/images/speakers/Feng-Tan.png',
                name: '谭丰',
                description: 'Woboo 联合创始人'
            }, {
                avatarUrl: 'assets/images/speakers/Yang-Ziye.jpg',
                name: '杨子叶',
                description: '可美亚跨境营销咨询公司 CEO'
            }, {
                avatarUrl: 'assets/images/speakers/Wang-Guan.jpg',
                name: '王冠',
                description: 'Sunny Crown 联合创始⼈'
            }, {
                avatarUrl: 'assets/images/speakers/Li-Chen.jpg',
                name: '李琛',
                description: '燎原 联合创始人'
            }, {
                avatarUrl: 'assets/images/speakers/Xiangrui-Kong.png',
                name: '孔祥瑞',
                description: '新魂娱乐 创始人'
            }, {
                avatarUrl: 'assets/images/speakers/Ma-Wener.jpg',
                name: '马汶儿',
                description: 'NEBULAR星云 联合创始人'
            }, {
                avatarUrl: 'assets/images/speakers/Fung-Vincent.jpg',
                name: 'Vincent Fung',
                description: 'IBIS资产 投资合伙人'
            }, {
                avatarUrl: 'assets/images/speakers/Xichun-Pan.jpeg',
                name: '潘惜唇',
                description: 'Dorsey & Whitney全美及中国联合业务 主席'
            }, {
                avatarUrl: 'assets/images/speakers/Yi-Li-Amazon.jpeg',
                name: '李轶',
                description: 'Amazon Rekognition 负责人'
            }, {
                avatarUrl: 'assets/images/speakers/Yu-Wang.jpg',
                name: '王昱',
                description: 'IDG资本 北美项目经理'
            }, {
                avatarUrl: 'assets/images/speakers/Joe-Sun.jpg',
                name: 'Joe Q. SUN',
                description: 'CIG，资本动力集团有限公司 CEO'
            }, {
                avatarUrl: 'assets/images/speakers/Yifei-Ju.jpg',
                name: '居易非',
                description: '点亮资产管理 项目合伙人'
            }, {
                avatarUrl: 'assets/images/speakers/George-Gong.jpeg',
                name: 'George Gong',
                description: 'Prominent Venture Capital 合伙人'
            }, {
                avatarUrl: 'assets/images/speakers/Pei-Qi.jpeg',
                name: '祁培',
                description: 'BRC 创新基金 管理合伙人'
            }, {
                avatarUrl: 'assets/images/speakers/Yi-Li.jpg',
                name: '李祎',
                description: '众方资本 合伙人'
            }, {
                avatarUrl: 'assets/images/speakers/Beibei-Li.jpg',
                name: '李贝贝',
                description: '真格基金 美东负责人'
            }, {
                avatarUrl: 'assets/images/speakers/Richard-Di-Tieri.jpg',
                name: 'Richard Di Tieri',
                description: 'Startup Institute CEO'
            }, {
                avatarUrl: 'assets/images/speakers/Da-Gui.jpeg',
                name: '桂达',
                description: 'e乡e家 创始人 '
            }, {
                avatarUrl: 'assets/images/speakers/You-Zhou.jpeg',
                name: '周游',
                description: '亚米网 创始人'
            }, {
                avatarUrl: 'assets/images/speakers/Bowen-Zhao.jpg',
                name: '赵柏闻',
                description: '北京量化健康科技有限公司 CEO'
            }, {
                avatarUrl: 'assets/images/speakers/Qi-Su.jpg',
                name: '苏奇',
                description: 'MODELO 联合创始人 & CEO 苏奇'
            }, {
                avatarUrl: 'assets/images/speakers/Guoyu-Lin.jpg',
                name: '林国宇',

                description: '留学生日报 创始人'
            }, {
                avatarUrl: 'assets/images/speakers/Yutong-Gao.jpg',
                name: '高宇同',
                description: '易思汇 联合创始人'
            }, {
                avatarUrl: 'assets/images/speakers/Jingyuan-Wang.jpg',
                name: '王静远',
                description: '牛犊资本 创始合伙人'
            }, {
                avatarUrl: 'assets/images/speakers/Amy-Duan.png',
                name: 'Amy Duan',
                description: '吃货小分队 创始人&CEO'
            }, {
                avatarUrl: 'assets/images/speakers/Hang-Tian.jpeg',
                name: '田行',
                description: 'NYIS 联合创始人'
            }, {
                avatarUrl: 'assets/images/speakers/ Donghui-Zhang.jpg',
                name: 'Donghui Zhang',
                description: 'Facebook 大数据架构专家'
            }, {
                avatarUrl: 'assets/images/speakers/Changfeng-Wang.jpeg',
                name: '王长风',
                description: 'Adelphic 联合创始人'
            }, {
                avatarUrl: 'assets/images/speakers/Jiangshan-Cao.jpg',
                name: 'Victor Cao',
                description: 'Even-Better 产品与运营总监'
            }, {
                avatarUrl: 'assets/images/speakers/Chen-Zhao.jpg',
                name: '赵晨',
                description: '屹杉资本管理合伙人'
            }, {
                avatarUrl: 'assets/images/speakers/Ben-Tao.jpg',
                name: 'Ben Tao',
                description: 'PTC Corporate Development VP'
            }, {
                avatarUrl: 'assets/images/speakers/Zinan-Chen.jpg',
                name: '陈紫楠',
                description: 'UniWill Ventures 创始人'
            },{
                avatarUrl: 'assets/images/speakers/Miao-Cao.jpeg',
                name: '曹苗',
                description: '麦肯锡公司金融人才管理北美负责人'
            },{
                avatarUrl: 'assets/images/speakers/Ren-Du.jpg',
                name: '杜韧',
                description: '启发资本 管理合伙人'
            }]
    };

    var en = {
        // 英文主会场嘉宾
        keynote: [
            {
                avatarUrl: 'assets/images/speakers/Cen-Wang.png',
                name: 'Cen Wang',
                description: 'Sequoia Capital China, Partner'
            }, {
                avatarUrl: 'assets/images/speakers/Xiang-Pan.jpg',
                name: 'Zac Pan',
                description: 'Lightspeed China Partners, Associate Partner'
            },  {
                avatarUrl: 'assets/images/speakers/Xiaoyu-Xu.jpg',
                name: 'Sue Xu',
                description: 'Amino Capital, General Partner'
            }, {
                avatarUrl: 'assets/images/speakers/George-Chen.jpeg',
                name: 'George Chen',
                description: 'Homaer Financial，Co-Founder & CIO'
            }, {
                avatarUrl: 'assets/images/speakers/Jiansheng-Li.png',
                name: 'Jiansheng Li',
                description: 'Zhangjiang Hi-Tech Park（Boston） Chairman'
            }, {
                avatarUrl: 'assets/images/speakers/Zhang-Lu.png',
                name: 'Lu Zhang',
                description: 'Fusion Fund Capital, Founding and Managing Partner Forbes US 30 under 30 & Featured as the Honoree of VC category'
            }, {
                avatarUrl: 'assets/images/speakers/Li-Feng.jpg',
                name: 'Feng Li',
                description: 'Founding Partner, Frees Fund'
            }, {
                avatarUrl: 'assets/images/speakers/Hao-Zou.png',
                name: 'Hao Zou',
                description: 'Abundy, CEO & Founder Forbes US 30 under 30, Visiting Full Professor at Stanford University'
            }, {
                avatarUrl: 'assets/images/speakers/Hongcai-Guo.jpg',
                name: 'Chandler Guo',
                description: 'Well-known Angel Investor'
            }, {
                avatarUrl: 'assets/images/speakers/Yiting-Liu.jpg',
                name: 'Yiting Liu',
                description: 'Coalescence Partners, Investment Management Partner'
            }, {
                avatarUrl: 'assets/images/speakers/Yi-Guo.jpg',
                name: 'Yi Guo',
                description: 'Univest Securities, COO'
            }, {
                avatarUrl: 'assets/images/speakers/Zhang-Hongmei.jpg',
                name: 'May Cheung',
                description: 'Easy Print, Founder & CEO'
            }, {
                avatarUrl: 'assets/images/speakers/Lex Fenwick.jpg',
                name: 'Lex Fenwick',
                description: 'Bloomberg & Dow Jones, Former CEO Heckyl, Chairman and CEO'
            }, {
                avatarUrl: 'assets/images/speakers/David-Chang.jpeg',
                name: 'David Chang',
                description: 'PersonalVC Co-Founder & HBS EIR'
            },{
                avatarUrl: 'assets/images/speakers/Shaoyuan-Fu.jpeg',
                name: 'Shaoyuan Fu',
                description: 'National Securities Corp. Senior Registered Investment Advisor'
            },{
                avatarUrl: 'assets/images/speakers/Brittany-McDonough.jpeg',
                name: 'Brittany McDonough',
                description: 'The Director of Global Partnerships at MassChallenge'
            },{
                avatarUrl: 'assets/images/speakers/Zhang-Yao.jpg',
                name: 'Yao Zhang',
                description: 'RoboTerra，Founder & CEO'
            }],
        // 英文分会场嘉宾
        panel: [
            {
                avatarUrl: 'assets/images/speakers/Dinghong-Chen.jpeg',
                name: 'Dinghong Chen',
                description: 'Tomorrow Advancing Life, Investment Director'
            }, {
                avatarUrl: 'assets/images/speakers/Tao-Wu.jpeg',
                name: 'Tao Wu',
                description: 'Microsoft‘s AI and Research Group， Principal Data Scientist Manger'
            }, {
                avatarUrl: 'assets/images/speakers/Yining-Cui.jpeg',
                name: 'Yining Cui',
                description: 'Easymay Founder & CEO'
            }, {
                avatarUrl: 'assets/images/speakers/Cole-Scanlon.png',
                name: 'Cole Scanlon',
                description: 'Fair Opportunity Project Co-Founder'
            }, {
                avatarUrl: 'assets/images/speakers/Millie-Liu.png',
                name: 'Millie Liu',
                description: 'Procyon Ventures Co-Founder'
            }, {
                avatarUrl: 'assets/images/speakers/Yide-Jiang.jpeg',
                name: 'Alan Jiang',
                description: 'Xtalpi Inc, Chief Strategy Officer'
            }, {
                avatarUrl: 'assets/images/speakers/Qiang-Wang.jpeg',
                name: 'William Wang',
                description: 'Menusifu Co-Founder & CEO'
            }, {
                avatarUrl: 'assets/images/speakers/Liang-Enze.jpg',
                name: 'Enze Liang',
                description: 'Marcum Bernstein & Pinchuk LLP，Director'
            }, {
                avatarUrl: 'assets/images/speakers/April-Huang.png',
                name: 'April Huang',
                description: 'Dealmoon East Coast, Regional Director'
            }, {
                avatarUrl: 'assets/images/speakers/Yang-Ziye.jpg',
                name: 'Jennifer Yang',
                description: 'Co.Media，CEO'
            }, {
                avatarUrl: 'assets/images/speakers/Feng-Tan.png',
                name: 'Feng Tan',
                description: 'Woboo, Co-Founder'
            }, {
                avatarUrl: 'assets/images/speakers/Fung-Vincent.jpg',
                name: 'Vincent Fung',
                description: 'IBIS Capital, Investment Partner'
            }, {
                avatarUrl: 'assets/images/speakers/Wang-Guan.jpg',
                name: 'Guan Wang',
                description: 'Sunny Crown, Co-Founder'
            }, {
                avatarUrl: 'assets/images/speakers/Li-Chen.jpg',
                name: 'Chen Li',
                description: 'Liaoyuan, Co-Founder'
            }, {
                avatarUrl: 'assets/images/speakers/Xiangrui-Kong.png',
                name: 'Henry Kong',
                description: 'Neo-Soul Entertainment, Founder'
            }, {
                avatarUrl: 'assets/images/speakers/Ma-Wener.jpg',
                name: 'Wener Ma',
                description: 'NEBULAR, Co-Founder'
            }, {
                avatarUrl: 'assets/images/speakers/Xichun-Pan.jpeg',
                name: 'Catherine X. Pan – Giordano',
                description: 'Dorsey & Whitney United States and China,President'
            }, {
                avatarUrl: 'assets/images/speakers/Yi-Li-Amazon.jpeg',
                name: 'Yi Li',
                description: 'Amazon Rekognition, Manger'
            }, {
                avatarUrl: 'assets/images/speakers/Yu-Wang.jpg',
                name: 'Georege Wang',
                description: 'IDG Capital, Investment Manger'
            }, {
                avatarUrl: 'assets/images/speakers/Joe-Sun.jpg',
                name: 'Joe Q. SUN',
                description: 'Capital Impetus Group Limited, CEO'
            }, {
                avatarUrl: 'assets/images/speakers/Yifei-Ju.jpg',
                name: 'Yifei Ju',
                description: 'Lit Asset Management, Project Partner'
            }, {
                avatarUrl: 'assets/images/speakers/George-Gong.jpeg',
                name: 'George Gong',
                description: 'Prominent Venture Capital, Partner'
            }, {
                avatarUrl: 'assets/images/speakers/Pei-Qi.jpeg',
                name: 'Pei Qi',
                description: 'Brain Robotics Capital (BRC), Founding and Managing Partner'
            }, {
                avatarUrl: 'assets/images/speakers/Yi-Li.jpg',
                name: 'Elaine Li',
                description: 'Zonvon Capital, Founder Partner'
            }, {
                avatarUrl: 'assets/images/speakers/Beibei-Li.jpg',
                name: 'Beibei Li',
                description: 'ZhenFund North America, Reginal Director'
            }, {
                avatarUrl: 'assets/images/speakers/Richard-Di-Tieri.jpg',
                name: 'Richard Di Tieri ',
                description: 'Startup Institute, CEO'
            }, {
                avatarUrl: 'assets/images/speakers/Da-Gui.jpeg',
                name: 'Da Gui',
                description: 'EHOMIE, Founder'
            }, {
                avatarUrl: 'assets/images/speakers/You-Zhou.jpeg',
                name: 'Alex Zhou',
                description: 'Yamibuy.com, Founder & CEO'
            }, {
                avatarUrl: 'assets/images/speakers/Bowen-Zhao.jpg',
                name: 'Bowen Zhao',
                description: 'QuantiHealth, Founder & CEO'
            }, {
                avatarUrl: 'assets/images/speakers/Qi-Su.jpg',
                name: 'Qi Su',
                description: 'MODELO, Founder & CEO'
            }, {
                avatarUrl: 'assets/images/speakers/Guoyu-Lin.jpg',
                name: 'Guoyu Lin',
                description: 'College Daily, Founder & CEO'
            }, {
                avatarUrl: 'assets/images/speakers/Yutong-Gao.jpg',
                name: 'Tony Gao',
                description: 'Easytransfer, Co-Founder'
            }, {
                avatarUrl: 'assets/images/speakers/Jingyuan-Wang.jpg',
                name: 'Jingyuan Wang',
                description: 'NewDo Venture, Managing Partner '
            }, {
                avatarUrl: 'assets/images/speakers/Amy-Duan.png',
                name: 'Amy Duan',
                description: 'CHIHUO Inc，Founder'
            }, {
                avatarUrl: 'assets/images/speakers/Hang-Tian.jpeg',
                name: 'Hang Tian',
                description: 'NYIS, Co-Founder'
            }, {
                avatarUrl: 'assets/images/speakers/ Donghui-Zhang.jpg',
                name: 'Donghui Zhang',
                description: 'Facebook, Big-data Hands-on Architect'
            }, {
                avatarUrl: 'assets/images/speakers/Changfeng-Wang.jpeg',
                name: 'Changfeng Wang',
                description: 'Adelphic, Co-Founder'
            }, {
                avatarUrl: 'assets/images/speakers/Jiangshan-Cao.jpg',
                name: 'Victor Cao',
                description: 'Even-Better, Product and Operations Director'
            }, {
                avatarUrl: 'assets/images/speakers/Chen-Zhao.jpg',
                name: 'Chen Zhao',
                description: 'E3 Capital, General Partner'
            }, {
                avatarUrl: 'assets/images/speakers/Ben-Tao.jpg',
                name: 'Ben Tao',
                description: 'PTC Corporate Development VP'
            }, {
                avatarUrl: 'assets/images/speakers/Zinan-Chen.jpg',
                name: 'Zinan Chen',
                description: 'UniWill Ventures, Co-Founder'
            },{
                avatarUrl: 'assets/images/speakers/Ziming-Zheng.jpeg',
                name: 'Ziming Zheng',
                description: 'TripAdvisor Inc., Software Engineer'
            },{
                avatarUrl: 'assets/images/speakers/Miao-Cao.jpeg',
                name: 'Miao Cao',
                description: 'The Talent Network North America Lead for Financial Services Practice at McKinsey'
            },{
                avatarUrl: 'assets/images/speakers/Ren-Du.jpg',
                name: 'Ren Du',
                description: 'Heuristic Capital, Managing Director'
            }]
    };
    return {
        'zh': zh,
        'en': en
    };
});
/**
 * Created by j3_z2Z on 6/30/2016.
 */

angular.module('summit.model').factory('Team', function () {

    var zh = [
        {
            name: "萝卜太辣",
            description: "萝卜太辣教育科技是一家位于美国硅谷，由哥伦比亚大学和斯坦福大学校友创立的机器人教育公司，目前拥有两项美国专利及多项知识产权。",
            bigImg: "upload/moment/image/155c18b4fce-5776c66251844ffc3270d38c-f38c79b2797bad9a"
        },
        {
            name: "易思汇",
            description: "易思汇，首创国内在线美国留学学费缴纳平台，支持全美所有高中、大学和研究生院学费缴纳。在线缴纳学费方面快捷，安全可靠。",
            bigImg: "upload/moment/image/155c18b4f95-5776c66251844ffc3270d38c-9a7a37c50851b944"
        },
        {
            name: "Modelo",
            description: "MODELO 是一个针对多维设计和产品的分享互动平台。我们提供崭新的展示媒介，传播平台和交互系统。",
            bigImg: "upload/moment/image/155c18ab6e6-5776c66251844ffc3270d38c-e77425503b8d3f46"
        },
        {
            name: "晶泰科技",
            description: "XtalPi (Xtal-π) 晶泰科技是一家世界领先的以计算驱动创新的药物晶型研发公司，通过计算物理、量子化学与云端强大的智能算法，实现高度精确的药物晶型预测，并极大缩短药物设计、多晶型筛选与药物制剂开发的时间，对药企的药物晶型专利申报与保护起到关键作用。",
            bigImg: "upload/moment/image/155c18b4f73-5776c66251844ffc3270d38c-f2b3ecda690f3c1c"
        },
        {
            name: "亚米网",
            description: "美国亚米网是北美最大亚洲商品购物平台，网站于2013年3月上线，主营最具特色的亚洲商品，涵盖食品，美妆，养生，电器等生活类别。经过近几年的飞速发展，亚米网已经成为北美华人线上购物的首选。",
            bigImg: "upload/moment/image/155c18b4f7c-5776c66251844ffc3270d38c-7a7c34670bffa349"
        },
        {
            name: "简信",
            description: "深圳市电邮时代科技有限公司研发的简信 MailTime 是一款利用电子邮件技术开发的即时通讯应用程序。",
            bigImg: "upload/moment/image/155c18ab6d4-5776c66251844ffc3270d38c-df5015b59b8671ff"
        },
        {
            name: "亿航",
            description: "亿航是一家全球领先的智能飞行器科技公司，总部位于广州，在美国加州、德国杜塞尔多夫、中国北京、上海设有分公司。",
            bigImg: "upload/moment/image/155c18b4f7e-5776c66251844ffc3270d38c-73e7e30be7b53b78"
        },
        {
            name: "Wayfair",
            description: "Wayfair在线家庭用品购物平台是美国一家致力于家庭用户和家庭电器的中档家具为主的在线购物电商。",
            bigImg: "upload/moment/image/155c18ab72a-5776c66251844ffc3270d38c-67701e4fd21610a8"
        },
        {
            name: "Fitbit",
            description: "我们是一支充满热情，致力于健康和健身的团队，研制可帮助改变人们生活的产品。",
            bigImg: "upload/moment/image/155c18ab6cf-5776c66251844ffc3270d38c-89f5ab6d1c265556"
        },
        {
            name: "Monster",
            description: "全球最大的专业招聘网站，也是全球最大的招聘服务供应商，其访问量长期位居 30 位内，提供由招聘代理、线上招聘、猎头服务和招聘黄页广告四项构成的全方位的全球招聘解决方案。",
            bigImg: "upload/moment/image/155c18ab713-5776c66251844ffc3270d38c-dda88f51e36747f1"
        },
        {
            name: "StudentUniverse",
            description: "StudentUniverse是世界顶级的、为学生和青年提供特惠旅行产品的网站。",
            bigImg: "upload/moment/image/155c18ab720-5776c66251844ffc3270d38c-06a2d607b2caf927"
        },
        {
            name: "MathWorks",
            description: "MathWorks是领先的为工程师和科学家提供数学计算软件的供应商。公司于 1984 年创立，总部位于美国马萨诸塞州内迪克，在全球 15 个国家有 3000 名员工。",
            bigImg: "upload/moment/image/155c18ab6e2-5776c66251844ffc3270d38c-bd30098d9d882b93"
        }
    ];
    var en = [
        {
            name: "RoboTerra",
            description: "RoboTerra, Inc. is an educational robotics company with leading R&D capacity in smart device design, cognitive sciences, user experience design and gamification in education.",
            bigImg: "upload/moment/image/155c18b4fce-5776c66251844ffc3270d38c-f38c79b2797bad9a"
        },
        {
            name: "Easy Transfer",
            description: "Easy Transfer operates an online service and maintains banking relationships between payment processors and banks in China to give Chinese students and their families a single channel for tuition payment processing. ",
            bigImg: "upload/moment/image/155c18b4f95-5776c66251844ffc3270d38c-9a7a37c50851b944"
        },
        {
            name: "Modelo",
            description: "At Modelo we are building a 3D collaboration platform to revolutionize the CAD industry.",
            bigImg: "upload/moment/image/155c18ab6e6-5776c66251844ffc3270d38c-e77425503b8d3f46"
        },
        {
            name: "XtalPi",
            description: "XtalPi (Xtal-π) is a cloud-based technology company that solves drug polymorphism by providing accurate computational crystal structure prediction for small-molecule drugs.",
            bigImg: "upload/moment/image/155c18b4f73-5776c66251844ffc3270d38c-f2b3ecda690f3c1c"
        },
        {
            name: "Yamibuy",
            description: "Established in March 2013, Yamibuy is a leading e-commerce site in North America for Asian Americans. We carry a wide variety of Asian snacks, beauty products, health supplements and home appliances.",
            bigImg: "upload/moment/image/155c18b4f7c-5776c66251844ffc3270d38c-7a7c34670bffa349"
        },
        {
            name: "Mailtime",
            description: "MailTime is an open and mobile messenger built with email technology.",
            bigImg: "upload/moment/image/155c18ab6d4-5776c66251844ffc3270d38c-df5015b59b8671ff"
        },
        {
            name: "Ehang",
            description: "EHANG, Inc. is a technological innovation company specializing in R&D, manufacturing and sales of intelligent aerial vehicles.",
            bigImg: "upload/moment/image/155c18b4f7e-5776c66251844ffc3270d38c-73e7e30be7b53b78"
        },
        {
            name: "Wayfair",
            description: "Wayfair is one of the world's largest online destinations for the home. It offers an extensive selection of home furnishings and décor across all styles and price points.",
            bigImg: "upload/moment/image/155c18ab72a-5776c66251844ffc3270d38c-67701e4fd21610a8"
        },
        {
            name: "Fitbit",
            description: "We're a passionate team dedicated to health and fitness who are building products that help transform people's lives.",
            bigImg: "upload/moment/image/155c18ab6cf-5776c66251844ffc3270d38c-89f5ab6d1c265556"
        },
        {
            name: "Monster",
            description: "Monster is a global online employment solution for people seeking jobs and the employers who need great people.",
            bigImg: "upload/moment/image/155c18ab713-5776c66251844ffc3270d38c-dda88f51e36747f1"
        },
        {
            name: "StudentUniverse",
            description: "StudentUniverse is the world's leading travel booking service for students and youth.",
            bigImg: "upload/moment/image/155c18ab720-5776c66251844ffc3270d38c-06a2d607b2caf927"
        },
        {
            name: "MathWorks",
            description: "MathWorks® is the leading developer of mathematical computing software. Engineers and scientists worldwide rely on its products to accelerate the pace of discovery, innovation, and development.",
            bigImg: "upload/moment/image/155c18ab6e2-5776c66251844ffc3270d38c-bd30098d9d882b93"
        }
    ];
    return {
        'zh': zh,
        'en': en
    };
});
angular.module('summit.model').constant('Locale', {
    zh: {
        title: '2017中美投融资峰会暨人才项目对接会',
        header: {
            sloganLiaoyuanUrl: 'assets/images/zh/slogan-liaoyuan.png',
            homepage: '主页'
        },
        first: {
            reviewUrl: 'assets/images/zh/review@2x.png',
            logoUrl: 'assets/images/zh/2017中@2x.png',
            location: '波士顿海因斯国际会展中心',
            ticket: '进入购票',
            download:'下载大会日程'
        },
        second: {
            title: '合作伙伴',
            host: '燎原',
            role: '主办方',
            desc: '「燎原」是立足于北美的新型创投服务平台。「燎原」提供对早期人才、项目的投资和支持和全方位的创投服务内容，' +
            '为中美投资机构及早期项目的对接与交流搭建了一个良好的沟通平台。目前，「燎原」网站和 App 平台已连接全美 100 多所高校的高质量' +
            '人才、项目，同时平台提供深层次的人才招聘、创投活动落地承办、早期项目软件咨询开发以及场地孵化加速等服务。\n\n' +
            '目前实名注册用户及团队已超过 5 万，发布了逾 2200 场北美创投圈活动，共吸引逾 40 万名留学生及当地创业者的关注和参与；' +
            '与中美近百家顶级 VC 机构达成合作意向，输送优质项目及人才资源。总部位于波士顿，同时在上海设有分部。「燎原」已完成两轮融资' +
            '，总融资超过 1200 万人民币，目前正在 A 轮融资中。',
            more: '更多合作伙伴',
            sponsors: '若有赞助或协办活动意向，欢迎联系'
        },
        third: {
            investAndFinanceExpo: {
                title: '投融资峰会',
                content: '开创性地聚焦中美投融资热点话题，为中美投资人创建一个直面、深度交流的平台。'
            },
            startUpEx: {
                title: '初创展览会 / 招聘会',
                content: '汇聚 200+ 优质初创展示最新技术和创新项目，让创业者们分享创业经验，寻求合作机会，也让投资人高效、直接的发现项目。参展团队更将现场招聘优质人才！'
            },
            eliteBanquet: {
                title: '创投精英晚宴',
                content: '年度不可错过的社交机会，提供与 VIP 嘉宾交流的绝佳机会。'
            }
        },
        concertTicket: '需单独购票',
        agenda: [
            {
                date: '9.29',
                weekday: '周五',
                events: [
                    {
                        locationPrimary: '峰会预热活动',
                        locationDetail: 'Royale Boston',
                        activities: [
                            {
                                time: '7:00 PM - 11:00 PM',
                                content: '峰会预热趴暨红花会演唱会'
                            }
                        ]
                    }
                ]
            },
            {
                date: '9.30',
                weekday: '周六',
                morning: {
                    events: [
                        {
                            locationPrimary: '主会场',
                            locationDetail: '302/304/306厅',
                            activities: [
                                {
                                    time: '8:00 AM - 9:00 AM',
                                    content: '来宾签到'
                                },
                                {
                                    time: '9:00 AM - 9:20 AM',
                                    content: '消费升级 - 红杉资本中国合伙人 王岑'
                                },
                                {
                                    time: '9:20 AM - 9:40 AM',
                                    content: '人工智能 - 峰瑞资本创始人 李丰'
                                },
                                {
                                    time: '9:40 AM - 10:10 AM',
                                    content: '科技金融的创新',
                                    subContent: ['斯坦福客座教授 & 福布斯全球 30 under 30 邹昊', '秦岭资本合伙人 刘亦婷', '比特币及区块链行业天使投资⼈ 郭宏才']
                                },

                                {
                                    time: '10:10 AM - 10:40 AM',
                                    content: '炉边对话 - 中美创新投资与趋势',
                                    subContent: ['光速中国助理合伙人 潘翔', 'PersonalVC 联合创始人 & 哈佛商学院的 EIR David Chang']
                                }, {
                                    time: '10:40 AM - 10:55 AM',
                                    content: '在美创业如何获得美国政府和企业资源 - 美中合作发展委员会执行主席 & 上海张江波士顿企业园董事长 李建生'
                                },
                                {
                                    time: '10:55 AM - 11:10 AM',
                                    content: '华尔街科技金融 - Bloomberg & Dow Jones 前CEO、Heckyl. CEO Lex Fenwick'
                                },
                                {
                                    time: '11:10 AM - 11:25 AM',
                                    content: '加速孵化模式 - Masschallenge 全球合作总监 Brittany McDonough'
                                },
                                {
                                    time: '11:25 AM - 12:05 PM',
                                    content: '企业出海及海外资产配置',
                                    subContent: ['万通证券 COO 郭易', '荷马金融 联合创始人 & CIO George Chen', '阳光印网创始人 & CEO 张红梅', 'National Securities 资深注册投资顾问 付少远']
                                },
                                {
                                    time: '12:05 PM - 12:40 PM',
                                    content: '深度聚焦 – 人工智能',
                                    subContent: ['丰元资本管理合伙人 徐霄羽', 'Fusion Fund 创始合伙人 & 福布斯美国30/30 张璐', 'RoboTerra 创始人 & CEO,世界经济论坛人工智能与机器人特别委员 张尧', 'IDG资本 北美项目经理 王昱']
                                },
                                {
                                    time: '12:40 PM - 2:00 PM',
                                    content: '午餐休息时间'
                                }
                            ]
                        }
                    ]
                },
                break: {
                    description: '12：40 PM - 2：00 PM 自由午餐时间'
                },
                afternoon: {
                    events: [
                        {
                            locationPrimary: '分论坛 1',
                            locationDetail: '会场：302厅',
                            activities: [
                                {
                                    time: '2:00 PM - 3:00 PM',
                                    content: '中美人工智能的机遇与挑战',
                                    subContent: ['Amazon AI Services 项目负责人 李轶', 'BRC创新基金 管理合伙人 祁培', 'Deep Cognitive Machines 联合创始人 王长风', '牛犊创投 合伙人 王静远']
                                },
                                {
                                    time: '3:00 PM - 4:00 PM',
                                    content: '教育科技 or 科技教育',
                                    subContent: ['易思汇联合创始人 高宇同', '真格基金美东项目经理 李贝贝', 'IBIS Capital 投资银行合伙人 Vincent Fung', 'Sunny Crown 联合创始⼈ 王冠']
                                },
                                {
                                    time: '4:00 PM - 5:00 PM',
                                    content: '大数据的发展与应用',
                                    subContent: ['屹杉资本管理合伙人 赵晨', '比特币及区块链行业天使投资⼈ 郭宏才', '微软人工智能和研究部门 高级数据分析经理 邬涛', 'TripAdvisor 资深数据工程师 郑自明']
                                },
                                {
                                    time: '5:00 PM - 6:00 PM',
                                    content: '美国教育创业的发展与趋势',
                                    subContent: ['Startup institute CEO Richard Di Tieri', 'UniWill Ventures 联合创始人 陈紫楠', 'Fair Opportunity Project 联合创始人 Cole Scanlon', 'Woboo 联合创始人 & CEO 谭丰']
                                }
                            ]
                        },
                        {
                            locationPrimary: '分论坛 2',
                            locationDetail: '会场：304厅',
                            activities: [
                                {
                                    time: '2:00 PM - 3:00 PM',
                                    content: '如何抓住消费升级的投资机会',
                                    subContent: ['光速中国助理合伙人 潘翔', '亚米网创始人 周游', '点亮资产管理 项目合伙人 居易非', '众方资本 合伙人 李祎']
                                },
                                {
                                    time: '3:00 PM - 4:00 PM',
                                    content: '金融科技新时代：大数据与人工智能',
                                    subContent: ['Beyondsoft 联合创始人 & 天使投资人 George Gong', 'Facebook 大数据架构专家 Donghui Zhang', 'Even-Better 产品与运营总监 Victor Cao', '丰元资本管理合伙人 徐霄羽']
                                },
                                {
                                    time: '4:00 PM - 5:00 PM',
                                    content: '留学生如何创业',
                                    subContent: ['留学生日报创始人 林国宇', 'NYIS 联合创始人 田行', '易思汇 联合创始人 高宇同', '「燎原」联合创始人 李琛', '易美留学 创始人 崔易宁', '北美省钱快报东岸负责人 April Huang']
                                },
                                {
                                    time: '5:00 PM - 6:00 PM',
                                    content: '海外求职及工作职场生存',
                                    subContent: ['麦肯锡公司金融人才管理北美负责人 曹苗', '秦岭资本合伙人 刘亦婷', 'Amazon AI Services 项目负责人 李轶', 'Marcum Bernstein & Pinchuk LLP 董事 梁恩泽']
                                }
                            ]
                        },
                        {
                            locationPrimary: '分论坛 3',
                            locationDetail: '会场：306厅',
                            activities: [
                                {
                                    time: '2:00 PM - 3:00 PM',
                                    content: '聚焦波士顿（中美）生物医疗发展的现状',
                                    subContent: ['启发资本管理合伙人 杜韧', '斯坦福客座教授 & 福布斯全球 30／30 邹昊', '北京量化健康科技有限公司 CEO 赵柏闻', '晶泰科技 首席战略官 蒋一得']
                                },
                                {
                                    time: '3:00 PM - 4:00 PM',
                                    content: '中国企业出海的模式探讨',
                                    subContent: ['资本动力集团有限公司 CEO Joe Q. SUN', 'Dorsey & Whitney 全美及中国联合业务主席 Catherine X. Pan – Giordano', 'Marcum Bernstein & Pinchuk LLP 董事 梁恩泽', '可美亚跨境营销咨询公司 CEO 杨子叶']
                                },
                                {
                                    time: '4:00 PM - 5:00 PM',
                                    content: 'SaaS 企业如何突围',
                                    subContent: ['Modelo 联合创始人&CEO 苏奇', 'Procyon Ventures 联合创始人 Millie Liu', 'Menusifu 联合创始人 & CEO 李宇', 'PTC Corporate Development VP Ben Tao', 'TrueMotion 联合创始人 & CTO Brad Cordova']
                                },
                                {
                                    time: '5:00 PM - 6:00 PM',
                                    content: '北美生活娱乐新机遇',
                                    subContent: ['新魂娱乐创始人 孔祥瑞', 'NEBULAR 星云联合创始人 马汶儿', 'e乡e家创始人 桂达', '吃货小分队 创始人 & CEO Amy Duan']
                                }
                            ]
                        }
                    ]
                },
                evening: {
                    events: [
                        {
                            locationPrimary: 'VIP 晚宴',
                            locationDetail: '312厅',
                            activities: [
                                {
                                    time: '7:30 PM - 9:00 PM',
                                    content: 'VIP 晚宴 （着装：Business Casual）'
                                }
                            ]
                        }
                    ]
                }
            },
            {
                date: '10.1',
                weekday: '周日',
                events: [
                    {
                        locationPrimary: '主会场',
                        locationDetail: '302/304厅',
                        activities: [
                            {
                                time: '8:30 AM - 9:30 AM',
                                content: '来宾签到'
                            },
                            {
                                time: '9:30 AM - 5:00 PM',
                                content: '海外项目暨人才交流会（超过60个企业展位，丰富的招聘信息)'
                            }
                        ]
                    },
                    {
                        locationPrimary: '分会场',
                        locationDetail: '306厅',
                        activities: [
                            {
                                time: '9:30 AM- 11:30 AM',
                                content: '专场项目路演对接会',
                                subContent: ['Airacer 航空航天', '3D Beans 3D打印', '灵慧致远机器人 人工智能', 'Chowbus 餐饮', 'Panacea 生活科技', 'META 生物医疗', 'Unibit 大数据', '新顿材料 高科技 / 数据传输', 'KRL 新能源', '亿昊基因 生物医疗', '指点 新闻资讯', '蹄子科技 科技硬件', 'Visual Pathy VR', 'Bio－X 生物医疗']
                            },
                            {
                                time: '11:30 AM - 12:30 PM',
                                content: '教育科技创业分享',
                                subContent: ['好未来海外业务首席顾问 陈丁鸿', 'IBIS Capital 投资银行合伙人 Vincent Fung', 'RoboTerra 创始人 & CEO、世界经济论坛人工智能与机器人特别委员 张尧', 'Sunny Crown 联合创始⼈ 王冠']
                            },
                            {
                                time: '1:30 PM - 2:30 PM',
                                content: '法律专场 - 留学生创业以及创业身份',
                                subContent: ['NYIS Law Firm managing attorney Vera Su']
                            },
                            {
                                time: '2:30 PM- 3:30 PM',
                                content: '创业早期如何拓展市场',
                                subContent: ['做做联合创始人& 媒体黑客 朱沈昊辰', '没课啦联合创始人 李厚达', 'Edge联合创始人 Austin', 'FoodEasyGo联合创始人 Mandy', 'VisualPathy联合创始人 潘洋']

                            },
                            {
                                time: '3:30 PM- 4:30 PM',
                                content: '海外众筹及品牌出海',
                                subContent: ['Poputar 视感科技联合创始人和 COO 陈荣斌', 'Vbike 美东市场负责人', 'Rstorm联合创始人 姚冬伟', '可美亚跨境营销咨询公司 CEO 杨子叶']
                            },
                            {
                                time: '4:30 PM- 5:30 PM',
                                content: '早期创业团队如何融资'
                            }
                        ]
                    }

                ]
            }
        ],
        agendaMore: '下载大会日程',
        speaker: {
            title: '确认嘉宾',
            titleUrl: 'assets/images/zh/text-speaker.png',
            more: '更多活动嘉宾',
            invited: '主会场',
            preliminary: '分会场'
        },
        startupExpo: {
            titleUrl: 'assets/images/zh/text-startup-expo.png',
            summary: '人才项目对接会预计将有超过 100 家中美企业参展，展位申请已正式开放。',
            title: '参展团队',
            more: '查看更多团队',
            description: '初创展览预计将有超过 200 家中美初创团队参展。展位申请已正式开放。',
            contact: '初创团队若想申请参展，请点此按钮填写申请信息。'
        },
        media: {
            titleUrl: 'assets/images/zh/text-media.png',
            title: '媒体支持',
            more: '查看更多媒体'
        },
        startupMarathon: {
            titleUrl: 'assets/images/zh/text-startup-marathon.png',
            description: '「燎原」创业马拉松活动旨在聚集多种创新创业资源和人才，在中美的创业者和投资人间搭建一个高质量、高效率的平台。',
            generalContact:
                "若想提前联系报名事宜或有任何疑问，请发送电子邮件至 <a class='email' href='mailto:general@us.liaoyuan.io'>general@us.liaoyuan.io</a>",
            partnerContact:
                "若想合作协办所在城市创业马拉松，请发送电子邮件至 <a class='email' href='mailto:partnership@us.liaoyuan.io'>partnership@us.liaoyuan.io</a>"
        },
        partner: {
            titleUrl: 'assets/images/zh/text-partner.png',
            host: {
                titleUrl: 'assets/images/zh/text-host.png',
                description:
                    '「燎原」是以创业者为核心的中文创业社交平台，通过专精领域的细分，能够在第一时间为优秀创业者匹配志同道合的创业伙伴，提升互动交流；并且分享初创活动，创业热点和初创招聘等各类资源。在「燎原」，创业不再是孤独的星火。「燎原」创业社交平台已于 2016 年 4 月 6 日，推出网页版及 App iOS 正式版。'
            },
            content:
                "若有赞助或协办活动意向，欢迎联系&nbsp;<a class='email' href='mailto:sponsors@us.liaoyuan.io'>sponsors@us.liaoyuan.io</a>",
            more: '查看更多合作伙伴'
        },
        contact: {
            title: '联系我们',
            general: {
                text: '常见问题：',
                email: 'general@us.liaoyuan.io'
            },
            sponsorship: {
                text: '赞助：',
                email: 'sponsors@us.liaoyuan.io'
            },
            partner: {
                text: '合作：',
                email: 'partnership@us.liaoyuan.io'
            },
            press: {
                text: '媒体：',
                email: 'press@us.liaoyuan.io'
            },
            opening: {
                text: '峰会志愿者招聘：',
                email: 'hr@us.liaoyuan.io'
            }
        },
        footer: {
            ticket: '报名购票',
            agenda: '大会日程',
            review: '精彩回顾',
            speakers: '活动嘉宾',
            teams: '初创展览团队',
            media: '合作媒体',
            partner: '合作伙伴',
            liaoyuan: '关于燎原',
            socialMedia: '关注社交媒体：'
        },
        applyPage: {
            titleUrl: 'assets/images/zh/text-startup-expo-booth-application.png',
            description: '初创展览会将汇聚200+优质初创团队展示最新技术和创新项目，让初创者们分享创业经验，寻求合作机会，更让投资人能够高效、直接地发现项目。',
            formDescription: '请先填写并提交以下信息，确认您的展位申请。展会工作人员将尽快与您和您的团队联络，为您提供展会详细信息及参展申请材料，并解答您的任何疑问。',
            companyNameUrl: 'assets/images/zh/text-company-name.png',
            companySiteUrl: 'assets/images/zh/text-company-site.png',
            contactNameUrl: 'assets/images/zh/text-contact-name.png',
            contactPositionUrl: 'assets/images/zh/text-contact-position.png',
            contactPhoneUrl: 'assets/images/zh/text-contact-phone.png',
            contactEmailUrl: 'assets/images/zh/text-contact-email.png',
            submit: '提交申请',
            info: "如有问题，请联系 <a href='mailto:startupexpo@us.liaoyuan.io'> startupexpo@us.liaoyuan.io </a>",
            successContent: '您的展位申请已成功提交，工作人员会尽快与您取得联系。',
            errorContent: '公司名称和邮箱必填',
            okButton: '确定'
        },
        applyConfirmation: {
            titleUrl: 'assets/images/zh/text-apply-confirmation.png',
            description: '您的展位申请信息已经提交，完成全部申请流程，还请进行以下步骤：'
        },

        zhRandomTeams: [
            {
                teamCategory: 200,
                data: {
                    teamId: '57a2068bd7eae8ad054d686e',
                    logoUrl: 'upload/team/image/15650df3f30-55a2843b2072b5510450aa80-88f4c8ce496f053e',
                    name: 'PENCIL ONE Product Photography',
                    slogan: '让产品摄影品质更优秀，沟通更简单，价格更低廉。'
                }
            },
            {
                teamCategory: 200,
                data: {
                    teamId: '57a2b9b3d7eae8ad054d7517',
                    logoUrl: 'upload/team/image/156539dd9a3-5722a8e7f2b9f523328e1929-9bf1e8b3015ddba0',
                    name: 'EsianMall（宜尚北美网）',
                    slogan: '按月订购-让购物变成一种乐趣， 一种惊喜， 一种生活方式！'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '开始寻找你的下一顿食物。',
                    externalLink: 'https://www.foodtruckstars.com/index.html',
                    logoUrl: 'upload/feed/image/1569a176d7f-57966aab0075ef4a7b015f0d-ccaaa3097643de9e',
                    name: 'Food Truck Stars'
                }
            },
            {
                teamCategory: 200,
                data: {
                    teamId: '57b6411e0c4c5d1413e290af',
                    logoUrl: 'upload/team/image/1569fee5400-576d84c1c613b7e87418c0b9-e9e1e6265a775c8c',
                    name: '易思汇',
                    slogan: '最受留学家庭欢迎的缴费方式'
                }
            },
            {
                teamCategory: 200,
                data: {
                    teamId: '57a3a70bd7eae8ad054d7ff1',
                    logoUrl: 'upload/team/image/156572b5a8b-55e61a7e05928a4f04870ba1-d4a462aec910fd11',
                    name: 'VISUALPATHY',
                    slogan: '摄影师360/VR作品的分享平台'
                }
            },
            {
                teamCategory: 200,
                data: {
                    teamId: '57a0e4fbcea54c0f1d0f0a2f',
                    logoUrl: 'upload/team/image/157771f38a4-567082933e67695004d969a7-14caa2cbaf5756ee',
                    name: 'R-storm Technology LLC',
                    slogan: '科技推动进步!!技术优化生活!!'
                }
            },
            {
                teamCategory: 200,
                data: {
                    teamId: '57a3b6e2cea54c0f1d0f500c',
                    logoUrl: 'upload/team/image/1565771e310-56c4e77f16030e50049efabe-162e6ea831be3ed6',
                    name: 'Gridology美国格道教育科技',
                    slogan: '我们让复杂信息的获取更高效、更直观、更可靠'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '在低能见度条件下增加司机安全(雨、雾、大雪或黑暗)。',
                    externalLink: 'http://hudwayapp.com/',
                    logoUrl: 'upload/feed/image/1569a2b7e7e-57966aab0075ef4a7b015f0d-131220b63615dbb6',
                    name: 'HUDWAY'
                }
            },
            {
                teamCategory: 200,
                data: {
                    teamId: '57a88975cea54c0f1d0f81d5',
                    logoUrl: 'upload/team/image/1566a5854a9-57753d699baa35841eb784e3-2f527bc1e17e48af',
                    name: '洋草全球购',
                    slogan: '足不出户接触全球最时尚最划算的商品。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '与雷贝同行，掌全球机遇！',
                    externalLink: 'http://baike.baidu.com/view/9234983.htm',
                    logoUrl: 'upload/feed/image/1569a316417-57966aab0075ef4a7b015f0d-6c990e9093fdd7e9',
                    name: '南京雷贝教育信息咨询有限公司'
                }
            },
            {
                teamCategory: 200,
                data: {
                    teamId: '57aa26bfd7eae8ad054dc27f',
                    logoUrl: 'upload/team/image/1569471ad42-560c55d7a37e8f6704c6836b-6e26d3c1ad3aa6ad',
                    name: 'CheersYou 清柚教育',
                    slogan: '立足纽约，专注名校'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '解决卫生间漏水问题。',
                    logoUrl: 'upload/feed/image/1569a3523f3-57966aab0075ef4a7b015f0d-f3d2fd1671165f4f',
                    name: 'LeakSpotter'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '国内云端开发平台',
                    externalLink: 'https://mart.coding.net/',
                    logoUrl: 'upload/feed/image/156e57c5e61-57966aab0075ef4a7b015f0d-3cc7d8eed4dad9fc',
                    name: 'Coding 码市'
                }
            },
            {
                teamCategory: 200,
                data: {
                    teamId: '57b1e1240de1c2c22e5ffd51',
                    logoUrl: 'upload/team/image/156b80cf3b0-571688226ac9e450042d150a-353edfe0c58ed158',
                    name: '荷马金融',
                    slogan: '提供全球资产配置和海外理财的一站式服务，共享全球优质财富'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '瑞典/美国工程和工业设计公司，开发模块化设计可再生实木产品材料简化和改进建设物流。',
                    externalLink: 'http://www.modvion.com/',
                    logoUrl: 'upload/feed/image/1569a3d6b60-57966aab0075ef4a7b015f0d-a9f9f50ab57b9318',
                    name: 'Modvion'
                }
            },
            {
                teamCategory: 200,
                data: {
                    description: '做波士顿最好的婚纱店！',
                    teamId: '57a0c0bccea54c0f1d0f07da',
                    logoUrl: 'upload/team/image/1564bf04aca-579a7780e1557d9e1e5158a2-9b9ca5e82253d36f',
                    name: 'I Do Wedding Boston',
                    slogan: '做波士顿最好的婚纱店！'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '美味新鲜的姜汁啤酒！',
                    externalLink: 'http://www.thefarmerwillies.com/',
                    logoUrl: 'upload/feed/image/1569a3f2bef-57966aab0075ef4a7b015f0d-0c678380724512b7',
                    name: "FARMER WILLIE'S"
                }
            },
            {
                teamCategory: 200,
                data: {
                    description: '基于地图的社交互动app。',
                    teamId: '57ad3439970e176573d93317',
                    logoUrl: 'upload/team/image/1572c1ca032-55cf962edd77a69704a00b05-6aff71cfc9ecaffb',
                    name: 'PinOn',
                    slogan: '基于地图的社交互动app。'
                }
            },
            {
                teamCategory: 200,
                data: {
                    teamId: '57aced28d3d69dbe3f67c2f7',
                    logoUrl: 'upload/avatar/156faf83186-57acd1e7010036c90d3f02cb-672866872a02f4e1',
                    name: 'IGET',
                    slogan: '为所有中小型商家打造一个公平，便宜，有效的广告平'
                }
            },
            {
                teamCategory: 200,
                data: {
                    description: '你的大脑控制一切。',
                    teamId: '57ae326e970e176573d94023',
                    logoUrl: 'upload/team/image/15680736376-573146b31fc7625004bb1ce1-548d1f4654a8cac2',
                    name: 'BrainCo',
                    slogan: '你的大脑控制一切。'
                }
            },
            {
                teamCategory: 200,
                data: {
                    teamId: '57ae39054200faf743a08dab',
                    logoUrl: 'upload/team/image/156808e6fca-57460f733362c6886920da79-d290c861fa31952c',
                    name: '美信金融 ',
                    slogan: '海外理财，上美信'
                }
            },
            {
                teamCategory: 200,
                data: {
                    teamId: '5790ec8ff28822a5390eaa7e',
                    logoUrl: 'upload/team/image/1560e1330dc-559edb1600249b5004e543cd-583cae71bd1ddade',
                    name: '众方资本',
                    slogan: '新生代大摩 —— 研究为本 科技驱动 资本运作 艺术管理'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '吃掉你的咖啡！',
                    externalLink: 'http://newgroundsfood.com/',
                    logoUrl: 'upload/feed/image/1569a43ca82-57966aab0075ef4a7b015f0d-81069a721eaa5f1d',
                    name: 'NEW GROUNDS FOOD'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Modelo是在线设计展示与协作平台',
                    externalLink: 'http://www.modelo.io/',
                    logoUrl: 'upload/feed/image/1569a456988-57966aab0075ef4a7b015f0d-c12c3ee8692e429c',
                    name: 'modelo'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '数据、洞察力、行动。',
                    externalLink: 'https://www.dataxu.com/',
                    logoUrl: 'upload/feed/image/1569a48b2df-57966aab0075ef4a7b015f0d-e09f0348c1d27c93',
                    name: 'DataXu'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'GoGoVan（高高客貨車）為亞洲首個以手機應用程式為載體的貨運物流平台。',
                    externalLink: 'https://www.gogovan.com.hk/en/',
                    logoUrl: 'upload/feed/image/1569a5601ca-57966aab0075ef4a7b015f0d-e61d1ad313415eca',
                    name: 'GogoVan'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'TripAdvisor(猫途鹰)是全球知名的旅行社区。',
                    externalLink: 'https://www.tripadvisor.com/',
                    logoUrl: 'upload/feed/image/1569a59306c-57966aab0075ef4a7b015f0d-30a11e8dfcf335db',
                    name: 'TripAdvisor'
                }
            },
            {
                teamCategory: 200,
                data: {
                    description: '创业不再是孤独的星火。',
                    teamId: '578cab4e59b299ff6be01643',
                    logoUrl: 'upload/team/image/155ff315808-5599de02e149c24e045442c3-363ec1dff7bacc3b',
                    name: '「燎原」',
                    slogan: '创业不再是孤独的星火。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '提供分析引擎，优化信息，报价和渠道组合等服务，以帮助公司实现更高的销售额',
                    externalLink: 'www.enginologi.com',
                    logoUrl: 'upload/feed/image/156c230ce0c-57966aab0075ef4a7b015f0d-7a4ea0f1f3d332ab',
                    name: 'ENGINOLOGI INC.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '基于尖端的microRNA传感器技术，通过开发一种微创侵入性血液测试，可检测多达18种的癌症。',
                    externalLink: 'www.conquerxlab.com',
                    logoUrl: 'upload/feed/image/156c2445f15-57966aab0075ef4a7b015f0d-3fbcfdc91a35e47e',
                    name: 'ConquerX'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '致力于修复受损器官。我们正在开发可植入式的医疗装置以帮助受损食道再生。',
                    externalLink: 'www.dpbioinnovations.com',
                    logoUrl: 'upload/feed/image/156c247a3d3-57966aab0075ef4a7b015f0d-bcd8a8c01fd1f502',
                    name: 'D&P BIOINNOVATIONS, LLC'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'iBeehub是由一群热心的企业家创建，致力于帮助人们实现创业目标。',
                    externalLink: 'http://ibeehub.org/',
                    logoUrl: 'upload/feed/image/156c248b21f-57966aab0075ef4a7b015f0d-a15aab7af946fbe6',
                    name: 'Ibeehub'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '我们开发了一种能够产生新的成熟血管的药物，用来恢复死去肌肉组织的血液流动。',
                    externalLink: 'nangiotx.com',
                    logoUrl: 'upload/feed/image/156c2499e17-57966aab0075ef4a7b015f0d-7f21b65218f8d578',
                    name: 'NangioTx'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '一款为现代移动音乐设计的专业，价格合适且易于学习的音乐录制应用程序。',
                    externalLink: 'lumit-audio.com',
                    logoUrl: 'upload/feed/image/156c2509947-57966aab0075ef4a7b015f0d-62d22c58864b45d7',
                    name: 'Lumit Audio, LLC'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'RoyalRoadL是一个帮助作者出版小说的电子平台。已拥有6000本电子小说的免费图书馆。',
                    externalLink: 'royalroadl.com',
                    logoUrl: 'upload/feed/image/156c251f077-57966aab0075ef4a7b015f0d-66e2b9751626004d',
                    name: 'RoyalRoadL'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '通过将大麻素的提取从天然植物提取改为工业酵母提取，使得基于大麻素的治疗剂成为可能。',
                    externalLink: 'hyasynthbio.com',
                    logoUrl: 'upload/feed/image/156c25968fc-57966aab0075ef4a7b015f0d-dfac78354aace2e7',
                    name: 'Hyasynth Bio'
                }
            },
            {
                teamCategory: 200,
                data: {
                    description: '飛翼的第一个核心应用飛翼地图提供了世界上最先进的实时社交体验。',
                    teamId: '57a2d4bdcea54c0f1d0f44c3',
                    logoUrl: 'upload/team/image/156f77a2d4d-56de1df668ed6b50041273ab-b255cf2308efebda',
                    name: '飛翼地图－Fae Map',
                    slogan: '飛翼的第一个核心应用飛翼地图提供了世界上最先进的实时社交体验。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '通过经济实惠的微型光量子随机数发生器，来取代以往的安全软件。',
                    externalLink: 'www.eylpartners.com',
                    logoUrl: 'upload/feed/image/156c2631290-57966aab0075ef4a7b015f0d-6437374356768f99',
                    name: 'EYL'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'JEFIT是一款健身跟踪平台。帮助健身者跟踪和分析他们的健身目标。',
                    externalLink: 'https://www.jefit.com/',
                    logoUrl: 'upload/feed/image/156c278829b-57966aab0075ef4a7b015f0d-1b2036a727f99aa2',
                    name: 'Jefit, Inc.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '我们发明和烟有关的游戏产品来连接吸烟者的社交网络。',
                    externalLink: 'https://cloudculturestore.com/',
                    logoUrl: 'upload/feed/image/156c27acc8a-57966aab0075ef4a7b015f0d-53236e32c6316898',
                    name: 'Cloud Culture'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '是⼀个专注于建立共享经济模式下的知识分享平台。',
                    externalLink: 'http://www.aladdincloud.com/',
                    logoUrl: 'upload/feed/image/156c2811978-57966aab0075ef4a7b015f0d-ebf378be19d7fff7',
                    name: '微帮'
                }
            },
            {
                teamCategory: 200,
                data: {
                    description: '以增强现实（AR）技术为主，采用微软下一代计算平台全息投影（Holographic），虚拟人工智能交互。',
                    teamId: '57bdc8f7168044005ba687e6',
                    logoUrl: 'upload-cn/avatar/156bd5a2756-56f550762aa6935004260393-0134dc2332e40c3b',
                    name: 'PlusOne',
                    slogan: '以增强现实（AR）技术为主，采用微软下一代计算平台全息投影（Holographic），虚拟人工智能交互。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '搭配 NVCloud 的软件，可以让用户随时随地从任何云端连接的设备进行 3D 打印，',
                    externalLink: 'nvbots.com',
                    logoUrl: 'upload/feed/image/156e1f33702-57966aab0075ef4a7b015f0d-43a35b51bc82a33a',
                    name: 'New Valence Robotics'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '我们通过完善的职业指导，帮助求职者得到他们的梦想职业.',
                    externalLink: 'http://www.shoo-in.com/home',
                    logoUrl: 'upload/feed/image/156e1f4e4da-57966aab0075ef4a7b015f0d-0cc8d521c1e67bec',
                    name: 'SHOO-IN'
                }
            },
            {
                teamCategory: 200,
                data: {
                    description: 'XtalPi创新性地以分子物理、量子化学结合高性能云计算，取代实验室里的摸索，实现准确高效的药物晶型预测这一“黑魔法”',
                    teamId: '57b481ac3cd7fe7f4a5abb32',
                    logoUrl: 'upload/team/image/1569916648d-57583cbe5d0a7bff3ca483c8-808754ea9b6d4dc9',
                    name: 'XtalPi',
                    slogan: 'XtalPi创新性地以分子物理、量子化学结合高性能云计算，取代实验室里的摸索，实现准确高效的药物晶型预测这一“黑魔法”'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '我们提供世界上最好的军事虚拟现实训练产品。',
                    externalLink: 'www.serioussimulations.com',
                    logoUrl: 'upload/feed/image/156e1fb2e6a-57966aab0075ef4a7b015f0d-b94036e6dc57d21b',
                    name: 'Serious Simulations'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Xeno Therapeutics 是一个非营利性组织。寻求将 xenoskin 移植技术可实用化。',
                    externalLink: 'http://xenotherapeutics.org',
                    logoUrl: 'upload/feed/image/156e1fc3748-57966aab0075ef4a7b015f0d-1205e3f51145737c',
                    name: 'Xeno Therapeutics'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Vitamio 是一款 Android 与 iOS 平台上的全能多媒体开发框架。',
                    externalLink: 'http://www.yixia.com/',
                    logoUrl: 'upload/feed/image/156e1ff6c29-57966aab0075ef4a7b015f0d-6d20b9c16ed411ce',
                    name: '一下科技'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '北美最专业的一站式求职咨询和培训平台。',
                    externalLink: 'http://www.uscareerpass.com/',
                    logoUrl: 'upload/feed/image/157430cd763-57966aab0075ef4a7b015f0d-32e6f19f22ca8951',
                    name: '北美职通卡'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '虚拟现实眼科治疗技术',
                    externalLink: 'https://luminopia.com/',
                    logoUrl: 'upload/feed/image/156e641a5de-57966aab0075ef4a7b015f0d-5ce7cacc37e688e2',
                    name: 'Luminopia'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '旅行规划师平台--提供一站式美国自由行解决方案',
                    externalLink: 'https://itunes.apple.com/us/app/lin-ke-mei-guo-sui-shi-sui/id1039598771?mt=8',
                    logoUrl: 'upload/feed/image/156e7a50c88-57966aab0075ef4a7b015f0d-78dae0e122b4ee61',
                    name: '邻客美国'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'LabMinds 是一个智能的，多用途的，全自动液体溶液的混合系统。',
                    externalLink: 'http://www.labminds.co/',
                    logoUrl: 'upload/feed/image/156e7aa1106-57966aab0075ef4a7b015f0d-6dff952edef163d1',
                    name: 'LabMinds'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Fetchpark 是自动化停车服务 App',
                    externalLink: 'http://fetchpark.com/',
                    logoUrl: 'upload/feed/image/156e7aac6a7-57966aab0075ef4a7b015f0d-941efbe75fd51a8f',
                    name: 'Fetchpark'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Create将城市数据汇集在一起创造城市的全貌。',
                    externalLink: 'create.io',
                    logoUrl: 'upload/feed/image/156e7ae7383-57966aab0075ef4a7b015f0d-db287067fc3c94ef',
                    name: 'Create'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'PermitZone可以提供您在任何设备上所需要的信息，消除您与办公室的距离',
                    externalLink: 'https://permitzone.com',
                    logoUrl: 'upload/feed/image/1570b8b7741-57966aab0075ef4a7b015f0d-634a50eac0dffd9c',
                    name: 'PermitZone'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '通过LISNR技术，运用超音波传送数据',
                    externalLink: 'www.lisnr.com',
                    logoUrl: 'upload/feed/image/1570b8cd53a-57966aab0075ef4a7b015f0d-d9b9a75c4e6a3825',
                    name: 'LISNR'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Red Cube为早期创业公司提供一站式视频服务。',
                    externalLink: 'redcubestudio.com',
                    logoUrl: 'upload/feed/image/1570b8e368d-57966aab0075ef4a7b015f0d-0f7f6a1d640898f9',
                    name: 'Red Cube'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '简信将复杂的电子邮件格式重组，变为聊天气泡，并且把聊天和其他非通讯类的邮件智能地分类。',
                    externalLink: 'http://jianxinapp.com',
                    logoUrl: 'upload/feed/image/1570b8f43dd-57966aab0075ef4a7b015f0d-dac52ccf64fa89fa',
                    name: '简信 MailTime'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '设计自己的钻石订婚戒指',
                    externalLink: 'https://www.rarepink.com',
                    logoUrl: 'upload/feed/image/1570b963fc6-57966aab0075ef4a7b015f0d-c8201ec1faf2e022',
                    name: 'Rare Pink'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '我们的团队致力于将凯威国际教育与市场上其他中介区分开来，真正为准留学生服务，帮助他们铺好出国之路.',
                    externalLink: 'http://www.gatewayscholars.com/about-us',
                    logoUrl: 'upload/feed/image/1570b9710b2-57966aab0075ef4a7b015f0d-6b3c8a69bf5aaafe',
                    name: '凯威国际教育'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Soft Robotics设计和制造钳形控制系统，让机器人可以操作不同大小，形状和重量的物品。',
                    externalLink: 'http://www.softroboticsinc.com/',
                    logoUrl: 'upload/feed/image/1570fa7cbff-57966aab0075ef4a7b015f0d-a3cfccb3424ef50a',
                    name: 'Soft Robotics Inc.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '我们致力于将VR摄影变为主流。',
                    externalLink: 'http://lumierevr.com/',
                    logoUrl: 'upload/feed/image/1570fa8fd84-57966aab0075ef4a7b015f0d-e8ffa335e7d28d07',
                    name: 'LumiereVR'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'OBEN使得IoT或虚拟人物能够在短短几分钟内学会任何声音和语言。',
                    externalLink: 'oben.com',
                    logoUrl: 'upload/feed/image/1570fafa30d-57966aab0075ef4a7b015f0d-d6bb45de5ecc3622',
                    name: 'ObEN'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '跨平台虚拟现实网络游戏',
                    externalLink: 'http://www.surrealvr.com/',
                    logoUrl: 'upload/feed/image/1570fb20dc0-57966aab0075ef4a7b015f0d-628f8ddf079597aa',
                    name: 'SurrealVR'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Ominicharge是第一款有插排接口的充电宝，用户可以在设备充电的同时依旧给充电宝充电。',
                    externalLink: 'http://www.omnicharge.co/',
                    logoUrl: 'upload/feed/image/1570fb8c850-57966aab0075ef4a7b015f0d-c128e6e97f820433',
                    name: 'ominicharge'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Catalant是一家专注为公司寻找具有某项专长的自由职业者平台。该平台可帮助公司快速找到他们需要的高精端人才。',
                    externalLink: 'https://gocatalant.com/',
                    logoUrl: 'upload/feed/image/1570fbb76ee-57966aab0075ef4a7b015f0d-b0ed46cadc5854e8',
                    name: 'Catalant'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Flx.bike是一款电通自行车，该公司提供的自行车不需要传统自行车的脚踏板，同时时速可以达到每小时20英里。',
                    externalLink: 'https://flx.bike/',
                    logoUrl: 'upload/feed/image/1570fbdf43c-57966aab0075ef4a7b015f0d-76f90d42d5c9d495',
                    name: 'flx.bike'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'VirZoom是一款和VR主流设备进行连接的动感单车，用户可以通过单车健身，同时VR设备玩匹配相应单车运动的游戏。',
                    externalLink: 'https://my.virzoom.com/login/',
                    logoUrl: 'upload/feed/image/1570fbf6b72-57966aab0075ef4a7b015f0d-51f55eb6db3420ce',
                    name: 'Virzoom'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'KittAi开发的声控检测系统，该公司的指令搜索引擎有效的利用关键词，提高用户使用时的便捷性。',
                    externalLink: 'http://kitt.ai/',
                    logoUrl: 'upload/feed/image/1570fc08a30-57966aab0075ef4a7b015f0d-3b3c89ce8fce9096',
                    name: 'KittAi'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '智能教育玩具帮助孩子启发想象力和创造力',
                    externalLink: 'http://www.woobo.io/',
                    logoUrl: 'upload/feed/image/1574d50d723-57966aab0075ef4a7b015f0d-ffc73232593a91ac',
                    name: 'Woobo'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'SaveOhno正在尝试将社会问题游戏化。 SaveOhno.org是一个在线平台，人们可以在这里将未来的变得更加美好。',
                    externalLink: 'saveohno.org',
                    logoUrl: 'upload/feed/image/1574d51cbba-57966aab0075ef4a7b015f0d-8ac32b609f6661c7',
                    name: 'Saveohno'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '我们提供了全方位的审计，税务和会计咨询服务。',
                    externalLink: 'shengen-cpa.com',
                    logoUrl: 'upload/feed/image/1574d52a3bb-57966aab0075ef4a7b015f0d-7b1b2ddfd40465f2',
                    name: 'Shen Gen'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '创建于2008年8月，是一家面向基于移动互联网的无线医疗产品的独创研发的高科技企业。',
                    externalLink: 'raiing.com',
                    logoUrl: 'upload/feed/image/1574d54f5b2-57966aab0075ef4a7b015f0d-0f92482c49fb3acc',
                    name: '睿仁医疗'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '我们开发的智能生物材料。这将帮助医生治疗和再生坏死的组织。',
                    externalLink: 'gelformed.com',
                    logoUrl: 'upload/feed/image/1574d55f5e1-57966aab0075ef4a7b015f0d-3a9ec029828d96c4',
                    name: 'Gel4Med'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '我们正在将3D打印技术与3D扫描技术结合，使设备能够自动测量，设计和生产出完全符合用户身体结构的产品',
                    externalLink: 'skelmet.com',
                    logoUrl: 'upload/feed/image/1574d56c682-57966aab0075ef4a7b015f0d-150a046eae92ecc3',
                    name: 'Skelmet'
                }
            },
            {
                teamCategory: 200,
                data: {
                    description: '卡内基梅隆大学中美创新创业峰会秉承CMU的创新精神,旨在推动中美两国的对话与合作,鼓励大学生创新思想的沟通与交流,促进科学技术与商业机遇的共同发展。',
                    teamId: '57903d48513d1a002ffe3ba5',
                    logoUrl: 'upload/team/image/1560dc85636-570b0cdc3a905750048ef5d1-4d19fe0c1fc41dda',
                    name: 'CMU SUMMIT',
                    slogan: '卡内基梅隆大学中美创新创业峰会秉承CMU的创新精神,旨在推动中美两国的对话与合作,鼓励大学生创新思想的沟通与交流,促进科学技术与商业机遇的共同发展。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '我们帮助每个人从心理上改变他们不喜欢的坏习惯',
                    externalLink: 'gainlife.com',
                    logoUrl: 'upload/feed/image/1574d5eccc4-57966aab0075ef4a7b015f0d-9281b4d42d42d2b4',
                    name: 'Gain Life'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '机器之心是国内领先的前沿科技垂直媒体，关注人工智能，机器人，神经认知科学等前沿科技，旨在通过高质量的内容让用户更好的了解即将到来的下一次技术革命。',
                    externalLink: 'jiqizhixin.com',
                    logoUrl: 'upload/feed/image/1574d5fd517-57966aab0075ef4a7b015f0d-06fd1fd67b69b5a9',
                    name: '机器之心 Synced'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'OK车险是一款囊括车险算价投保、理赔、加油、违章代办、汽车借贷及自驾保障等功能的车主服务平台。',
                    externalLink: 'okchexian.com',
                    logoUrl: 'upload/feed/image/1574d6412e6-57966aab0075ef4a7b015f0d-f5be589887f7ebe4',
                    name: 'OK车险'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '我们是一家总部设在波士顿的医疗器械公司，开发感染监控的传感器。',
                    externalLink: 'qsmdiagnostics.com',
                    logoUrl: 'upload/feed/image/1574d651b7a-57966aab0075ef4a7b015f0d-971ca32c1f999449',
                    name: 'QSM Diagnostics Inc'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '吃，不是一个人的事儿--社交·聚合·团餐',
                    externalLink: 'http://yunbanbao.com/',
                    logoUrl: 'upload/feed/image/157746bb57e-57966aab0075ef4a7b015f0d-bc1823cef2b3f1a2',
                    name: '云板报'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Menusifu提供下一代餐厅软件解决方案。',
                    externalLink: 'http://www.menusifu.com/',
                    logoUrl: 'upload/feed/image/157746fecfd-57966aab0075ef4a7b015f0d-d2a28157f0fb95a5',
                    name: 'Menusifu'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '解决美国运营商价格昂贵问题，携号转网，号码不变！',
                    externalLink: 'http://www.callmr.com/',
                    logoUrl: 'upload/feed/image/1577477cca9-57966aab0075ef4a7b015f0d-33669b99bb1c52de',
                    name: '卡美移动通信'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'NimbleDroid是一家位于美国纽约，提供 App自动测试服务的创新性公司，致力于解决应用卡顿崩溃等严重影响用户体验的问题，帮助app开发者打造更棒的应用，赢得更多的用户。',
                    externalLink: 'https://nimbledroid.com/',
                    logoUrl: 'upload/feed/image/15774799c8f-57966aab0075ef4a7b015f0d-d7c95ebf246fa616',
                    name: 'Nimbledroid'
                }
            },
            {
                teamCategory: 200,
                data: {
                    description: '像在亚马逊购物一样买车。网上下单，送车到家，不满意就退货。',
                    teamId: '57ae601d4200faf743a092c5',
                    logoUrl: 'upload/team/image/15681290126-573cd01ccdb007b90c71277c-259737ef48279d5c',
                    name: 'Carloha懒人汽车',
                    slogan: '像在亚马逊购物一样买车。网上下单，送车到家，不满意就退货。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '帧实',
                    logoUrl: 'upload/feed/image/1577942bd2a-57966aab0075ef4a7b015f0d-ca3585c0fa3dfd87',
                    slogan: 'phoreal致力于构建一个摄影的平台，每一个人都可以在这里分享摄影与他们的羁绊，也能享受到摄影的魅力。摄影也食人间烟火'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'COOLCOMPOSITES, INCORPORATED',
                    logoUrl: 'upload/feed/image/1577944584f-57966aab0075ef4a7b015f0d-f1950f5589f2c8c8',
                    externalLink: 'http://www.coolcomposites.com/',
                    slogan: 'CoolComposites使用化学材料，让建筑在不增加成本的情况下变得更加节能。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'D&P BIOINNOVATIONS, LLC',
                    logoUrl: 'upload/feed/image/15779452714-57966aab0075ef4a7b015f0d-51623dcc365035ff',
                    externalLink: 'www.dpbioinnovations.com',
                    slogan: 'D＆P Bioinnovations致力于修复受损器官。我们正在开发可植入式的医疗装置以帮助受损食道再生。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Dope',
                    logoUrl: 'upload/feed/image/1577945c390-57966aab0075ef4a7b015f0d-74ceed237f7a5a60',
                    slogan: '汇聚全球最优质品牌的社交购物平台，集奢侈品电商、买家秀主题的图片式社交于一身，打造全球最专业的高端社交购物APP。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'AquaFresco',
                    logoUrl: 'upload/feed/image/1577946d285-57966aab0075ef4a7b015f0d-dc047b90be68cfb9',
                    externalLink: 'http://aquafresco.co',
                    slogan: 'AquaFresco用其突破性的废水循环系统重新塑造50亿的洗衣服务市场。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Phzter INC',
                    logoUrl: 'upload/feed/image/15779478422-57966aab0075ef4a7b015f0d-25d5e885c37a562c',
                    externalLink: 'http://www.phzter.com/',
                    slogan:
                        'PHZTER取自谐音faster，get service faster旨在打造一个renting平台，基于C2C的理念， 我们着力打破传统的出租观念，提出全新的理念，目前活跃在美东的留学生群体。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '北京伊林伊路电子商务有限公司',
                    logoUrl: 'upload/feed/image/15779481e63-57966aab0075ef4a7b015f0d-3c5330e0eed97cbf',
                    externalLink: 'http://101club.com/',
                    slogan: '我们提供一站式跨境分销服务：从品牌资源，物流发货，到售后服务一步到位，从根本上解决了目前跨境海淘的一切壁垒。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'CleanIU',
                    logoUrl: 'upload/feed/image/157794a393f-57966aab0075ef4a7b015f0d-7930e3d11f3af764',
                    externalLink: 'http://cleaniu.com/',
                    slogan: '送货上门洗衣服务。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '迈吉客科技',
                    logoUrl: 'upload/feed/image/157794c9b75-57966aab0075ef4a7b015f0d-07e3cba4f23289b5',
                    externalLink: 'http://www.appmagics.com/',
                    slogan: '卡通形象教学创意无限; 内容跳出书本增强表达; 丰富互动方式寓教于乐'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Wizdy',
                    logoUrl: 'upload/feed/image/157794d4380-57966aab0075ef4a7b015f0d-333255aca3fcdf3e',
                    externalLink: 'http://wizdygames.com/',
                    slogan: '我们相信游戏有益身心健康。 Wizdy利用游戏引导孩子养成健康的生活习惯。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Pundit',
                    logoUrl: 'upload/feed/image/157794e77e5-57966aab0075ef4a7b015f0d-e1b83e66c4fe5fd1',
                    externalLink: 'https://getpundit.com',
                    slogan: 'Pundit是一个语音社交平台，可以让你随时开始一场脱口秀。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Phoenix Green Food Inc',
                    logoUrl: 'upload/feed/image/157794ff79c-57966aab0075ef4a7b015f0d-7d2395bb20f6b360',
                    externalLink: 'http://www.phoenixgreenfood.com',
                    slogan: '我们的全天然产品和有机产品不包含任何人工防腐剂，香料，或色素'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Asian Kitchen Management LLC',
                    logoUrl: 'upload/feed/image/1577950a0fb-57966aab0075ef4a7b015f0d-e492ee70083ba452',
                    externalLink: 'http://asian-kitchen.com/',
                    slogan: '把冰箱搬入云端, 智能推荐菜谱, 食材送货上门'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '北美留学生日报',
                    logoUrl: 'upload/feed/image/157795194b8-57966aab0075ef4a7b015f0d-b11951755ca3f646',
                    externalLink: 'http://www.collegedaily.cn/',
                    slogan: '北美留学生日报创立于2013年底，坚持真实，独立，有味道的宗旨，报道留学生的新闻，发掘留学生活故事，分享海外的多彩世界。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '异乡好居',
                    logoUrl: 'upload/feed/image/1577957f0bf-57966aab0075ef4a7b015f0d-a6d0ace457be4cd9',
                    externalLink: 'http://www.uhouzz.com',
                    slogan: '解决海外留学生住房难题'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '问校友',
                    logoUrl: 'upload/feed/image/1577958bef7-57966aab0075ef4a7b015f0d-bafd68c86841adc8',
                    externalLink: 'http://www.wenxiaoyou.com/',
                    slogan: '问校友是由哈佛资深校友发起的在线咨询和分享平台，为寻求海外学习和全球发展机会的中国青少年提供高质量的指导和服务。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '牧诚律师事务所',
                    logoUrl: 'upload/feed/image/1577959537a-57966aab0075ef4a7b015f0d-79e8c0ef89b40635',
                    externalLink: 'http://www.magstonelaw.net/',
                    slogan: '牧诚律师事务所致力于为其客户，包括跨国公司、中小型企业、初创企业和个人客户，提供最高效、最经济的高质量法律服务。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '外联教育',
                    logoUrl: 'upload/feed/image/157795a4f37-57966aab0075ef4a7b015f0d-c78b3c9c09c625f7',
                    externalLink: 'http://www.wailianvisa.com/',
                    slogan: '作为外联出国顾问集团的子公司，外联教育致力于为留学美国的学生提供高品质的教育方案，进入一流的艺术学院、优秀高中和大学，提供规划和日常辅导，以及社会实践和实习的机会。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '瑞联集团',
                    logoUrl: 'upload/feed/image/157795bd191-57966aab0075ef4a7b015f0d-d6de6b80aff75442',
                    externalLink: 'http://www.relatedusa.com/cn/about-related1',
                    slogan: '瑞联集团是美国最大的私人房地产开发公司之一。集团拥有40余年的业界经验，并经营自己的EB-5区域中心，为有意向的投资人提供直接联系项目开发商的机会。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'AirFox',
                    logoUrl: 'upload/feed/image/157795d4bd2-57966aab0075ef4a7b015f0d-916d66c287890df1',
                    externalLink: 'http://airfox.io',
                    slogan: 'AirFox为消费者和移动运营商，在世界各地大大降低了移动服务的成本。我们致力于用更低的价格，让更多的人用上移动互联网'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'FactoryShop',
                    logoUrl: 'upload/feed/image/157795e8191-57966aab0075ef4a7b015f0d-1849ff8e85b44685',
                    externalLink: 'http://www.factoryshopusa.com',
                    slogan: '我们帮助商店整合以及再分配运营资源，以帮助减少货物积压，接受紧急订单，以及维护关键客户关系。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '地凡科技',
                    logoUrl: 'upload/feed/image/157795f8482-57966aab0075ef4a7b015f0d-3376b7a2b55203fc',
                    externalLink: 'http://thefinetechs.com/services/',
                    slogan: '我们为您提供商务上的IT解决方案'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '亨瑞集团',
                    logoUrl: 'upload/feed/image/1577960c6f5-57966aab0075ef4a7b015f0d-8eee29e51054fc4e',
                    externalLink: 'http://www.visa800.com/',
                    slogan: '亨瑞集团作为全球最大的移民留学教育机构。亨瑞集团倾情打造的HG Plus亨瑞留学生实习就业平台'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '波士顿网源设计',
                    logoUrl: 'upload/feed/image/15779627e9b-57966aab0075ef4a7b015f0d-48c92eadb792f53c',
                    externalLink: 'http://www.bostonwebpower.com/',
                    slogan: '我們公司为中小企业提供专业化的的网站开发服务，为您增加业务，降低成本，增強客戶关系提供有效的解決方案。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'MT 律师事务所',
                    logoUrl: 'upload/feed/image/15779644340-57966aab0075ef4a7b015f0d-9d3e6d658348484c',
                    externalLink: 'http://www.mtlawllc.com/en',
                    slogan: 'MT 律师事务所为个人和企业竭诚提供众多关键法律业务，帮助您成功实现个人需要和商务国际化。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '社交思维',
                    logoUrl: 'upload/feed/image/1577964c6f2-57966aab0075ef4a7b015f0d-60c219c679a23d47',
                    slogan:
                        'VENUSTUS 是一个致力于人际交往和自我提升的新媒体频道，其前身为拥有百万级播放量的视频栏目《微视》。 VENUSTUS 频道倡导“通过持续行动，让自己每天进步一点点”，目前已聚集一批高质量原创作者，拥有来自多个国家的数千名用户。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'DatingPitch',
                    logoUrl: 'upload/feed/image/1577965d30f-57966aab0075ef4a7b015f0d-2479d91394cd060f',
                    externalLink: 'https://pitch.dating/',
                    slogan: 'DatingPitch起源于波士顿地区，最早用于哈佛和MIT的单身男女相亲交友用，世界首创的最有效率1对多的相亲模式，迅速火爆全美，在全美6个城市覆盖了5000超高质量会员。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'SporNet LLC',
                    logoUrl: 'upload/feed/image/15779683295-57966aab0075ef4a7b015f0d-6c9c0951d6e97926',
                    externalLink: 'http://spornetapp.com/',
                    slogan: 'SporNet是一个体育社交网络平台。将“体育和网”的概念结合在一起起源，我们为体育爱好者提供了一个全新的体育社交网络平台。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '全美华人创投联盟',
                    logoUrl: 'upload/feed/image/1577968ce2c-57966aab0075ef4a7b015f0d-49fa5938ede1fc5c',
                    externalLink: 'https://caea-usa.org',
                    slogan: '全美华人创投联盟是一个立足美国面向全球的组织，其宗旨是为所有有理想，有激情，追求创业创新的华人及其国际伙伴提供一个信息技术交流，资源利益共享的平台，为创业者和投资人铺路搭桥。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'CUTLER & WILENSKY, LLP',
                    logoUrl: 'upload/feed/image/157796a2ee2-57966aab0075ef4a7b015f0d-39127a32db2de696',
                    externalLink: 'http://www.cutlerlegal.com/',
                    slogan: '本所为美国境内和境外的公司、商业团体及个人你提供综合的法律服务。本所两名合伙人在各自的领域里执业二十多年，本所能提供流利的中文服务。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Regulus',
                    logoUrl: 'upload/feed/image/157796b3883-57966aab0075ef4a7b015f0d-bc5dad091584717c',
                    externalLink: 'http://regulusrx.com',
                    slogan: '我们正在研究运用微分子RNA疗法疗法来治愈多种疾病。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Unicareer',
                    logoUrl: 'upload/feed/image/157796bf358-57966aab0075ef4a7b015f0d-5505ddfe574d6e44',
                    externalLink: 'http://unicareer.org',
                    slogan: 'UniCareer提供全面的职业发展资讯服务。为国际学生和专业人士提供不同阶段的职业解决方案。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '易美留学',
                    logoUrl: 'upload/feed/image/157796ca505-57966aab0075ef4a7b015f0d-21a1bf77ae1a1cc5',
                    externalLink: 'http://www.easymayus.com/',
                    slogan: '易美留学创立于世界金融中心纽约华尔街，利用本土化优势多年来为中国留学生提供最真实可靠的高端留学咨询服务。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '鹭博文化传播',
                    logoUrl: 'upload/feed/image/157796def4e-57966aab0075ef4a7b015f0d-de4e5d46cbca5413',
                    externalLink: 'heroninterverse.com',
                    slogan: '波士顿龙虾君、纽约松鼠君，一个是波士顿的生活指南，一个是纽约的吃喝路书'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Ease Travel',
                    logoUrl: 'upload/feed/image/157796ec01b-57966aab0075ef4a7b015f0d-2c6b5853fbb38136',
                    externalLink: 'http://www.sealbrous.com/',
                    slogan: 'EaseTravel是一家跨国初创旅行公司，主营各类邮轮业务，我们秉承“诚信、激情、创新、多赢”的企业理念，致力于开发旅游企业电子商务和网络营销应用，打造综合型旅游网站。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '语义信息科技有限公司',
                    logoUrl: 'upload/feed/image/157796fa9f8-57966aab0075ef4a7b015f0d-d7d3a31b33cf629f',
                    externalLink: 'www.usources.cn',
                    slogan: '语义信息科技有限公司是一家新成立的数据管理初创公司'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '张瑞纹会计师事务所',
                    logoUrl: 'upload/feed/image/1577970778c-57966aab0075ef4a7b015f0d-105ce242c8800118',
                    externalLink: 'http://changcpa.com/',
                    slogan: '张瑞纹会计师事务所专为新创企业、中小型企业、提供公司登记、记帐、稅务申报、会计师签证等服务。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '华美银行',
                    logoUrl: 'upload/feed/image/1577970f3a5-57966aab0075ef4a7b015f0d-fb65bb5fcb6c8188',
                    externalLink: 'https://www.eastwestbank.com/',
                    slogan: '华美银行现为全美以华裔为主要市场规模最大的商业银行。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '野孩子',
                    logoUrl: 'upload/feed/image/1577971c2b7-57966aab0075ef4a7b015f0d-ce93b09e7d9971ef',
                    externalLink: 'https://wildkiz.com/',
                    slogan: '野孩子是由一群热爱户外，并且切身感受到户外对生活所带来的积极影响的小伙伴们创建。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Advanced Real Estate Consulting LLC',
                    logoUrl: 'upload/feed/image/157797288cb-57966aab0075ef4a7b015f0d-c08cc737d1b9239a',
                    externalLink: 'http://advancedrealty-home.com/',
                    slogan: 'Advanced Real Estate Consulting是马萨诸塞州的房地产经纪公司。为客户提供个性化的房地产需求。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Babson College',
                    logoUrl: 'upload/feed/image/1577973b15c-57966aab0075ef4a7b015f0d-6feec3d24f75c6ba',
                    externalLink: 'http://www.babson.edu/Pages/default.aspx',
                    slogan: 'Babson College是一家成立于1919年的私立商科大学，是全美最负盛名的创业学学院。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Biolmag',
                    logoUrl: 'upload/feed/image/15779746346-57966aab0075ef4a7b015f0d-37238a802cd3f1c9',
                    externalLink: 'http://www.bioimag.com/',
                    slogan: 'BioImag是一家医疗诊断公司，通过非侵入性诊断法检测体内酶的活性来了解身体发生的变化。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'OSC Technologies',
                    logoUrl: 'upload/feed/image/1577974f400-57966aab0075ef4a7b015f0d-003e7c3a55ceb9ef',
                    externalLink: 'http://www.oscgc.com/',
                    slogan: 'OSC Technologies提供最高质量的IT咨询服务'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: "Kellogg's Research Labs",
                    logoUrl: 'upload/feed/image/1577975a704-57966aab0075ef4a7b015f0d-fcc3b9a2339a114a',
                    externalLink: 'https://www.kelloggsresearchlabs.com/',
                    slogan: "Kellogg's Research Labs专注于记忆金属的开发"
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '汉美中国',
                    logoUrl: 'upload/feed/image/15779767aea-57966aab0075ef4a7b015f0d-aeca6ea34b76f69a',
                    externalLink: 'http://www.hanmeiservicedoffices.com',
                    slogan: '汉美中国专注于服务式写字楼的投资及运营'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '博德山庄',
                    logoUrl: 'upload/feed/image/15779776429-57966aab0075ef4a7b015f0d-f2f6158debafb0ca',
                    externalLink: 'http://www.thepreserveri.com',
                    slogan: '欢迎光临博德山庄——一个私人高尔夫球场和度假区，提供您非凡的度假体验。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: '北美大推手',
                    logoUrl: 'upload/feed/image/157797821af-57966aab0075ef4a7b015f0d-3d84f3f7c72a9972',
                    externalLink: 'www.boostinsider.com',
                    slogan: '北美大推手，来自硅谷，拥有10万美国大v，覆盖4亿粉丝，包括美国，加拿大，欧洲，澳洲。目前是美国最大的网红平台。'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Yalber从事各种金融产品的投资。',
                    externalLink: 'http://www.yalber.com/',
                    logoUrl: 'upload/feed/image/157797b81e3-57966aab0075ef4a7b015f0d-798e7b7edebdfe66',
                    name: 'Yalber'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Waycook帮助您分享和发现附近新的自制美食。',
                    externalLink: 'https://itunes.apple.com/us/app/waycook-order-seamless-homemade/id1004552356?mt=8',
                    logoUrl: 'upload/feed/image/157797c7076-57966aab0075ef4a7b015f0d-03b4df20bd17c580',
                    name: 'Waycook'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '相由心生',
                    externalLink: 'http://www.hanli.photos/',
                    logoUrl: 'upload/feed/image/157797d9847-57966aab0075ef4a7b015f0d-f71190d77922ba09',
                    name: 'Han Li Photography'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Arkview Media（视野方舟）制作团队具有多年中美两国的工作生活及媒体制作经验，，精心打造一系列纪录片及访谈节目，为观众对比讲述中美两国的文化差异，提供全新的视觉体验。',
                    logoUrl: 'upload/feed/image/157797e125a-57966aab0075ef4a7b015f0d-f2e943977658b2f8',
                    name: '视野方舟'
                }
            }
        ],
        zhRandomMedia: [
            {
                teamCategory: 100,
                data: {
                    slogan: '国内权威电子商务新闻门户，围绕传统企业做电商、B2B、B2C、外贸电商、移动电商、电商服务、电商政策、电商资本等领域开展信息与研究服务，在电子商务行业拥有巨大影响力。',
                    externalLink: 'http://www.ebrun.com/',
                    logoUrl: 'upload/feed/image/156e26e4779-57966aab0075ef4a7b015f0d-ffaad7ad38a61a62',
                    name: '亿邦动力'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '关注智能硬件创新创业的科技媒体，提供新潮科技产品的体验式测评文章，已入驻多家媒体平台，服务数十万智能硬件爱好者。',
                    externalLink: 'http://www.iterduo.com/',
                    logoUrl: 'upload/feed/image/156e2712f0c-57966aab0075ef4a7b015f0d-d4b3e7aa1720517b',
                    name: 'IT耳朵'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '所属名都传媒，是一本以城市为主题的中文期刊。为居住在北美城市或到此旅游的华语人士提供北美主流城市资讯。',
                    externalLink: 'http://www.mandarinquarterly.com/',
                    logoUrl: 'upload/feed/image/156e2721457-57966aab0075ef4a7b015f0d-676ccc8e747bda52',
                    name: '名都季刊'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '留学生自己发声的媒体,中国最大的独立留学生新媒体讲述留学生自己的故事，享有趣而真实的留学生活，中立、真实、有味道,你值得关注。',
                    externalLink: 'http://www.collegedaily.cn/',
                    logoUrl: 'upload/feed/image/156e27346c0-57966aab0075ef4a7b015f0d-e28aa8c432097ac8',
                    name: '北美留学生日报'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '优质创业活动的聚集地，通过精准的活动链接优质创业人群，陪伴创业青年成长！',
                    externalLink: 'http://www.17shang.cc/',
                    logoUrl: 'upload/feed/image/156e275674b-57966aab0075ef4a7b015f0d-e48d31268d3b3163',
                    name: '一起上NEWS'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '没有记录就没有发生过，记录最精彩的留学生活，做最美的留学短视频。',
                    externalLink: 'http://v.qq.com/vplus/onthewheel',
                    logoUrl: 'upload/feed/image/156e6ca1f72-57966aab0075ef4a7b015f0d-f4d30528a595a2a0',
                    name: '车轮上的留学生'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '立足硅谷的科技媒体,探秘全球科技精华,为你带来最新的产品报道,读硅谷,看硅谷,去硅谷。',
                    externalLink: 'http://www.svinsight.com',
                    logoUrl: 'upload/feed/image/156e6cb49a9-57966aab0075ef4a7b015f0d-25c04e9940df4f3e',
                    name: '硅谷密探'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '公司2011年8月成立于北京，是领先的移动短视频娱乐分享应用和移动视频技术服务提供商。',
                    externalLink: 'http://www.yixia.com/',
                    logoUrl: 'upload/feed/image/156e6cbc008-57966aab0075ef4a7b015f0d-8d635c6834f5f795',
                    name: '一下科技'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '西雅图第一个属于 IT人自己的圈子，无论你是在职场打拼的程序猿，攻城狮or产品锦鲤，还是打算在这个科技之都寻找自己归属的new comers，都欢迎你加入圈子，一起打拼一起飞！',
                    logoUrl: 'upload/feed/image/156e6cd0894-57966aab0075ef4a7b015f0d-eeea43d109ebf448',
                    name: '西雅图 IT 圈'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '由资深IT人士邓煜坤2015年于上海创办的互联网创业媒体，专注于互联网早期创业项目的报道和互联网创新技术的报道。',
                    externalLink: 'http://www.ihoushi.com/',
                    logoUrl: 'upload/feed/image/156e6d3d0d3-57966aab0075ef4a7b015f0d-543b1da1c2628ce7',
                    name: '厚势新媒体'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '中国浏览量最大的中文门户网站，是腾讯公司推出的集新闻信息、互动社区、娱乐产品和基础服务为一体的大型综合门户网站。',
                    externalLink: 'http://www.qq.com/',
                    logoUrl: 'upload/feed/image/156e6d4a85e-57966aab0075ef4a7b015f0d-7dd46a3aae1b7a92',
                    name: '腾讯网'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '为用户提供24小时不间断的最新资讯，及搜索、邮件等网络服务。',
                    externalLink: 'http://www.sohu.com/',
                    logoUrl: 'upload/feed/image/156e6d766ae-57966aab0075ef4a7b015f0d-7d6583fbafb475ec',
                    name: '搜狐'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '中国金融信息网(新华08网)是新华社主办财经网站,致力打造中国金融信息领域权威发布平台。',
                    externalLink: 'http://www.xinhua08.com',
                    logoUrl: 'upload/feed/image/156e6d8070c-57966aab0075ef4a7b015f0d-e5af3b3b9b72b4b9',
                    name: '中国金融信息网'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        '安生集团投资创办的子品牌，是中国国内首家专注海归求职的垂直招聘平台，旨在服务海归求职、晋升与创业。行业顶尖名企，海量高薪职位一手掌控，高端人才精准引荐服务；首创个性化的视频简历展示方式，配搭视频面试+视频宣讲会的数字化应聘途径，让求职晋升实现高效，高质，随时随地，仅在指尖之遥。',
                    externalLink: 'www.ZhiMaQiao.com',
                    logoUrl: 'upload/feed/image/156e6d8ba94-57966aab0075ef4a7b015f0d-8b5d6ef6381847a6',
                    name: '芝麻桥'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '纽约名利场FameNYC，汇聚纽约各界翘楚、名媛、青年才俊专访，网络红酒珠宝、高级会所等上流生活资讯，是最in最时尚最高端的私人专属平台。',
                    externalLink: 'http://weibo.com/u/5902671235?refer_flag=1005050010_&is_hot=1',
                    logoUrl: 'upload/feed/image/156e6d96706-57966aab0075ef4a7b015f0d-09d9efb30602cb9d',
                    name: '纽约名利场'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        '国内社群驱动型创业媒体第一品牌，旗下产品包括“CEO说”创业微课堂，希鸥会、希鸥创业营、希鸥创业沙龙、希鸥私董会、中国创新创业领袖峰会等产品，平台活跃着数万名创业者和数百位投资人，目前在21个城市有办事处。',
                    externalLink: 'http://www.xiouhui.com',
                    logoUrl: 'upload/feed/image/156e6da0c65-57966aab0075ef4a7b015f0d-18215f5d2c545361',
                    name: '希鸥网'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '播出时间是周一到周四每晚9点到12点，周五到周日为晚上8点到12点，手机App和电台网站可以全天候收听过往节目。',
                    externalLink: 'https://chineseradioseattle.com',
                    logoUrl: 'upload/feed/image/156e6dae0b4-57966aab0075ef4a7b015f0d-4e4d33d8d250047f',
                    name: '西雅图中文电台'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '美通社，中文全称“美国企业新闻通讯公司”，是一个为公司新闻与公共关系提供服务的大型通讯社，在全世界超过十国以上设有办公室，使用语言可达40种，影响范围可括及170个国家和地区。',
                    externalLink: 'http://en.prnasia.com',
                    logoUrl: 'upload/feed/image/156e6dcbe7f-57966aab0075ef4a7b015f0d-15241c4a399e8fa1',
                    name: '美通社'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        '钛媒体是TMT（Tech\\Media\\Telecom）的谐音，起源于TMT行业。TMT是科技、媒体和通信三个英文单词的缩写，意指未来（互联网）科技、媒体和通信的TMT产业。TMT中的技术更多是以IT和前沿科技为主，Media更多是以互联网，固定互联网、移动互联网为代表的新媒体。',
                    externalLink: 'http://www.tmtpost.com',
                    logoUrl: 'upload/feed/image/156e6dd86a5-57966aab0075ef4a7b015f0d-09d36f02277a4bbb',
                    name: '钛媒体'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        '成立于2007年，是中国领先的专业创业服务平台，致力于帮助创业者实现创业梦想，推动各领域高成长企业快速发展。创业邦为创业者提供高价值的资讯与服务，推动中国创新创业。旗下拥有媒体互动、创业孵化、融资服务等业务。',
                    externalLink: 'http://www.cyzone.cn',
                    logoUrl: 'upload/feed/image/156e6de540f-57966aab0075ef4a7b015f0d-5374c81f141c767b',
                    name: '创业邦'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '专注原创的科技创业新媒体，发布最新的创业资讯、互联网公司新闻、投融资对接，以鸵鸟数据为驱动，提供专业洞见，挖掘潜力创业公司！',
                    externalLink: 'http://tuoniao.fm',
                    logoUrl: 'upload/feed/image/156e6df724f-57966aab0075ef4a7b015f0d-93f554042b2cabae',
                    name: '鸵鸟电台'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '关注创业和投资的互联网媒体。以投资人视角，全景关注移动互联网，集中报道最新创业项目，对创业创新提供前瞻性的分析和观察。以媒体为核心，打造创业者服务生态。',
                    externalLink: 'http://mp.i.sohu.com/suc6898784/profile',
                    logoUrl: 'upload/feed/image/156e6e1d842-57966aab0075ef4a7b015f0d-d57337c23a053c79',
                    name: 'B12'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '坚守用心服务创业者的理念，专注创业创新，互联网创业项目推荐，关注新产品、新公司、新模式，以原创独家报道、分析以及美国硅谷的一手报道闻名业界。',
                    externalLink: 'http://www.lieyunwang.com',
                    logoUrl: 'upload/feed/image/156e6e2dbef-57966aab0075ef4a7b015f0d-aa679bba20285c84',
                    name: '猎云网'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '成立于1998年,是北美领先的由学生组织的中国话题论坛。十九年来,我们一直致力于打造群英荟萃的交流平台,让政商文化各界翘楚分享见解。',
                    externalLink: 'http://www.harvardchina.org',
                    logoUrl: 'upload/feed/image/156e6e42832-57966aab0075ef4a7b015f0d-022743fbb0a79c37',
                    name: '哈佛中国论坛'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '以独特视角呈现科技圈内大事小事，内容包括互联网、IT业界、通信、趋势、科技访谈等。',
                    externalLink: 'http://tech.163.com',
                    logoUrl: 'upload/feed/image/156e6e4d9e6-57966aab0075ef4a7b015f0d-db1277eeefb7f5d7',
                    name: '网易科技'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '中国第一中文分类信息网站，涵盖房产、车辆、招工、兼职、黄页等海量的生活分类信息，满足不同的查询需求,',
                    externalLink: 'http://g.58.com/city',
                    logoUrl: 'upload/feed/image/156e6e6847c-57966aab0075ef4a7b015f0d-d2144e925f71806d',
                    name: '58同城'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '专注于互联网+的新媒体和服务机构，以驱动创业创新和助力传统产业转型升级为使命，旗下有亿欧和天窗两款产品。',
                    externalLink: 'www.iyiou.com',
                    logoUrl: 'upload/feed/image/156e6e84897-57966aab0075ef4a7b015f0d-5d8a0caf937bd2e8',
                    name: '亿欧'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '提供7X24小时财经资讯及全球金融市场报价，覆盖股票、债券、基金、期货、信托、理财、管理等多种面向个人和企业的服务。',
                    externalLink: 'http://finance.sina.com.cn',
                    logoUrl: 'upload/feed/image/156e6e9f1ba-57966aab0075ef4a7b015f0d-6f59d4617319c573',
                    name: '新浪财经'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '全球华人新主流财经门户，第一时间为用户提供专业财经资讯与数据应用服务，以独立的价值判断、独到的报道角度，全面覆盖宏观、商业、金融、证券、理财等各个领域。',
                    externalLink: 'http://finance.ifeng.com',
                    logoUrl: 'upload/feed/image/156e6eae231-57966aab0075ef4a7b015f0d-9571b667aa2c5955',
                    name: '凤凰财经'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '全新24小时数字频道,24.4,覆盖纽约五大城市,提供详细的播放指南,直播新闻,综艺节目,热门电视剧等 。',
                    externalLink: 'http://www.sinovision.net',
                    logoUrl: 'upload/feed/image/156e7464585-57966aab0075ef4a7b015f0d-b40282b8db0a9876',
                    name: '美国中文电视台'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '作为全球移动营销第一平台，是全球第一家集内容和服务于一体的移动营销综合平台。',
                    externalLink: '北京真巧文化传媒有限公司',
                    logoUrl: 'upload/feed/image/156e7478bf7-57966aab0075ef4a7b015f0d-fbeb13a729756fa9',
                    name: '北京真巧文化传媒有限公司'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        '西创会旨在团结西雅图周围至北美的高层次创业人才和团队，搭建一个供创业人才和团队、创业投资者及各专业领域人士交流与合作的平台，并以多样化的服务来帮助各方取得成功，借此推动西雅图及北美地区创业活动的发展。',
                    externalLink: 'http://www.seattlestartup.org',
                    logoUrl: 'upload/feed/image/156e7486e84-57966aab0075ef4a7b015f0d-88df14aefbaad04d',
                    name: '西雅图创业协会'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '提供免费即时报价，最新财经新闻，个人投资组合，各国外币汇价，以及环球市场咨询。',
                    externalLink: 'https://finance.yahoo.com/',
                    logoUrl: 'upload/feed/image/156e763aac2-57966aab0075ef4a7b015f0d-18177e9be8282e33',
                    name: '雅虎财经'
                }
            },
            {
                teamCategory: 200,
                data: {
                    description: '我不卖龙虾，我只捕捉波士顿最新鲜的享乐情报，我是丰满的龙虾君。',
                    teamId: '57d9a687388cb31e027a0b38',
                    logoUrl: 'upload/team/image/1572a2ff74f-569ea3909508a64f04bef0fa-91f927aebb323561',
                    name: '波士顿龙虾君',
                    slogan: '我不卖龙虾，我只捕捉波士顿最新鲜的享乐情报，我是丰满的龙虾君。'
                }
            }
        ],

        zhRandomPartners: [
            {
                teamCategory: 100,
                data: {
                    slogan: '瑞联集团是美国最大的私人房地产开发公司之一。集团拥有40余年的业界经验，并经营自己的EB-5区域中心，为有意向的投资人提供直接联系项目开发商的机会。',
                    externalLink: 'www.relatedUSA.com',
                    logoUrl: 'upload/feed/image/157109e43f4-57966aab0075ef4a7b015f0d-be317aeab8c1d393',
                    name: '瑞联集团'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '中科招商集团是中国首家经政府批准设立的大型人民币创业投资基金专业管理机构，公司管理总部设在北京。',
                    externalLink: 'http://www.leadvc.com/',
                    logoUrl: 'upload/feed/image/15710a2b4d4-57966aab0075ef4a7b015f0d-506788bcb8e6a083',
                    name: '中科招商集团'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '外联教育：作为外联出国顾问集团的子公司，外联教育致力于为留学美国的学生提供高品质的教育方案，进入一流的艺术学院、优秀高中和大学，提供规划和日常辅导，以及社会实践和实习的机会。',
                    externalLink: 'http://www.wailianvisa.com/',
                    logoUrl: 'upload/feed/image/1575e9beaeb-55a037aad1f41e51044f6071-f14ac36da63b232c',
                    name: '外联教育'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Come Up Capital is a $50M early stage venture capital fund based in Minneapolis that invests in tech startups primarily headquartered in the US Midwest.',
                    externalLink: 'http://www.comeup.capital/',
                    logoUrl: 'upload/feed/image/1575e8dc365-55a037aad1f41e51044f6071-1f2cad500a2145f1',
                    name: 'Come Up Capital'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '汉美中国专注于服务式写字楼的投资及运营，目前在北京、上海、郑州、包头已投资运营10余个服务式办公空间。',
                    externalLink: 'http://www.hanmeiservicedoffices.com/',
                    logoUrl: 'upload/feed/image/1575e8bbfd3-55a037aad1f41e51044f6071-118fde8ca950e57b',
                    name: '汉美中国'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '天津异乡好居网络科技有限公司是一家由新东方、利欧集团股份和众信旅游共同背书，且已完成Pre-A轮融资的互联网公司。',
                    externalLink: 'http://www.uhouzz.com/',
                    logoUrl: 'upload/feed/image/15710a5fb85-57966aab0075ef4a7b015f0d-71493e489511641e',
                    name: '天津异乡好居网络科技有限公司'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        '北京泰有投资管理有限公司是由余龙文、孙焱联合新东方教育集团董事长俞敏洪，美国路易斯安那州第一商业银行联合创始人嵇跃勤等企业家共同发起设立，是专注于种子和天使阶段的私募股权投资基金管理公司。',
                    externalLink: 'http://www.taiyoufund.com/',
                    logoUrl: 'upload/feed/image/15710a47e31-57966aab0075ef4a7b015f0d-75e89b1465f7afc9',
                    name: '北京泰有投资管理有限公司'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '一个线上咨询 + 知识分享的平台，邀请全球名校校友在线，为每年数十万计的出国留学和寻求全球职业发展的中国年轻人提供高质量的线上咨询和问答服务。',
                    externalLink: 'http://www.wenxiaoyou.com/',
                    logoUrl: 'upload/feed/image/157308aa9d7-55a037aad1f41e51044f6071-2fad1763f539540e',
                    name: '问校友科技有限公司'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        '牧诚律师事务所在美国硅谷成立，在美国东、西两岸设有办公室。是一家新型的律师事务所。牧诚所有的律师都是在美国顶尖大律所工作多年、经验丰富的资深律师。牧诚律师事务所致力于为其客户，包括跨国公司、中小型企业、初创企业和个人客户，提供最高效、最经济的高质量法律服务。',
                    externalLink: 'http://www.magstonelaw.net/',
                    logoUrl: 'upload/feed/image/157308a5d74-55a037aad1f41e51044f6071-40b4f54cd12c2d13',
                    name: '牧诚律师事务所'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '亨瑞集团作为全球最大的移民留学教育机构，自2009年以来，已在美国参与投资60多个地产项目，直接投融资超过30亿美元。',
                    externalLink: 'http://www.visa800.com/',
                    logoUrl: 'upload/feed/image/1573087e198-55a037aad1f41e51044f6071-bedea6adfc7e3a47',
                    name: '亨瑞集团'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        '杭州硅谷孵化基金（Westlake Ventures）立足美国硅谷，聚焦于大数据、云计算、互联网安全、企业级应用、生物技术等领域，通过对以硅谷为代表的技术创新项目进行早期投资并提供增值服务，培育具有全球化发展前景的海外优秀创新创业项目。',
                    externalLink: 'http://www.westlakevc.com/',
                    logoUrl: 'upload/feed/image/15710a99951-57966aab0075ef4a7b015f0d-eecf7998fc0a32d2',
                    name: '杭州硅谷孵化基金'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: '中文短视频世界范围扩展者',
                    externalLink: 'http://wap.huaplus.tv/',
                    logoUrl: 'upload/feed/image/15743e0fa0f-57966aab0075ef4a7b015f0d-7807767788666a5e',
                    name: '华＋'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Takusan Sushi是波士顿最棒的日本寿司自助亚洲餐厅，提供舒适的就餐环境，拥有各类生鲜鱼片，以及小龙虾，烤三文鱼，烤牛肉，羊肉串的吧台，距离Fenway Park 仅5分钟路程。',
                    externalLink: 'http://takusansushi.com/',
                    logoUrl: 'upload/feed/image/15710ab2778-57966aab0075ef4a7b015f0d-848bb8b9e6b7e9c9',
                    name: 'Takusan Sushi'
                }
            }
        ]
    },

    en: {
        title: '2017 US-China VC Summit & Startup Expo',
        header: {
            sloganLiaoyuanUrl: 'assets/images/en/slogan-liaoyuan.png',
            homepage: 'Home'
        },
        first: {
            reviewUrl: 'assets/images/en/review@2x.png',
            logoUrl: 'assets/images/en/2017En@2x.png',
            location: 'Boston Hynes Convention Center',
            ticket: 'Tickets',
            download:'Download'
        },
        second: {
            title: 'Partners',
            host: 'Liaoyuan',
            role: 'Host',
            desc: 'Liaoyuan is the next-generation service platform for Entrepreneurship and Venture Capital in North America.' +
            ' Liaoyuan provides informational and financial support for early-stage startups, by connecting investors' +
            ' and startups of the U.S. and China. The Liaoyuan website and mobile app has attracted talents and' +
            ' high quality startup projects from over 100 universities across North America. Liaoyuan has a service' +
            ' line of talent acquisition, startup event planning, IT consulting & software development and' +
            ' incubation for early-stage projects.\n\nLiaoyuan currently has over 50,000 registered personal and' +
            ' institutional members, and has hosted or published over 2,200 startup events in North America with ' +
            'an exposure to over 400,000 Chinese students and local entrepreneurs. Liaoyuan is helping dozens of' +
            ' top VC institutes from U.S. and China by providing investment leads and talent acquisition service.' +
            ' Liaoyuan is headquartered in Boston and has an office in Shanghai. Liaoyuan has completed two rounds ' +
            'of funding of over 1.8 million USD and is currently seeking Series A funding.',
            sponsors: 'If you are interested in sponsoring the Summit, please email us at',
            more: 'SEE ALL'
        },
        third: {
            investAndFinanceExpo: {
                title: 'VC panel discussions',
                content:
                    'VC panel discussions aim to facilitate in-depth discussions of the latest investment trends among investors.'
            },
            startUpEx: {
                title: 'The Startup Expo / Career Fair',
                content:
                    'The Startup Expo will draw more than 200 U.S. and Chinese startups to showcase their innovative ideas, encouraging entrepreneurs and investors to interact across borders.'
            },
            eliteBanquet: {
                title: 'The Elites Dinner',
                content:
                    'At the Elites Dinner, participants will earn a spectacular opportunity to network with the VIP guests.'
            }
        },
        concertTicket: 'Purchase Ticket Separately',
        agenda: [
            {
                date: '9.29',
                weekday: 'Friday',
                events: [
                    {
                        locationPrimary: 'Warm-Up',
                        locationDetail: 'Royale Boston',
                        activities: [
                            {
                                time: '7:00 PM - 11:00 PM',
                                content: 'Summit Warm-Up HHH Concert'
                            }
                        ]
                    }
                ]
            },
            {
                date: '9.30',
                weekday: 'Saturday',
                morning: {
                    events: [
                        {
                            locationPrimary: 'Main Session',
                            locationDetail: 'Room 302/304/306',
                            activities: [
                                {
                                    time: '8:00 AM - 9:00 AM',
                                    content: 'Registration & Reception'
                                },
                                {
                                    time: '9:00 AM - 9:20 AM',
                                    content: 'Consumption Upgrade - Cen Wang, Sequoia Capital China, Partner'
                                },
                                {
                                    time: '9:20 AM - 9:40 AM',
                                    content: 'Artificial Intelligence - Feng Li, Frees Fund, Founding Partner'
                                },
                                {
                                    time: '9:40 AM - 10:10 AM',
                                    content: 'Innovation in Fintech',
                                    subContent: ['Hao Zou, Abundy, Founder & Forbes US 30 under 30, Visiting Full Professor at Stanford University', 'Yiting Liu, Coalescence Partners, Investment Management Partner', 'Chandler Guo, Well-known Bitcoin Angel Investor']
                                },
                                {
                                    time: '10:10 AM - 10:40 AM',
                                    content: 'Fireside Chats - Investment and Trend of US-China Innovation',
                                    subContent: ['Zac Pan, Lightspeed China Partners, Associate Partner', 'David Chang, PersonalVC Co-Founder & HBS EIR']
                                },
                                {
                                    time: '10:40 AM - 10:55 AM',
                                    content: 'Entrepreneurship in the US: How to obtain resource from US Government and Firms - Jiansheng Li, President, US-China Development Operations & Chairman, Shanghai Boston Startup Lab'
                                },
                                {
                                    time: '10:55 AM - 11:10 AM',
                                    content: 'Fintech in Wall Street -  Lex Fenwick, Ex-CEO of Bloomberg & Dow Jones、Heckyl. CEO'
                                },
                                {
                                    time: '11:10 AM - 11:25 AM',
                                    content: 'Speed up the incubator -  Brittany McDonough, Masschallenge, Director of Global Partnerships'
                                },
                                {
                                    time: '11:25 AM - 12:05 PM',
                                    content: 'Chinese Enterprise in U.S and Overseas Asset Allocation',
                                    subContent: ['Yi Guo，Univest Securities, COO', 'George Chen, Homaer Financial，Co-Founder & CIO', 'May Cheung, Easy Print, Founder & CEO', 'Shaoyuan Fu, National Securities, Senior Investment Advisor']
                                },
                                {
                                    time: '12:05 PM - 12:40 PM',
                                    content: 'Focus – Artificial Intelligence',
                                    subContent: ['Sue Xu, Amino Capital, General Partner', 'Lu Zhang, Fusion Fund Capital, Founding and Managing Partner Forbes US 30 under 30 & Featured as the Honoree of VC category', 'Yao Zhang, RoboTerra，Founder & CEO & World Economy Forum, Member of AI and Robotics', 'Yu Wang, IDG Capital, North America Project Manager']
                                },
                                {
                                    time: '12:40 PM - 2:00 PM',
                                    content: 'Lunch & Break'
                                }

                            ]
                        }
                    ]
                },
                break: {
                    description: '12：40 PM - 2：00 PM Lunch & Break'
                },
                afternoon: {
                    events: [
                        {
                            locationPrimary: 'Panel 1',
                            locationDetail: 'Room 302',
                            activities: [
                                {
                                    time: '2:00 PM - 3:00 PM',
                                    content: 'US-China AI Opportunity & Challenge',
                                    subContent: ['Yi Li, Amazon AI Service, Project Manger', 'Pei Qi, Brain Robotics Capital (BRC)', 'Changfeng Wang, Deep Cognitive Machines, Co-Founder', 'Jingyuan Wang, NewDo Venture, Managing Partner']
                                },
                                {
                                    time: '3:00 PM - 4:00 PM',
                                    content: 'Educational Technology v.s. Technology Education',
                                    subContent: ['Tony Gao, Easytransfer, Co-Founder', 'Beibei Li, ZhenFund, North America Project Manager', 'Vincent Fung, IBIS Capital Investment partner', 'Guan Wang, Sunny Crown, Co-Founder']
                                },
                                {
                                    time: '4:00 PM - 5:00 PM',
                                    content: 'Development and application of Big Data',
                                    subContent: ['Chen Zhao, E3 Capital, General Partner', 'Chandler Guo, Well-known Bitcoin Angel Investor', 'Tao Wu, Microsoft, Senior AI and Research Data Anayltics Manager', 'Ziming Zheng, TripAdvisor, Senior Data Scientist']
                                },
                                {
                                    time: '5:00 PM - 6:00 PM',
                                    content: 'Development and Trend of US Education Entrepreneurship',
                                    subContent: ['Richard Di Tieri, Startup Institute CEO ', 'Zinan Chen, UniWill Ventures, Co-Founder', 'Cole Scanlon, Fair Opportunity Project, Co-Founder', 'Feng Tan, Woboo, Ceo & Co-Founder']
                                }
                            ]
                        },
                        {
                            locationPrimary: 'Panel 2',
                            locationDetail: 'Room 304',
                            activities: [

                                {
                                    time: '2:00 PM - 3:00 PM',
                                    content: 'Investment Opportunities among the Emerging Consumers',
                                    subContent: ['ac Pan, Lightspeed China Partners, Associate Partner', 'Alex Zhou, Yamibuy.com, Founder & CEO', 'Yifei Ju, Lit Asset Management, Project Partner', 'Elaine Li, Zonvon Capital, Founder Partner']
                                },
                                {
                                    time: '3:00 PM - 4:00 PM',
                                    content: 'Fintech: Big Data & AI',
                                    subContent: ['George Gong, Beyondsoft, Co-Founder & Angel Investor', 'Donghui Zhang, Facebook, Big-data hands-on architect', 'Victor Cao, Even-Better, Product and Operations Director', 'Sue Xu, Amino Capital, General Partner']
                                },
                                {
                                    time: '4:00 PM - 5:00 PM',
                                    content: 'Entrepreneurship Opportunities for International Students',
                                    subContent: ['Guoyu Lin, College Daily, Founder & CEO', 'Hang Tian, NYIS, Co-Founder', 'Tony Gao, Easytransfer, Co-Founder', 'Chen Li, 「Liaoyuan」, Co-Founder', 'Yining Cui, EasyMay, Founder & CEO', 'April Huang，Dealmoon East Coast, Regional Director']
                                },
                                {
                                    time: '5:00 PM - 6:00 PM',
                                    content: 'Career in the US',
                                    subContent: ['Miao Cao, McKinsey & Co, Human Resource in Finance, North America Manager', 'Yiting Liu, Coalescence Partners, Investment Management Partner', 'Yi Li, Amazon Rekognition Manger', 'nze Liang, Marcum Bernstein & Pinchuk LLP，Director']
                                }
                            ]
                        },
                        {
                            locationPrimary: 'Panel 3',
                            locationDetail: 'Room 306',
                            activities: [
                                {
                                    time: '2:00 PM - 3:00 PM',
                                    content: 'The Bio Medical Development in Boston (US-China)',
                                    subContent: ['Ren Du, Heuristic Capital, Managing Director', 'Hao Zou, Abundy, Founder Forbes US 30/30, Visiting Full Professor at Stanford University', 'Bowen Zhao, QuantiHealth, Founder & CEO', 'Alan Jiang, Xtalpi Inc, Chief Strategy Officer']
                                },
                                {
                                    time: '3:00 PM - 4:00 PM',
                                    content: 'Chinese Enterprise in U.S.',
                                    subContent: ['Joe Q. SUN, Capital Impetus Group Limited, CEO', 'Catherine X. Pan – Giordano, Dorsey & Whitney United States and China,President', 'Enze Liang, Marcum Bernstein & Pinchuk LLP，Director', 'Jennifer Yang, Co.Media，CEO']
                                },
                                {
                                    time: '4:00 PM - 5:00 PM',
                                    content: 'The future of the SaaS',
                                    subContent: ['Qi Su, MODELO, Founder & CEO', 'Millie Liu, Procyon Ventures, Co-Founder ', 'Yu Li, Menusifu Co-Founder & CEO', 'PTC Corporate Development VP Ben Tao', 'Brad Cordova, TrueMotion Co-Founder & CTO']
                                },
                                {
                                    time: '5:00 PM - 6:00 PM',
                                    content: 'Entertainment & Lifestyle in North America',
                                    subContent: ['Henry Kong, Neo-Soul Entertainment, Founder', 'Wener Ma, NEBULAR, Co-Foumder', 'Da Gui, Ehomie, Founder', 'Amy Duan, Chihuo Inc, Founder & CEO']
                                }
                            ]
                        }
                    ]
                },
                evening: {
                    events: [
                        {
                            locationPrimary: 'VIP Dinner',
                            locationDetail: 'Room 312',
                            activities: [
                                {
                                    time: '7:30 PM - 9:00 PM',
                                    content: 'VIP Dinner (Dress Code: Business Casual)'
                                }
                            ]
                        }
                    ]
                }
            },
            {
                date: '10.1',
                weekday: 'Sunday',
                events: [
                    {
                        locationPrimary: 'Main Session',
                        locationDetail: 'Room 302/304',
                        activities: [
                            {
                                time: '8:30 AM - 9:30 AM',
                                content: 'Registration'
                            },
                            {
                                time: '9:30 AM - 5:00 PM',
                                content: 'Startup Expo'
                            }
                        ]
                    },

                    {
                        locationPrimary: 'Panel',
                        locationDetail: 'Room 306',
                        activities: [
                            {
                                time: '9:30 AM- 11:30 AM',
                                content: 'Startup Pitch, great opportunity for startups to communicate with investors'
                            },
                            {
                                time: '11:30 AM - 12:30 PM',
                                content: 'EdTech Panel',
                                subContent: ['Dinghong Chen, Tomorrow Advancing Life, Oversea Business Chief Advisor', 'Vincent Fung, IBIS Capital Investment partner', 'Yao Zhang, RoboTerra，Founder & CEO & World Economy Forum, Member of AI and Robotics', 'Guan Wang, Sunny Crown, Co-Founder']
                            },
                            {
                                time: '1:30 PM - 2:30 PM',
                                content: 'Law Panel - entrepreneurship for International Students',
                                subContent: ['Vera Su, NYIS Law Firm, Managing Attorney']
                            },
                            {
                                time: '2:30 PM- 3:30 PM',
                                content: 'Explore the market for startups',
                                subContent: ['Shenhaochen Zhu, Zuouo Media, Co-Founder', 'Houda Li, After School Mart, Co-Founder', 'Austin, Edge, Co-Founder', 'Mandy, FoodEasyGo, Co-Founder', 'Yang Pan, VisualPathy, Co-Founder']

                            },
                            {
                                time: '3:30 PM- 4:30 PM',
                                content: 'Kickstarter abroad',
                                subContent: ['Rongbin Chen, Poputar, Co-Founder & COO', 'Vbike, North America Director', 'Dongwei Yao, Rstorm, Co-Founder', 'Jennifer Yang, Co.Media，CEO']
                            },
                            {
                                time: '4:30 PM- 5:30 PM',
                                content: 'Funding for startups'
                            }
                        ]
                    }
                ]
            }
        ],
        agendaMore: 'Download Brochure',
        speaker: {
            title: 'Speakers',
            titleUrl: 'assets/images/en/text-speaker.png',
            more: 'SEE ALL',
            invited: 'Keynote',
            preliminary: 'Panel'
        },
        startupExpo: {
            titleUrl: 'assets/images/en/text-startup-expo.png',
            summary:
                'The Startup Expo will draw more than 100 U.S. and Chinese startups. Booth application is now officially open.',
            title: 'Teams',
            more: 'SEE ALL',
            description:
                'The Startup Expo will draw more than 200 U.S. and Chinese startups. Booth application is now officially open.',
            contact: 'For booth application, please click APPLY and fill out the form.'
        },
        media: {
            titleUrl: 'assets/images/en/text-media.png',
            title: 'Media',
            more: 'SEE ALL'
        },
        startupMarathon: {
            titleUrl: 'assets/images/en/text-startup-marathon.png',
            description:
                'Liaoyuan Startup Marathon aims to gather entrepreneurial resources and talents from different fields, creating an efficient platform for U.S. and Chinese investors and entrepreneurs to communicate.',
            generalContact:
                "For early registration or any general questions, please contact us at <a class='email' href='mailto:general@us.liaoyuan.io'>general@us.liaoyuan.io</a>",
            partnerContact:
                "To partner with us and host the Startup Marathon in your city, please contact us at <a class='email' href='mailto:partnership@us.liaoyuan.io'>partnership@us.liaoyuan.io</a>"
        },
        partner: {
            titleUrl: 'assets/images/en/text-partner.png',
            host: {
                titleUrl: 'assets/images/en/text-host.png',
                description:
                    'Liaoyuan is a social networking platform for the STARTUP community to connect with each other. We provide the platform to help entrepreneurs, investors and industry professionals to efficiently acquire valuable resources by enabling explicit and personalize searching. Users can find active communication and resources including events, feeds and startup jobs. Liaoyuan and its community are here with you alongside of your entrepreneurial journey. Liaoyuan officially launched its Web and iOS App in Chinese version on April 6th, 2016.'
            },
            content:
                "If you are interested in sponsoring the Summit, please email us at <a class='email' href='mailto:sponsors@us.liaoyuan.io'>sponsors@us.liaoyuan.io</a>",
            more: 'SEE ALL'
        },
        contact: {
            title: 'Contact',
            general: {
                text: 'General Questions: ',
                email: 'general@us.liaoyuan.io'
            },
            sponsorship: {
                text: 'Sponsorship: ',
                email: 'sponsors@us.liaoyuan.io'
            },
            partner: {
                text: 'Partnership: ',
                email: 'partnership@us.liaoyuan.io'
            },
            press: {
                text: 'Press Inquiries: ',
                email: 'press@us.liaoyuan.io'
            },
            opening: {
                text: 'Volunteer Recruitment Inquiries: ',
                email: 'hr@us.liaoyuan.io'
            }
        },
        footer: {
            ticket: 'Tickets',
            agenda: 'Agenda',
            review: 'Review',
            speakers: 'Speakers',
            teams: 'Startup Expo',
            media: 'Media',
            partner: 'Partners',
            liaoyuan: 'About Liaoyuan',
            socialMedia: 'Social Media:'
        },
        applyPage: {
            titleUrl: 'assets/images/en/text-startup-expo-booth-application.png',
            description:
                'The Startup Expo will draw more than 400 U.S. and Chinese startups to showcase their innovative ideas, encouraging entrepreneurs to interact across borders and enabling investors to identify promising startups.',
            formDescription:
                'Please fill out and submit the following form. Our staffs will contact you shortly to answer your questions and provide you with detailed information regarding the exhibition and booth application materials. ',
            companyNameUrl: 'assets/images/en/text-company-name.png',
            companySiteUrl: 'assets/images/en/text-company-site.png',
            contactNameUrl: 'assets/images/en/text-contact-name.png',
            contactPositionUrl: 'assets/images/en/text-contact-position.png',
            contactPhoneUrl: 'assets/images/en/text-contact-phone.png',
            contactEmailUrl: 'assets/images/en/text-contact-email.png',
            submit: 'Booth Application',
            info: 'If you have any questions, please contact startupexpo@us.liaoyuan.io',
            successContent:
                'You have successfully submitted the booth application for the Startup Expo. Our representative will contact you shortly.',
            errorContent: 'Company name and email are required.',
            okButton: 'OK'
        },
        applyConfirmation: {
            titleUrl: 'assets/images/en/text-apply-confirmation.png',
            description:
                'Thank you for applying “2016 US-China VC Summit & Startup Expo”. We’ve already confirmed your booth application.'
        },
        enRandomTeams: [
            {
                teamCategory: 200,
                data: {
                    name: 'PENCIL ONE Product Photography',
                    logoUrl: 'upload/team/image/15650df3f30-55a2843b2072b5510450aa80-88f4c8ce496f053e',
                    teamId: '57a2068bd7eae8ad054d686e',
                    description: 'Full-service commercial photography studio',
                    slogan: 'Full-service commercial photography studio'
                }
            },
            {
                teamCategory: 200,
                data: {
                    name: 'EsianMall（宜尚北美网）',
                    logoUrl: 'upload/team/image/156539dd9a3-5722a8e7f2b9f523328e1929-9bf1e8b3015ddba0',
                    teamId: '57a2b9b3d7eae8ad054d7517',
                    description: 'Cross-Board E-Commerce',
                    slogan: 'Cross-Board E-Commerce'
                }
            },
            {
                teamCategory: 200,
                data: {
                    name: 'VISUALPATHY',
                    logoUrl: 'upload/team/image/156572b5a8b-55e61a7e05928a4f04870ba1-d4a462aec910fd11',
                    teamId: '57a3a70bd7eae8ad054d7ff1',
                    description: 'VR, 3D Photography',
                    slogan: 'VR, 3D Photography'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Food Truck Stars',
                    logoUrl: 'upload/feed/image/1569a176d7f-57966aab0075ef4a7b015f0d-ccaaa3097643de9e',
                    externalLink: 'https://www.foodtruckstars.com/index.html',
                    slogan: '#1 food truck platform that connects customers with their local food trucks!'
                }
            },
            {
                teamCategory: 200,
                data: {
                    name: 'R-storm Technology LLC',
                    logoUrl: 'upload/team/image/157771f38a4-567082933e67695004d969a7-14caa2cbaf5756ee',
                    teamId: '57a0e4fbcea54c0f1d0f0a2f',
                    description: 'Cleaning Robotics',
                    slogan: 'Cleaning Robotics'
                }
            },
            {
                teamCategory: 200,
                data: {
                    name: 'Gridology美国格道教育科技',
                    logoUrl: 'upload/team/image/1565771e310-56c4e77f16030e50049efabe-162e6ea831be3ed6',
                    teamId: '57a3b6e2cea54c0f1d0f500c',
                    description: 'Gradgrid Database, Upgrid Consulting Service.',
                    slogan: 'Gradgrid Database, Upgrid Consulting Service.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'HUDWAY',
                    logoUrl: 'upload/feed/image/1569a2b7e7e-57966aab0075ef4a7b015f0d-131220b63615dbb6',
                    externalLink: 'http://hudwayapp.com/',
                    slogan: 'keeps your eyes on the road while driving.'
                }
            },
            {
                teamCategory: 200,
                data: {
                    name: '洋草全球购',
                    logoUrl: 'upload/team/image/1566a5854a9-57753d699baa35841eb784e3-2f527bc1e17e48af',
                    teamId: '57a88975cea54c0f1d0f81d5',
                    description: 'Shopping platform',
                    slogan: 'Shopping platform'
                }
            },
            {
                teamCategory: 200,
                data: {
                    name: 'CheersYou 清柚教育',
                    logoUrl: 'upload/team/image/1569471ad42-560c55d7a37e8f6704c6836b-6e26d3c1ad3aa6ad',
                    teamId: '57aa26bfd7eae8ad054dc27f',
                    description: 'Career Service, Master Application, College Application.',
                    slogan: 'Career Service, Master Application, College Application.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'LeakSpotter',
                    logoUrl: 'upload/feed/image/1569a3523f3-57966aab0075ef4a7b015f0d-f3d2fd1671165f4f',
                    slogan:
                        'Toilet leaks are huge water wasters and are mostly detected only after getting a huge water bill. Our thumbsized device solves this problem.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Coding',
                    logoUrl: 'upload/feed/image/156e57c5e61-57966aab0075ef4a7b015f0d-3cc7d8eed4dad9fc',
                    externalLink: 'http://yamatech.cn/',
                    slogan: 'Cloud development'
                }
            },
            {
                teamCategory: 200,
                data: {
                    name: '荷马金融',
                    logoUrl: 'upload/team/image/156b80cf3b0-571688226ac9e450042d150a-353edfe0c58ed158',
                    teamId: '57b1e1240de1c2c22e5ffd51',
                    description: 'Providing the most specialized overseas wealth management platform.',
                    slogan: 'Providing the most specialized overseas wealth management platform.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Modvion',
                    logoUrl: 'upload/feed/image/1569a3d6b60-57966aab0075ef4a7b015f0d-a9f9f50ab57b9318',
                    externalLink: 'http://www.modvion.com/',
                    slogan: 'Modvion develops modular wind power towers in composite materials.'
                }
            },
            {
                teamCategory: 200,
                data: {
                    name: 'I Do Wedding Boston',
                    logoUrl: 'upload/team/image/1564bf04aca-579a7780e1557d9e1e5158a2-9b9ca5e82253d36f',
                    teamId: '57a0c0bccea54c0f1d0f07da',
                    description: 'Boston based bridal shop and photographic services company.',
                    slogan: 'Boston based bridal shop and photographic services company.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: "FARMER WILLIE'S ALCOHOLIC GINGER BEER",
                    logoUrl: 'upload/feed/image/1569a3f2bef-57966aab0075ef4a7b015f0d-0c678380724512b7',
                    externalLink: 'http://www.thefarmerwillies.com/',
                    slogan: 'An advanced optical materials company.'
                }
            },
            {
                teamCategory: 200,
                data: {
                    name: 'PinOn',
                    logoUrl: 'upload/team/image/1572c1ca032-55cf962edd77a69704a00b05-6aff71cfc9ecaffb',
                    teamId: '57ad3439970e176573d93317',
                    description: 'Social App',
                    slogan: 'Social App'
                }
            },
            {
                teamCategory: 200,
                data: {
                    name: 'BrainCo',
                    logoUrl: 'upload/team/image/15680736376-573146b31fc7625004bb1ce1-548d1f4654a8cac2',
                    teamId: '57ae326e970e176573d94023',
                    description: 'Your Brain Controls Everything',
                    slogan: 'Your Brain Controls Everything'
                }
            },
            {
                teamCategory: 200,
                data: {
                    name: '美信金融',
                    logoUrl: 'upload/team/image/156808e6fca-57460f733362c6886920da79-d290c861fa31952c',
                    teamId: '57ae39054200faf743a08dab',
                    description: 'Financial Services',
                    slogan: 'Financial Services'
                }
            },
            {
                teamCategory: 200,
                data: {
                    name: '众方资本',
                    logoUrl: 'upload/team/image/1560e1330dc-559edb1600249b5004e543cd-583cae71bd1ddade',
                    teamId: '5790ec8ff28822a5390eaa7e',
                    description: 'Research, Science & Technology  Driven Financial Services  With a Touch of Art.',
                    slogan: 'Research, Science & Technology  Driven Financial Services  With a Touch of Art.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'NEW GROUNDS FOOD',
                    logoUrl: 'upload/feed/image/1569a43ca82-57966aab0075ef4a7b015f0d-81069a721eaa5f1d',
                    externalLink: 'http://newgroundsfood.com/',
                    slogan: 'Eat Your Coffee.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Modelo',
                    logoUrl: 'upload/feed/image/1569a456988-57966aab0075ef4a7b015f0d-c12c3ee8692e429c',
                    externalLink: 'http://www.modelo.io/',
                    slogan: 'Empowering collaboration & presentation for CAD users.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'DataXu',
                    logoUrl: 'upload/feed/image/1569a48b2df-57966aab0075ef4a7b015f0d-e09f0348c1d27c93',
                    externalLink: 'https://www.dataxu.com/',
                    slogan: '100% marketer-aligned data and analytics technology company.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'LumiereVR',
                    logoUrl: 'upload/feed/image/1569a4fa6ab-57966aab0075ef4a7b015f0d-2b9269c3e66ce99b',
                    externalLink: 'http://lumierevr.com/',
                    slogan: 'Building The Future Of Storytelling.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'GogoVan',
                    logoUrl: 'upload/feed/image/1569a5601ca-57966aab0075ef4a7b015f0d-e61d1ad313415eca',
                    externalLink: 'https://www.gogovan.com.hk/en/',
                    slogan: 'A Convenient new logistics service at your fingertips.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'TripAdvisor',
                    logoUrl: 'upload/feed/image/1569a59306c-57966aab0075ef4a7b015f0d-30a11e8dfcf335db',
                    externalLink: 'https://www.tripadvisor.com/',
                    slogan: 'Know better, Book better, Go better.'
                }
            },
            {
                teamCategory: 200,
                data: {
                    name: '「燎原」',
                    logoUrl: 'upload/team/image/155ff315808-5599de02e149c24e045442c3-363ec1dff7bacc3b',
                    teamId: '578cab4e59b299ff6be01643',
                    description:
                        'Liaoyuan is a social networking platform for the STARTUP community to connect with each other.',
                    slogan:
                        'Liaoyuan is a social networking platform for the STARTUP community to connect with each other.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'ENGINOLOGI INC.',
                    logoUrl: 'upload/feed/image/156c230ce0c-57966aab0075ef4a7b015f0d-7a4ea0f1f3d332ab',
                    externalLink: 'www.enginologi.com',
                    slogan: 'B2B SaaS company offering analytical engines'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'ConquerX',
                    logoUrl: 'upload/feed/image/156c2445f15-57966aab0075ef4a7b015f0d-3fbcfdc91a35e47e',
                    externalLink: 'www.conquerxlab.com',
                    slogan: 'Minimally invasive blood test'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Ibeehub',
                    logoUrl: 'upload/feed/image/156c248b21f-57966aab0075ef4a7b015f0d-a15aab7af946fbe6',
                    externalLink: 'http://ibeehub.org/',
                    slogan: 'Help people to launch their ideas.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'NangioTx',
                    logoUrl: 'upload/feed/image/156c2499e17-57966aab0075ef4a7b015f0d-7f21b65218f8d578',
                    externalLink: 'nangiotx.com',
                    slogan: 'Restore blood flow to dying muscles.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Lumit Audio, LLC',
                    logoUrl: 'upload/feed/image/156c2509947-57966aab0075ef4a7b015f0d-62d22c58864b45d7',
                    externalLink: 'lumit-audio.com',
                    slogan: 'Music recording application for the modern mobile musician.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'RoyalRoadL',
                    logoUrl: 'upload/feed/image/156c251f077-57966aab0075ef4a7b015f0d-66e2b9751626004d',
                    externalLink: 'royalroadl.com',
                    slogan: 'Onlie publisher'
                }
            },
            {
                teamCategory: 200,
                data: {
                    name: '飛翼地图－Fae Map',
                    logoUrl: 'upload/team/image/156f77a2d4d-56de1df668ed6b50041273ab-b255cf2308efebda',
                    teamId: '57a2d4bdcea54c0f1d0f44c3',
                    description: 'real-time social experience',
                    slogan: 'real-time social experience'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'EYL',
                    logoUrl: 'upload/feed/image/156c2631290-57966aab0075ef4a7b015f0d-6437374356768f99',
                    externalLink: 'www.eylpartners.com',
                    slogan: 'Better cyber sercurity software'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Jefit, Inc.',
                    logoUrl: 'upload/feed/image/156c278829b-57966aab0075ef4a7b015f0d-1b2036a727f99aa2',
                    externalLink: 'https://www.jefit.com/',
                    slogan: 'Workout tracking platform'
                }
            },
            {
                teamCategory: 200,
                data: {
                    name: 'PlusOne',
                    logoUrl: 'upload-cn/avatar/156bd5a2756-56f550762aa6935004260393-0134dc2332e40c3b',
                    teamId: '57bdc8f7168044005ba687e6',
                    description: 'AI virtual interaction',
                    slogan: 'AI virtual interaction'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'New Valence Robotics',
                    logoUrl: 'upload/feed/image/156e1f33702-57966aab0075ef4a7b015f0d-43a35b51bc82a33a',
                    externalLink: 'nvbots.com',
                    slogan: 'Cloud-connected 3D printer device'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'SHOO-IN',
                    logoUrl: 'upload/feed/image/156e1f4e4da-57966aab0075ef4a7b015f0d-0cc8d521c1e67bec',
                    externalLink: 'http://www.shoo-in.com/home',
                    slogan: 'We help job seekers land their dream jobs.'
                }
            },
            {
                teamCategory: 200,
                data: {
                    name: 'XtalPi',
                    logoUrl: 'upload/team/image/1569916648d-57583cbe5d0a7bff3ca483c8-808754ea9b6d4dc9',
                    teamId: '57b481ac3cd7fe7f4a5abb32',
                    description: 'Achieve accurate and efficient drug polymorph prediction.',
                    slogan: 'Achieve accurate and efficient drug polymorph prediction.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Serious Simulations',
                    logoUrl: 'upload/feed/image/156e1fb2e6a-57966aab0075ef4a7b015f0d-b94036e6dc57d21b',
                    externalLink: 'www.serioussimulations.com',
                    slogan: "the world's best virtual reality simulation training products"
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Xeno Therapeutics',
                    logoUrl: 'upload/feed/image/156e1fc3748-57966aab0075ef4a7b015f0d-1205e3f51145737c',
                    externalLink: 'http://xenotherapeutics.org',
                    slogan: 'Bringing xenoskin graft technology from the lab to patients'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Vitamio',
                    logoUrl: 'upload/feed/image/156e1ff6c29-57966aab0075ef4a7b015f0d-6d20b9c16ed411ce',
                    externalLink: 'http://www.yixia.com/',
                    slogan: 'Vitamio is a multimedia development framework on Android and iOS.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'USCareerPass.com',
                    logoUrl: 'upload/feed/image/157430cd763-57966aab0075ef4a7b015f0d-32e6f19f22ca8951',
                    externalLink: 'http://www.uscareerpass.com/',
                    slogan: 'Work with us and get your career pass to success.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Luminopia',
                    logoUrl: 'upload/feed/image/156e641a5de-57966aab0075ef4a7b015f0d-5ce7cacc37e688e2',
                    externalLink: 'https://luminopia.com/',
                    slogan: 'Enhancing vision using virtual reality.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Linkinusa',
                    logoUrl: 'upload/feed/image/156e7a50c88-57966aab0075ef4a7b015f0d-78dae0e122b4ee61',
                    externalLink: 'https://itunes.apple.com/us/app/lin-ke-mei-guo-sui-shi-sui/id1039598771?mt=8',
                    slogan: 'Leading online travel service providers and entertainment Gourmet Guide'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'LabMinds',
                    logoUrl: 'upload/feed/image/156e7aa1106-57966aab0075ef4a7b015f0d-6dff952edef163d1',
                    externalLink: 'http://www.labminds.co/',
                    slogan: 'A Revolution in the Lab'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Fetchpark',
                    logoUrl: 'upload/feed/image/156e7aac6a7-57966aab0075ef4a7b015f0d-941efbe75fd51a8f',
                    externalLink: 'http://fetchpark.com/',
                    slogan: 'Fetchpark is a transponder-based, app-complemented, automated parking service'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Create',
                    logoUrl: 'upload/feed/image/156e7ae7383-57966aab0075ef4a7b015f0d-db287067fc3c94ef',
                    externalLink: 'create.io',
                    slogan: 'Visualize your city'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'PermitZone',
                    logoUrl: 'upload/feed/image/156e7afe453-57966aab0075ef4a7b015f0d-e9bc0b8cbea9d41b',
                    externalLink: 'https://permitzone.com',
                    slogan: 'Deliver the information across any device'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'LISNR',
                    logoUrl: 'upload/feed/image/1570b8cd53a-57966aab0075ef4a7b015f0d-d9b9a75c4e6a3825',
                    externalLink: 'www.lisnr.com',
                    slogan:
                        'LISNR is a high frequency, inaudible technology; a communication protocol that sends data over audio.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Red Cube',
                    logoUrl: 'upload/feed/image/1570b8e368d-57966aab0075ef4a7b015f0d-0f7f6a1d640898f9',
                    externalLink: 'redcubestudio.com',
                    slogan:
                        'Red Cube is a cross-border one-stop shop that provides social video marketing and public relations service to early-stage companies.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'MailTime',
                    logoUrl: 'upload/feed/image/1570b8f43dd-57966aab0075ef4a7b015f0d-dac52ccf64fa89fa',
                    externalLink: 'http://jianxinapp.com',
                    slogan:
                        'MailTime is an instant email messaging applications. It can recombine complex e-mail format into the chat bubbles.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Rare Pink',
                    logoUrl: 'upload/feed/image/1570b963fc6-57966aab0075ef4a7b015f0d-c8201ec1faf2e022',
                    externalLink: 'https://www.rarepink.com',
                    slogan: 'Design Your Own Diamond Engagement Ring'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'GateWay Scholars Institute of Education',
                    logoUrl: 'upload/feed/image/1570b9710b2-57966aab0075ef4a7b015f0d-6b3c8a69bf5aaafe',
                    externalLink: 'http://www.gatewayscholars.com/about-us',
                    slogan: 'Our team is committed to help students to go study abroad.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Soft Robotics Inc.',
                    logoUrl: 'upload/feed/image/1570fa7cbff-57966aab0075ef4a7b015f0d-a3cfccb3424ef50a',
                    externalLink: 'http://www.softroboticsinc.com/',
                    slogan:
                        "By providing the world's best virtual reality simulation training products, our company focuses on training individuals and groups of lethal force professionals, emergency services personnel, and commercial/industrial professionals in high-danger and high-complexity tasks."
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'ObEN',
                    logoUrl: 'upload/feed/image/1570fafa30d-57966aab0075ef4a7b015f0d-d6bb45de5ecc3622',
                    externalLink: 'oben.com',
                    slogan:
                        'ObEN enables any Internet of Things (IoT) or virtual character to speak or sing in any voice and language, in just minutes.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'SurrealVR',
                    logoUrl: 'upload/feed/image/1570fb20dc0-57966aab0075ef4a7b015f0d-628f8ddf079597aa',
                    externalLink: 'http://www.surrealvr.com/',
                    slogan: 'Cross platform online multiplayers'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'ominicharge',
                    logoUrl: 'upload/feed/image/1570fb8c850-57966aab0075ef4a7b015f0d-c128e6e97f820433',
                    externalLink: 'http://www.omnicharge.co/',
                    slogan:
                        "Omnicharge's unique flow-through capabilities allow the battery to charge both your device and itself at the same time."
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Catalant',
                    logoUrl: 'upload/feed/image/1570fbb76ee-57966aab0075ef4a7b015f0d-b0ed46cadc5854e8',
                    externalLink: 'https://gocatalant.com/',
                    slogan:
                        'We use modern technology to connect your business with an expanding pool of top freelance talent. Find talent with the qualifications and expertise to match your needs, your budget, and the way you work.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'flx.bike',
                    logoUrl: 'upload/feed/image/1570fbdf43c-57966aab0075ef4a7b015f0d-76f90d42d5c9d495',
                    externalLink: 'https://flx.bike/',
                    slogan:
                        'Flux electric bikes are your wheels for the modern world。Allowing you to ride longer, explore further and conquer hills you never thought possible. Pedal or not, the choice is yours.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Virzoom',
                    logoUrl: 'upload/feed/image/1570fbf6b72-57966aab0075ef4a7b015f0d-51f55eb6db3420ce',
                    externalLink: 'https://my.virzoom.com/login/',
                    slogan:
                        'VirZOOM is a spinning equipment that can connect with major VR, such as Oculus. It comes with excercise games so that people can play games while do spinning.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'KittAi',
                    logoUrl: 'upload/feed/image/1570fc08a30-57966aab0075ef4a7b015f0d-3b3c89ce8fce9096',
                    externalLink: 'http://kitt.ai/',
                    slogan:
                        'KittAi is a voice detection system, that by using its hotword detection engine, system could efficiently understand the keywords. It is especially convenient to automation systems.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Woobo',
                    logoUrl: 'upload/feed/image/1574d50d723-57966aab0075ef4a7b015f0d-ffc73232593a91ac',
                    externalLink: 'http://www.woobo.io/',
                    slogan: 'The educational smart toy who inspires children to imagine and create'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Saveohno',
                    logoUrl: 'upload/feed/image/1574d51cbba-57966aab0075ef4a7b015f0d-8ac32b609f6661c7',
                    externalLink: 'saveohno.org',
                    slogan:
                        'SaveOhno is working on gamifying social causes. SaveOhno.org is an online platform where millennials can drive change today to improve her future world.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Shen Gen',
                    logoUrl: 'upload/feed/image/1574d52a3bb-57966aab0075ef4a7b015f0d-7b1b2ddfd40465f2',
                    externalLink: 'shengen-cpa.com',
                    slogan:
                        'At Shen Gen, we perform a full range of traditional assurance, tax, and accounting advisory services.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Raiing Medical Inc.',
                    logoUrl: 'upload/feed/image/1574d54f5b2-57966aab0075ef4a7b015f0d-0f92482c49fb3acc',
                    externalLink: 'raiing.com',
                    slogan:
                        'Founded in August 2008, Raiing Medical is researching and developing mobile Internet-based high-tech wireless medical products.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Gel4Med',
                    logoUrl: 'upload/feed/image/1574d55f5e1-57966aab0075ef4a7b015f0d-3a9ec029828d96c4',
                    externalLink: 'gelformed.com',
                    slogan:
                        'We develop smart biomaterials that will empower clinicians to treat and regenerate tissues in ways that were previously tough or impossible.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Skelmet',
                    logoUrl: 'upload/feed/image/1574d56c682-57966aab0075ef4a7b015f0d-150a046eae92ecc3',
                    externalLink: 'skelmet.com',
                    slogan:
                        'we are combining state of the art 3D scanning technologies with 3D printing to automatically measure, design, and produce one of a kind custom wearable products that are made just for you.'
                }
            },
            {
                teamCategory: 200,
                data: {
                    name: 'CMU SUMMIT',
                    logoUrl: 'upload/team/image/1560dc85636-570b0cdc3a905750048ef5d1-4d19fe0c1fc41dda',
                    teamId: '57903d48513d1a002ffe3ba5',
                    description:
                        "CMU Summit dedicate to promote dialogue and cooperation between the US and China, encourage communication and exchange of college students' creative thinking and promote common development of science, technology and business.",
                    slogan:
                        "CMU Summit dedicate to promote dialogue and cooperation between the US and China, encourage communication and exchange of college students' creative thinking and promote common development of science, technology and business."
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Gain Life',
                    logoUrl: 'upload/feed/image/1574d5eccc4-57966aab0075ef4a7b015f0d-9281b4d42d42d2b4',
                    externalLink: 'gainlife.com',
                    slogan: 'We help individuals change the behaviors that hold them back from the life they want.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'Synced',
                    logoUrl: 'upload/feed/image/1574d5fd517-57966aab0075ef4a7b015f0d-06fd1fd67b69b5a9',
                    externalLink: 'jiqizhixin.com',
                    slogan:
                        'Synced is a leading technology media platform focus on artificial intelligence, robotics, neuro-cognitive science and cutting-edge technology.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'OKchexian',
                    logoUrl: 'upload/feed/image/1574d6412e6-57966aab0075ef4a7b015f0d-f5be589887f7ebe4',
                    externalLink: 'okchexian.com',
                    slogan:
                        'OKchexian is an auto insurance covering the price of auto insurance operator, claims, refueling, illegal agency, the owner service platform car loans and car security and other functions.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    name: 'QSM Diagnostics Inc',
                    logoUrl: 'upload/feed/image/1574d651b7a-57966aab0075ef4a7b015f0d-971ca32c1f999449',
                    externalLink: 'qsmdiagnostics.com',
                    slogan:
                        'We are a Boston based medical device start-up company that develops sensors for infection monitoring.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Your Wechat activities assistant.',
                    externalLink: 'http://yunbanbao.com/',
                    logoUrl: 'upload/feed/image/157746bb57e-57966aab0075ef4a7b015f0d-bc1823cef2b3f1a2',
                    name: 'Yunbanbao'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Menusifu provide the next generation of restaurant software solutions.',
                    externalLink: 'http://www.menusifu.com/',
                    logoUrl: 'upload/feed/image/157746fecfd-57966aab0075ef4a7b015f0d-d2a28157f0fb95a5',
                    name: 'Menusifu'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Start to activate here without changing your phone number!',
                    externalLink: 'http://www.callmr.com/',
                    logoUrl: 'upload/feed/image/1577477cca9-57966aab0075ef4a7b015f0d-33669b99bb1c52de',
                    name: 'CALLMR'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        "At NimbleDroid, we're building cutting-edge tools to redefine how developers craft awesome apps.",
                    externalLink: 'https://nimbledroid.com/',
                    logoUrl: 'upload/feed/image/15774799c8f-57966aab0075ef4a7b015f0d-d7c95ebf246fa616',
                    name: 'Nimbledroid'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Phoreal is committed to building a photography platform',
                    logoUrl: 'upload/feed/image/1577942bd2a-57966aab0075ef4a7b015f0d-ca3585c0fa3dfd87',
                    name: 'Phoreal'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Make buildings more energy efficient',
                    externalLink: 'http://www.coolcomposites.com/',
                    logoUrl: 'upload/feed/image/1577944584f-57966aab0075ef4a7b015f0d-f1950f5589f2c8c8',
                    name: 'COOLCOMPOSITES, INCORPORATED'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Repair damaged organs',
                    externalLink: 'www.dpbioinnovations.com',
                    logoUrl: 'upload/feed/image/15779452714-57966aab0075ef4a7b015f0d-51623dcc365035ff',
                    name: 'D&P BIOINNOVATIONS, LLC'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'High-end Shopping platform',
                    logoUrl: 'upload/feed/image/1577945c390-57966aab0075ef4a7b015f0d-74ceed237f7a5a60',
                    name: 'Dope'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'cutting water and detergent use for laundry',
                    externalLink: 'http://aquafresco.co',
                    logoUrl: 'upload/feed/image/1577946d285-57966aab0075ef4a7b015f0d-dc047b90be68cfb9',
                    name: 'AquaFresco'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'PHZTER focus on breaking the traditional concept of renting forward into new ideas.',
                    externalLink: 'http://www.phzter.com/',
                    logoUrl: 'upload/feed/image/15779478422-57966aab0075ef4a7b015f0d-25d5e885c37a562c',
                    name: 'Phzter INC'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'We solve all problems in cross-border shopping',
                    externalLink: 'http://101club.com/',
                    logoUrl: 'upload/feed/image/15779481e63-57966aab0075ef4a7b015f0d-3c5330e0eed97cbf',
                    name: '101club'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Dry cleaning and laundry delivered to your door.',
                    externalLink: 'http://cleaniu.com/',
                    logoUrl: 'upload/feed/image/157794a393f-57966aab0075ef4a7b015f0d-7930e3d11f3af764',
                    name: 'CleanIU'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Connect Reality with Virtuality',
                    externalLink: 'http://www.appmagics.com/',
                    logoUrl: 'upload/feed/image/157794c9b75-57966aab0075ef4a7b015f0d-07e3cba4f23289b5',
                    name: 'appMagics'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'We believe in gaming for good.',
                    externalLink: 'http://wizdygames.com/',
                    logoUrl: 'upload/feed/image/157794d4380-57966aab0075ef4a7b015f0d-333255aca3fcdf3e',
                    name: 'Wizdy'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Pundit is a social voice platform that allows you to start interactive talkshows.',
                    externalLink: 'https://getpundit.com',
                    logoUrl: 'upload/feed/image/157794e77e5-57966aab0075ef4a7b015f0d-e1b83e66c4fe5fd1',
                    name: 'Pundit'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'All natural products or organic products',
                    externalLink: 'http://www.phoenixgreenfood.com',
                    logoUrl: 'upload/feed/image/157794ff79c-57966aab0075ef4a7b015f0d-7d2395bb20f6b360',
                    name: 'Phoenix Green Food Inc'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'New experience of eating',
                    externalLink: 'http://asian-kitchen.com/',
                    logoUrl: 'upload/feed/image/1577950a0fb-57966aab0075ef4a7b015f0d-e492ee70083ba452',
                    name: 'Asian Kitchen Management LLC'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'THe most popular wechat platform for Chinese students in North American.',
                    externalLink: 'http://www.collegedaily.cn/',
                    logoUrl: 'upload/feed/image/157795194b8-57966aab0075ef4a7b015f0d-b11951755ca3f646',
                    name: 'Collegedaily'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'We are devoted to being the authority in student rentals and foreign real estates buying for students studying abroad.',
                    externalLink: 'http://www.uhouzz.com',
                    logoUrl: 'upload/feed/image/1577957f0bf-57966aab0075ef4a7b015f0d-a6d0ace457be4cd9',
                    name: 'uhouzz'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Online consultation and knowledge sharing platform',
                    externalLink: 'http://www.wenxiaoyou.com/',
                    logoUrl: 'upload/feed/image/1577958bef7-57966aab0075ef4a7b015f0d-bafd68c86841adc8',
                    name: 'wenxiaoyou'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'High-quality legal services',
                    externalLink: 'http://www.magstonelaw.net/',
                    logoUrl: 'upload/feed/image/1577959537a-57966aab0075ef4a7b015f0d-79e8c0ef89b40635',
                    name: 'MagStone Law'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Wailian Education committed to provide high-quality education solutions',
                    externalLink: 'http://www.wailianvisa.com/',
                    logoUrl: 'upload/feed/image/157795a4f37-57966aab0075ef4a7b015f0d-c78b3c9c09c625f7',
                    name: 'Wailian Education'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Related is one of the largest private real estate development company in the USA.',
                    externalLink: 'http://www.relatedusa.com/cn/about-related1',
                    logoUrl: 'upload/feed/image/157795bd191-57966aab0075ef4a7b015f0d-d6de6b80aff75442',
                    name: 'Related'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'We Make Mobile Service Affordable',
                    externalLink: 'http://airfox.io',
                    logoUrl: 'upload/feed/image/157795d4bd2-57966aab0075ef4a7b015f0d-916d66c287890df1',
                    name: 'AirFox'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'A platform of machine shops, for the machine shops',
                    externalLink: 'http://www.factoryshopusa.com',
                    logoUrl: 'upload/feed/image/157795e8191-57966aab0075ef4a7b015f0d-1849ff8e85b44685',
                    name: 'FactoryShop'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'We offer successful IT solutions for you business',
                    externalLink: 'http://thefinetechs.com/services/',
                    logoUrl: 'upload/feed/image/157795f8482-57966aab0075ef4a7b015f0d-3376b7a2b55203fc',
                    name: 'The Fine Technology INC.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: "HG Plus Consulting Inc. is the world's largest educational institution for immigrants.",
                    externalLink: 'http://www.visa800.com/',
                    logoUrl: 'upload/feed/image/1577960c6f5-57966aab0075ef4a7b015f0d-8eee29e51054fc4e',
                    name: 'HG Plus Consulting Inc.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'We build powerful Website for you',
                    externalLink: 'http://www.bostonwebpower.com/',
                    logoUrl: 'upload/feed/image/15779627e9b-57966aab0075ef4a7b015f0d-48c92eadb792f53c',
                    name: 'Boston Web Power, Inc.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'We provide high quality law service to everyone',
                    externalLink: 'http://www.mtlawllc.com/en',
                    logoUrl: 'upload/feed/image/15779644340-57966aab0075ef4a7b015f0d-9d3e6d658348484c',
                    name: 'MT Law LLC'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'VENUSTUS is a new media channel dedicated to interpersonal communication and self-improvement.',
                    logoUrl: 'upload/feed/image/1577964c6f2-57966aab0075ef4a7b015f0d-60c219c679a23d47',
                    name: 'VENUSTUS'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Efficient dating platform',
                    externalLink: 'https://pitch.dating/',
                    logoUrl: 'upload/feed/image/1577965d30f-57966aab0075ef4a7b015f0d-2479d91394cd060f',
                    name: 'DatingPitch'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: "SporNet is a social networking app based on individual's sports preference.",
                    externalLink: 'http://spornetapp.com/',
                    logoUrl: 'upload/feed/image/15779683295-57966aab0075ef4a7b015f0d-6c9c0951d6e97926',
                    name: 'SporNet LLC'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'A platform for all Chinese and international entrepreneurs to share their knoweledge and information',
                    externalLink: 'https://caea-usa.org',
                    logoUrl: 'upload/feed/image/1577968ce2c-57966aab0075ef4a7b015f0d-49fa5938ede1fc5c',
                    name: 'caea'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'CUTLER & WILENSKY LLP specializes in representing businesses and individuals in a variety of legal matters',
                    externalLink: 'http://www.cutlerlegal.com/',
                    logoUrl: 'upload/feed/image/157796a2ee2-57966aab0075ef4a7b015f0d-39127a32db2de696',
                    name: 'CUTLER & WILENSKY, LLP'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'MicroRNA therapeutics will become a major new class of drugs.',
                    externalLink: 'http://regulusrx.com',
                    logoUrl: 'upload/feed/image/157796b3883-57966aab0075ef4a7b015f0d-bc5dad091584717c',
                    name: 'Regulus'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'UniCareer is a full-service career development platform',
                    externalLink: 'http://unicareer.org',
                    logoUrl: 'upload/feed/image/157796bf358-57966aab0075ef4a7b015f0d-5505ddfe574d6e44',
                    name: 'Unicareer'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'High-end education consulting services.',
                    externalLink: 'http://www.easymayus.com/',
                    logoUrl: 'upload/feed/image/157796ca505-57966aab0075ef4a7b015f0d-21a1bf77ae1a1cc5',
                    name: 'Easymay'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Professional living guide.',
                    externalLink: 'heroninterverse.com',
                    logoUrl: 'upload/feed/image/157796def4e-57966aab0075ef4a7b015f0d-de4e5d46cbca5413',
                    name: 'Heron Interverse'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'EaseTravel is a multinational start-up travel company.',
                    externalLink: 'http://www.sealbrous.com/',
                    logoUrl: 'upload/feed/image/157796ec01b-57966aab0075ef4a7b015f0d-2c6b5853fbb38136',
                    name: 'Ease Travel'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Solve problems through data',
                    externalLink: 'www.usources.cn',
                    logoUrl: 'upload/feed/image/157796fa9f8-57966aab0075ef4a7b015f0d-d7d3a31b33cf629f',
                    name: '语义信息科技有限公司'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: "Company registration, accounting, tax declaration and accountant's visa services",
                    externalLink: 'http://changcpa.com/',
                    logoUrl: 'upload/feed/image/1577970778c-57966aab0075ef4a7b015f0d-105ce242c8800118',
                    name: 'J Chang CPA, LLC'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'East West Bank is now the largest commercial banks for Chinese-American.',
                    externalLink: 'https://www.eastwestbank.com/',
                    logoUrl: 'upload/feed/image/1577970f3a5-57966aab0075ef4a7b015f0d-fb65bb5fcb6c8188',
                    name: 'East West Bank'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'WildKiz formed by a group of outdoor lovers. We want to spread positive impact of nature.',
                    externalLink: 'https://wildkiz.com/',
                    logoUrl: 'upload/feed/image/1577971c2b7-57966aab0075ef4a7b015f0d-ce93b09e7d9971ef',
                    name: 'WildKiz Corp'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Advanced Real Estate Consulting LLC is a Massachusetts Real Estate Brokerage firm',
                    externalLink: 'http://advancedrealty-home.com/',
                    logoUrl: 'upload/feed/image/157797288cb-57966aab0075ef4a7b015f0d-c08cc737d1b9239a',
                    name: 'Advanced Real Estate Consulting LLC'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'The most prestigious entrepreneurship college in the United States.',
                    externalLink: 'http://www.babson.edu/Pages/default.aspx',
                    logoUrl: 'upload/feed/image/1577973b15c-57966aab0075ef4a7b015f0d-6feec3d24f75c6ba',
                    name: 'Babson College'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'BioImag is a medical diagnostic company focused on improving outcomes',
                    externalLink: 'http://www.bioimag.com/',
                    logoUrl: 'upload/feed/image/15779746346-57966aab0075ef4a7b015f0d-37238a802cd3f1c9',
                    name: 'Biolmag'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'OSC Technologies provides the highest quality IT consulting to satisfy enterprise requirements',
                    externalLink: 'http://www.oscgc.com/',
                    logoUrl: 'upload/feed/image/1577974f400-57966aab0075ef4a7b015f0d-003e7c3a55ceb9ef',
                    name: 'OSC Technologies'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        "Kellogg's Research Labs specializes in memory metal development, consulting and product applications.",
                    externalLink: 'https://www.kelloggsresearchlabs.com/',
                    logoUrl: 'upload/feed/image/1577975a704-57966aab0075ef4a7b015f0d-fcc3b9a2339a114a',
                    name: "Kellogg's Research Labs"
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Han Mei focus on service-oriented office investment and operations',
                    externalLink: 'http://www.hanmeiservicedoffices.com',
                    logoUrl: 'upload/feed/image/15779767aea-57966aab0075ef4a7b015f0d-aeca6ea34b76f69a',
                    name: 'Han Mei'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Boulder Hills is a private golf and sporting retreat dedicated to providing extraordinary and natural experiences for the select few.',
                    externalLink: 'http://www.thepreserveri.com',
                    logoUrl: 'upload/feed/image/15779776429-57966aab0075ef4a7b015f0d-f2f6158debafb0ca',
                    name: 'The preserve at Boulder Hills'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Boostinsider is the largest influencer and celebrity marketing platform.',
                    externalLink: 'www.boostinsider.com',
                    logoUrl: 'upload/feed/image/157797821af-57966aab0075ef4a7b015f0d-3d84f3f7c72a9972',
                    name: 'Boostinsider'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Yalber specializes in a variety of financial products, mainly Royalty-Based Investments.',
                    externalLink: 'http://www.yalber.com/',
                    logoUrl: 'upload/feed/image/157797b81e3-57966aab0075ef4a7b015f0d-798e7b7edebdfe66',
                    name: 'Yalber'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Waycook is an app to help you share and explore home-made food in your neighborhood.',
                    externalLink: 'https://itunes.apple.com/us/app/waycook-order-seamless-homemade/id1004552356?mt=8',
                    logoUrl: 'upload/feed/image/157797c7076-57966aab0075ef4a7b015f0d-03b4df20bd17c580',
                    name: 'Waycook'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Phase from the heart',
                    externalLink: 'http://www.hanli.photos/',
                    logoUrl: 'upload/feed/image/157797d9847-57966aab0075ef4a7b015f0d-f71190d77922ba09',
                    name: 'Han Li Photography'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Create a series of documentaries and interviews to provide a brand-new visual experience for the cultural differences between China and the United States.',
                    logoUrl: 'upload/feed/image/157797e125a-57966aab0075ef4a7b015f0d-f2e943977658b2f8',
                    name: 'Arkview Media'
                }
            }
        ],
        enRandomMedia: [
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Ebrun.com is a specialized e-commerce media that acts as an influential magazine in the e-commercial industry.',
                    externalLink: 'http://www.ebrun.com/',
                    logoUrl: 'upload/feed/image/156e26e4779-57966aab0075ef4a7b015f0d-ffaad7ad38a61a62',
                    name: 'Ebrun'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'ITerduo is a intelligent hardware technology media. Providing evaluation articles for the most advanced technology products. We have settled in number of media platforms and served thousands of hardware enthusiasts.',
                    externalLink: 'http://www.iterduo.com/',
                    logoUrl: 'upload/feed/image/156e2712f0c-57966aab0075ef4a7b015f0d-d4b3e7aa1720517b',
                    name: 'ITerduo'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Mandarin Quarterly (MQ) is an award-winning Chinese-language media brand offering Chinese residents in North America access to mainstream business, fashion, and lifestyle. MQ offers readers an annual national Travel issue, plus city-centric editions available in Chicago, New York City and San Francisco.',
                    externalLink: 'http://www.mandarinquarterly.com/',
                    logoUrl: 'upload/feed/image/156e2721457-57966aab0075ef4a7b015f0d-676ccc8e747bda52',
                    name: 'Mandarin Quarterly (MQ)'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Collegedaily.cn is Chinese largest independent new media for students. Students can tell their own stories and share their life.',
                    externalLink: 'http://www.collegedaily.cn/',
                    logoUrl: 'upload/feed/image/156e27346c0-57966aab0075ef4a7b015f0d-e28aa8c432097ac8',
                    name: 'Collegedaily.cn'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        '17shang is a palce to gathering high-quality entrepreneurial activity. By linking quality entrepreneurs together, we grow up with young entrepreneurs.',
                    externalLink: 'http://www.17shang.cc/',
                    logoUrl: 'upload/feed/image/156e275674b-57966aab0075ef4a7b015f0d-e48d31268d3b3163',
                    name: '17shang'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Record the most exciting student life and do the most beautiful short videos.',
                    externalLink: 'http://v.qq.com/vplus/onthewheel',
                    logoUrl: 'upload/feed/image/156e6ca1f72-57966aab0075ef4a7b015f0d-f4d30528a595a2a0',
                    name: 'onthewheel'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Silicon Valley technology media.  Bring you the latest product reports. Read Silicon Valley; See Silicon Valley; To Silicon Valley.',
                    externalLink: 'http://www.svinsight.com',
                    logoUrl: 'upload/feed/image/156e6cb49a9-57966aab0075ef4a7b015f0d-25c04e9940df4f3e',
                    name: 'svinsight'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Yixia Tech, the Beijing developer behind Chinese video blogging app Miao Pai, has raised $200 million in Series D funding from microblogging platform Weibo, Sequoia Capital, YG Entertainment, and other investors at a valuation of $1 billion.',
                    externalLink: 'http://www.yixia.com/',
                    logoUrl: 'upload/feed/image/156e6cbc008-57966aab0075ef4a7b015f0d-8d635c6834f5f795',
                    name: 'Yixia Tech'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        "The first Seattle IT people's club. Whether you are program ape, energnier or product manger, you are welcome to join the circle.",
                    logoUrl: 'upload/feed/image/156e6cd0894-57966aab0075ef4a7b015f0d-eeea43d109ebf448',
                    name: 'seattleit'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        "ihoushi is a entrepreneurs' media founded in 2015 in Shanghai. We focus on reporting the eraly-starge innovative technologies.",
                    externalLink: 'http://www.ihoushi.com/',
                    logoUrl: 'upload/feed/image/156e6d3d0d3-57966aab0075ef4a7b015f0d-543b1da1c2628ce7',
                    name: 'ihoushi'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        "Founded in November, 1998, Tencent, Inc. has grown into China's largest and most used Internet service portal.",
                    externalLink: 'http://www.qq.com/',
                    logoUrl: 'upload/feed/image/156e6d4a85e-57966aab0075ef4a7b015f0d-7dd46a3aae1b7a92',
                    name: 'Tencent'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Sohu.com Inc. (NASDAQ: SOHU) is China’s premier online brand and indispensable to the daily life of millions of Chinese, providing a network of web properties and community based/web 2.0 products which offer the vast Sohu user community a broad array of choices regarding information, entertainment and communication.',
                    externalLink: 'http://www.sohu.com/',
                    logoUrl: 'upload/feed/image/156e6d766ae-57966aab0075ef4a7b015f0d-7d6583fbafb475ec',
                    name: 'SOHU'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'xinhua08 is sponsored by Xinhua financial websites. We dedeicate to build Chinese most influential financial information platform.',
                    externalLink: 'http://www.xinhua08.com',
                    logoUrl: 'upload/feed/image/156e6d8070c-57966aab0075ef4a7b015f0d-e5af3b3b9b72b4b9',
                    name: 'xinhua08'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Zhimahiao （www.ZhiMaQiao.com）belongs to Ambright Group. It is the first online recruiting platform for Chinese overseas student.',
                    externalLink: 'www.ZhiMaQiao.com',
                    logoUrl: 'upload/feed/image/156e6d8ba94-57966aab0075ef4a7b015f0d-8b5d6ef6381847a6',
                    name: 'ZhiMaQiao'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Fame NYC focuses on gethering the most high-end luxury life informationin in New York City.',
                    externalLink: 'http://weibo.com/u/5902671235?refer_flag=1005050010_&is_hot=1',
                    logoUrl: 'upload/feed/image/156e6d96706-57966aab0075ef4a7b015f0d-09d9efb30602cb9d',
                    name: 'FameNYC'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        "Rceo is pioneering entrepernuer's media brand. Platform now has tens thousands entrepreneurs and investors and 21 offices in China.",
                    externalLink: 'http://www.xiouhui.com',
                    logoUrl: 'upload/feed/image/156e6da0c65-57966aab0075ef4a7b015f0d-18215f5d2c545361',
                    name: 'Xiou'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Chinese Radio Seattle LLC (founded in May 2012) is radio programming for the Chinese community in the greater Seattle area.  We are proud to provide Chinese news programs, special programs on topics of living, health, travel, education, movie, books, love, and family etc, and programs of Chinese songs, music, and entertainment, as well as talk shows. Broadcast in Mandarin.',
                    externalLink: 'https://chineseradioseattle.com',
                    logoUrl: 'upload/feed/image/156e6dae0b4-57966aab0075ef4a7b015f0d-4e4d33d8d250047f',
                    name: 'Chinese Radio Seattle LLC'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        "PR Newswire's news distribution, targeting, monitoring and marketing solutions help you to connect and engage with target audiences across the globe.",
                    externalLink: 'http://en.prnasia.com',
                    logoUrl: 'upload/feed/image/156e6dcbe7f-57966aab0075ef4a7b015f0d-15241c4a399e8fa1',
                    name: 'PR Newswire'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: "China's leading business & technology information service provider.",
                    externalLink: 'http://www.tmtpost.com',
                    logoUrl: 'upload/feed/image/156e6dd86a5-57966aab0075ef4a7b015f0d-09d36f02277a4bbb',
                    name: 'TMT'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: "Cyzone, founded in 2007, is China's leading professional business service platform.",
                    externalLink: 'http://www.cyzone.cn',
                    logoUrl: 'upload/feed/image/156e6de540f-57966aab0075ef4a7b015f0d-5374c81f141c767b',
                    name: 'Cyzone'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Tuoniao is a new media focuses on original technology start-up companies.',
                    externalLink: 'http://tuoniao.fm',
                    logoUrl: 'upload/feed/image/156e6df724f-57966aab0075ef4a7b015f0d-93f554042b2cabae',
                    name: 'Tuoniao'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'B12 is an internet media focuses on entrepreneurship and investment.',
                    externalLink: 'http://mp.i.sohu.com/suc6898784/profile',
                    logoUrl: 'upload/feed/image/156e6e1d842-57966aab0075ef4a7b015f0d-d57337c23a053c79',
                    name: 'B12'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Lieyunwang focuses on entrepreneurship, innovation, and recommedations of internet venture projects.',
                    externalLink: 'http://www.lieyunwang.com',
                    logoUrl: 'upload/feed/image/156e6e2dbef-57966aab0075ef4a7b015f0d-aa679bba20285c84',
                    name: 'lieyunwang'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Founded in 1998, Harvard China Forum is the leading student organized North American conference dedicated to a constructive dialogue on the challenges, trends, and issues affecting China.',
                    externalLink: 'http://www.harvardchina.org',
                    logoUrl: 'upload/feed/image/156e6e42832-57966aab0075ef4a7b015f0d-022743fbb0a79c37',
                    name: 'Harvard China Forum'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'From unique perspective render science and technology.',
                    externalLink: 'http://tech.163.com',
                    logoUrl: 'upload/feed/image/156e6e4d9e6-57966aab0075ef4a7b015f0d-db1277eeefb7f5d7',
                    name: 'tech.163.com'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        '58.com is a classified advertisements website serving local merchants and consumers in China',
                    externalLink: 'http://g.58.com/city',
                    logoUrl: 'upload/feed/image/156e6e6847c-57966aab0075ef4a7b015f0d-d2144e925f71806d',
                    name: '58.com'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Yiouwang is a new tech media outlet focuses on O2O.',
                    externalLink: 'www.iyiou.com',
                    logoUrl: 'upload/feed/image/156e6e84897-57966aab0075ef4a7b015f0d-5d8a0caf937bd2e8',
                    name: 'iyiou'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Sina Finance provides 7X24 global financial information.',
                    externalLink: 'http://finance.sina.com.cn',
                    logoUrl: 'upload/feed/image/156e6e9f1ba-57966aab0075ef4a7b015f0d-6f59d4617319c573',
                    name: 'finance.sina'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Global Chinese mainstream financial portal. We provide users with the best professional financial information and data application services.',
                    externalLink: 'http://finance.ifeng.com',
                    logoUrl: 'upload/feed/image/156e6eae231-57966aab0075ef4a7b015f0d-9571b667aa2c5955',
                    name: 'finance.ifeng.com'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'SinoVision is a U.S.-based Chinese language television network.',
                    externalLink: 'http://www.sinovision.net',
                    logoUrl: 'upload/feed/image/156e7464585-57966aab0075ef4a7b015f0d-b40282b8db0a9876',
                    name: 'SinoVision'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'The world first integrated platform provides content and mobile marketing service.',
                    externalLink: 'http://www.morketing.cn/aboutUs',
                    logoUrl: 'upload/feed/image/156e7478bf7-57966aab0075ef4a7b015f0d-fbeb13a729756fa9',
                    name: 'Morketing'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        "Seattle Entrepreneurship Club (SEC) was founded by a group of professionals and entrepreneurship enthusiasts in the Greater Seattle Area. Its mission is to network the high-level professionals and entrepreneurs around Seattle, build a communication and collaboration platform for startup business, venture capital, and professionals, and support different parties' pursuit of success, so as to encourage the startup activities and enrich the entrepreneurship culture in the Seattle community and beyond.",
                    externalLink: 'http://www.seattlestartup.org',
                    logoUrl: 'upload/feed/image/156e7486e84-57966aab0075ef4a7b015f0d-88df14aefbaad04d',
                    name: 'Seattle Entrepreneurship Club'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        "Yahoo! Finance is a media property that is part of Yahoo!'s network. It provides financial news, data and commentary including stock, press releases, financial reports, and original programming. It also offers some online tools for finance management.",
                    externalLink: 'https://finance.yahoo.com/',
                    logoUrl: 'upload/feed/image/156e761956f-57966aab0075ef4a7b015f0d-15ba5f9cdb988e7d',
                    name: 'Yahoo! Finance'
                }
            },
            {
                teamCategory: 200,
                data: {
                    description: 'Exclusive go-to for Chinese expats in Boston.',
                    teamId: '57d9a687388cb31e027a0b38',
                    logoUrl: 'upload/team/image/1572a2ff74f-569ea3909508a64f04bef0fa-91f927aebb323561',
                    name: '波士顿龙虾君',
                    slogan: 'Exclusive go-to for Chinese expats in Boston.'
                }
            }
        ],
        enRandomPartners: [
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'With over 40 years of experience, Related Companies is one of the largest private real estate developers in the United States. Related operates its own EB-5 regional centers offering subscribers direct access to the project developer. Headquartered in New York City, Related has offices and major developments in the United States and in Abu Dhabi, London, São Paulo and Shanghai overseas. Related boasts a team of approximately 3,000 professionals.',
                    externalLink: 'http://www.relatedusa.com/cn',
                    logoUrl: 'upload/feed/image/157109e43f4-57966aab0075ef4a7b015f0d-be317aeab8c1d393',
                    name: '瑞联集团'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'China Science & Merchants Investment Management Group (CSC), founded in 2000, is a preeminent private investment firm in China with US $10 Billion (RMB ¥ 60 Billion) of capital under management. CSC owns an extensive network in China via its local partners. Currently CSC covers more than 100 cities and has built relationships with over 1000 LPs. This network provides an unique platform for CSC to acquire deep market insights and local know-hows, thus gives CSC the ability to identify valuable opportunities, as well as offer operational support to extract these values for its investors.',
                    externalLink: 'http://www.leadvc.com/',
                    logoUrl: 'upload/feed/image/15710a2b4d4-57966aab0075ef4a7b015f0d-506788bcb8e6a083',
                    name: 'China Science & Merchants Investment Management Group (CSC)'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Uhouzz Network Technology Co., Ltd. is invested by New Oriental (US listed company), Leo Group (A-share listed company) and UTour (A-share listed company). As an internet company, we have already completed the pre-A round of financing. Founded on January 19, 2015, in China, we provide our services mainly through our online platform. We are devoted to being the authority in student rentals and foreign real estates buying for students studying abroad. Now Uhouzz is a leading company in providing an easy-to-use online platform for overseas students through all necessary services.',
                    externalLink: 'http://www.uhouzz.com/',
                    logoUrl: 'upload/feed/image/15710a5fb85-57966aab0075ef4a7b015f0d-71493e489511641e',
                    name: 'Uhouzz Network Technology Co., Ltd.'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Beijing Thai investment Management Ltd. by Mr. Yulong Wen, Ms. Sun Yan joint New Oriental Education Group Chairman Michael Yu, Louisiana United First Commercial Bank founder Qin Yue Ji and other famous entrepreneurs jointly set up, it is focused on seed and angel stage private equity fund management company. Domestic companies in Thailand have launched a parallel series of the Fund and the establishment of overseas funds, based on the famous universities, research institutes, business park, incubation centers and other gathering places venture projects in the world looking for investment potential of the high-tech business projects, efforts to build a first-class angel investment funds.',
                    externalLink: 'http://www.taiyoufund.com/',
                    logoUrl: 'upload/feed/image/15710a47e31-57966aab0075ef4a7b015f0d-75e89b1465f7afc9',
                    name: 'Beijing Taiyou Investment Management Co., Ltd'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'An online consulting + knowledge sharing platform',
                    externalLink: 'http://www.wenxiaoyou.com/',
                    logoUrl: 'upload/feed/image/157308aa9d7-55a037aad1f41e51044f6071-2fad1763f539540e',
                    name: '问校友科技有限公司'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'With offices in both California and New York, MagStone Law, LLP (doing business in New York as Lai, Liu & Li, LLP) is a modern law firm dedicated to providing solution-focused, cost-effective, and high quality legal services to our clients. We are all experienced lawyers with backgrounds from the most prominent international law firms.  Our practice covers all aspects of corporate transactional law, including business formation, financing, licensing, corporate governance, mergers & acquisitions and SEC compliance.  Known for our expertise on China cross-border transactions, we have become the go-to law firm for many Chinese companies exploring the U.S. market.',
                    externalLink: 'http://www.magstonelaw.net/',
                    logoUrl: 'upload/feed/image/157308a5d74-55a037aad1f41e51044f6071-40b4f54cd12c2d13',
                    name: 'MagStone Law'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Henry Global Consulting Group（HGCG）is a professional organization providing services in wide areas including study abroad and visa consulting, immigration agent, overseas family settlement and new business development, related to different countries such as the United States, Canada, Australia and England, etc.',
                    externalLink: 'http://www.visa800.com/',
                    logoUrl: 'upload/feed/image/15710a81df7-57966aab0075ef4a7b015f0d-8ee436796e063f4e',
                    name: 'Henry Global Consulting Group（HGCG）'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Westlake Ventures is a newly established venture capital in Redwood City, California focusing on investing in early stage technology start-ups, especially into those tech companies that have their own core technology or made advances in scientific technology. We work to find potential startups and companies in their growth stage and to manage capital for strong investments. The company has enjoyed extraordinary growth since its inception.',
                    externalLink: 'http://www.westlakevc.com/',
                    logoUrl: 'upload/feed/image/15710a99951-57966aab0075ef4a7b015f0d-eecf7998fc0a32d2',
                    name: 'Westlake Ventures'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan: 'Chinese short video world extension',
                    externalLink: 'http://wap.huaplus.tv/',
                    logoUrl: 'upload/feed/image/15743e0fa0f-57966aab0075ef4a7b015f0d-7807767788666a5e',
                    name: 'HUA PLUS'
                }
            },
            {
                teamCategory: 100,
                data: {
                    slogan:
                        'Located just a mile up the street from Fenway Park or a five minute walk from Coolidge Corner, Takusan Sushi brings a (not your ordinary) Modern Asian Fusion Cuisine restaurant to Brookline, MA.  Executive Chef and Owner Shun Chen presents contemporary cuisine derived from region’s across Asia and only works with fresh ingredients to create unique and absolutely delicious dishes.',
                    externalLink: 'http://takusansushi.com/',
                    logoUrl: 'upload/feed/image/15710ab2778-57966aab0075ef4a7b015f0d-848bb8b9e6b7e9c9',
                    name: 'Takusan Sushi'
                }
            }
        ]
    }
});

angular.module('summit.filter', [])
    .filter('toImageUrl', ['Config', function (Config) {
        return function (key, widthInPx, heightInPx, mode) {
            if (key) {
                return Config.GetImageUrlWithCDN(key, mode || 'crop', widthInPx, heightInPx);
            } else {
                return 'assets/images/avatars/avatar-default.svg';
            }
        };
    }])
    .filter('toExternalUrl', [function () {
        return function (url) {
            if (url && url.indexOf('http://') !== 0 && url.indexOf('https://') !== 0) {
                return 'http://' + url;
            }
            return url;
        };
    }])
    .filter('toLiaoyuanTeamUrl', ['Config', function (Config) {
        return function (teamId) {
            return Config.FrontUrl + '/team/' + teamId + '/';
        };
    }]);
angular.module('summit.directive', ['duScroll'])
    .directive('speakerCard', function () {
        return {
            templateUrl: 'templates/directives/speakerCard.html',
            scope: {
                item: '='
            },
            restrict: 'E',
            replace: true
        };
    })
    .directive('roundSpeakerProfile', function () {
        return {
            templateUrl: 'templates/directives/roundSpeakerProfile.html',
            scope: {
                speaker: '='
            },
            restrict: 'E',
            replace: true
        };
    })
    .directive('squareTeamOrPartner', ['Config', function (Config) {
        return {
            templateUrl: 'templates/directives/squareTeamOrPartner.html',
            scope: {
                logoUrl: '=',
                name: '=',
                slogan: '= ',
                data: '= '
            },
            restrict: 'E',
            replace: true,
            link: function (scope) {
                var base = Config.FrontUrl + '/team/';
                scope.visitTeamOrPartner = function () {
                    var url = scope.data.teamId ? (base + scope.data.teamId + '/') : scope.data.externalLink;
                    window.open(url, '_blank');
                };
            }
        };
    }])
    .directive('teamCard', ['Config', function (Config) {
        return {
            templateUrl: 'templates/directives/teamCard.html',
            restrict: 'E',
            replace: true,
            scope: {
                item: '='
            },
            link: function (scope, element, attr) {
                scope.goToExternalLink = function (externalLink) {

                    if (externalLink) {
                        var regex = /^http/;

                        if (regex.test(externalLink.trim())) {
                            window.open(externalLink);
                            return true;
                        }

                        window.open('http://' + externalLink);
                        return true;
                    }
                };
                scope.goToTeam = function (teamId) {
                    var teamUrl = Config.FrontUrl + '/team/' + teamId;
                    window.open(teamUrl);
                    return true;
                };
            }
        };
    }])
    .directive('lyImageSlider', ['$timeout', function ($timeout) {
        return {
            templateUrl: 'templates/directives/imageSlider.html',
            scope: {
                images: '=item',
                lang: '=lyLang'
            },
            restrict: 'E',
            replace: true,
            link: function (scope, element, attrs) {
                scope.slider = {
                    nowShowing: 0,
                    waitTime: 6000,
                    chooseItem: function (index) {
                        scope.slider.nowShowing = index;
                    },
                    nextItem: function () {
                        scope.slider.nowShowing = scope.slider.nowShowing < 2 ? scope.slider.nowShowing + 1 : 0;
                        scope.slider.autoSlide();
                    },
                    prevItem: function () {
                        scope.slider.nowShowing = scope.slider.nowShowing > 0 ? scope.slider.nowShowing - 1 : 2;
                        scope.slider.autoSlide();
                    },
                    autoSlide: function () {
                        if (scope.slider.auto) {
                            $timeout.cancel(scope.slider.auto);
                        }
                        scope.slider.auto = $timeout(function () {
                            scope.slider.nextItem();
                        }, scope.slider.waitTime);
                    }
                };
                scope.slider.autoSlide();
            }
        };
    }])
    .directive('lypTipsCompatibility', ['$rootScope', function ($rootScope) {
        return {
            templateUrl: 'templates/directives/tipsCompatibility.html',
            restrict: 'E',
            replace: true,
            link: function (scope, element, attrs) {
                scope.isShowingTipCompatibility = false;
                scope.isWeixin = false;
                scope.closeTipsCompatibility = function () {
                    scope.isShowingTipCompatibility = false;
                };

                if (!($rootScope.isChrome || $rootScope.isWebKit) || ($rootScope.isSafari && !$rootScope.isChrome && !$rootScope.isIpad && !$rootScope.isIphone) || $rootScope.isOldVersion || $rootScope.isIE) {
                    scope.isShowingTipCompatibility = true;
                    if ($rootScope.isWeixin) {
                        scope.isWeixin = true;
                    }
                }
            }
        };
    }])
    .directive('sectionIndicator', [function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {number: '@', title: '@'},
            template: '<div class="section-indicator">' +
            '<h2 class="number" ng-bind="number"></h2>' +
            '<h2 class="title" ng-bind="title"></h2>' +
            '</div>'
        };
    }])

    .directive('secondPageHeader', [function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {title: '@'},
            template: '<div class="second-page-header">' +
            '<h1 ng-bind="title"></h1>' + '</div>'
        };
    }])
    .directive('secondPageTicketAndSponsor', ['Config', function (Config) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'templates/directives/secondPageTicketAndSponsor.html',
            link: function (scope) {
                scope.getTicket = function () {
                    window.open(Config.TicketFeedUrl, '_blank');
                };
            }
        };
    }])
    .directive('lyOnLoad', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                element.bind('load', function () {
                    scope.$apply(attrs.lyOnLoad);
                });
            }
        };
    })


    /**
     * Decorate `md-content` directive.
     */
    .config(['$provide', function ($provide) {
        $provide.decorator('mdContentDirective', ['$delegate', '$parse', 'loader', '$timeout', '$window', function ($delegate, $parse, loader, $timeout, $window) {
            var directive = $delegate[0];
            var originalLink = directive.link;
            directive.compile = function () {
                return function (scope, element, attrs) {
                    originalLink.apply(this, arguments);
                    var actions = {
                        scrollToTop: function (skipAnimation) {
                            if (skipAnimation) {
                                element.scrollTop(0);
                            } else {
                                element.scrollTopAnimated(0);
                            }
                        },

                        scrollToAnchor: function (anchorId, skipAnimation) {
                            if (anchorId && scope.anchors[anchorId]) {
                                if (skipAnimation) {
                                    element.scrollToElement(scope.anchors[anchorId]);
                                } else {
                                    element.scrollToElementAnimated(scope.anchors[anchorId]);
                                }
                            }
                        }
                    };

                    var setActions = $parse(attrs.actions).assign;
                    if (setActions) {
                        setActions(scope, actions);
                    }

                    if (attrs.onLoadMore) {
                        var checkLoadMore = function () {
                            var scrollTop = window.scrollY;
                            var scrollHeight = element.prop('scrollHeight');
                            if (scrollTop + 1450 > scrollHeight) {
                                scope.$eval(attrs.onLoadMore).then(function () {
                                    $timeout(checkLoadMore, 500);
                                }).catch(function () {
                                });
                            }
                        };
                        angular.element($window).on('scroll', checkLoadMore);
                        checkLoadMore();
                    }
                };
            };
            directive.controller = ['$scope', '$element', function ($scope, $element) {
                var anchors = $scope.anchors = {};
                this.register = function (anchorId, element) {
                    anchors[anchorId] = element;
                };
            }];
            return $delegate;
        }]);
    }]);



/**
 * Created by saber on 16/7/7.
 */

angular.module('summit.service', [])
    .factory('helper', ['$rootScope', '$q', '$timeout', '$document', function ($rootScope, $q, $timeout, $document) {

        var scrollTop = function () {
            $document.scrollTop(0);
        };

        var scrollTopAnimated = function () {
            $document.scrollTopAnimated(0, 500);
        };

        function load(createElement) {
            var promises = {};
            return function (src) {
                if (typeof promises[src] === 'undefined') {
                    var deferred = $q.defer();
                    var element = createElement(src);
                    element.onload = element.onreadystatechange = function (e) {
                        if (element.readyState && element.readyState !== 'complete' && element.readyState !== 'loaded') {
                            return;
                        }
                        $timeout(function () {
                            deferred.resolve(e);
                        });
                    };
                    element.onerror = function (e) {
                        $timeout(function () {
                            deferred.reject(e);
                        });
                    };
                    promises[src] = deferred.promise;
                }
                return promises[src];
            };
        }

        var loadScript = load(function (src) {
            var document = $document[0];
            var script = document.createElement('script');
            script.src = src;
            document.body.appendChild(script);
            return script;
        });

        return {
            scrollTop: scrollTop,
            scrollTopAnimated: scrollTopAnimated,
            loadScript: loadScript
        };
    }])

    .factory('loader', ['$q', 'rx',
        function ($q, rx) {
            function Loader(options) {
                var self = this;
                var deferredFirst = $q.defer();
                var isFirst = true;
                var items = [];
                var pageSize = options.pageSize || 10;
                var pageInfo = {
                    pageSize: pageSize,
                    res: {}
                };

                self.hasMore = true;
                self.observable = new rx.BehaviorSubject();
                self.items = [];

                var watchedValue;
                var currentLoadMoreDeferred;

                var loadMore = function (watchField) {

                    if (!self.hasMore) {
                        return $q.reject('ended');
                    }

                    if (!watchField && currentLoadMoreDeferred) {
                        return currentLoadMoreDeferred.promise;
                    }
                    currentLoadMoreDeferred = $q.defer();

                    var requestValue;

                    var promise = options.query(function (params) {
                        watchedValue = params[watchField];
                        requestValue = params[watchField];
                        var res = angular.extend({
                            pageSize: pageInfo.pageSize
                        }, params);
                        if (pageInfo.marker) {
                            res.marker = pageInfo.marker;
                        }
                        return res;
                    });

                    return promise.then(function (res) {
                        if (watchedValue !== requestValue) {
                            return false;
                        }
                        var newItems = res.items || [];
                        if (options.map) {
                            newItems = _.map(newItems, options.map);
                        }
                        items = items.concat(newItems);
                        self.hasMore = !!res.marker;
                        if (isFirst && items && items[0]) {
                            isFirst = false;
                            deferredFirst.resolve(items[0]);
                        }
                        pageInfo.marker = res.marker;
                        pageInfo.newItems = newItems;
                        pageInfo.res = res;
                        self.publish();
                        currentLoadMoreDeferred.resolve(true);
                        currentLoadMoreDeferred = false;
                        return true;
                    }).catch(function (err) {
                        currentLoadMoreDeferred.reject(err);
                        currentLoadMoreDeferred = false;
                    });
                };

                this.reset = function () {
                    pageInfo = {
                        pageSize: pageSize,
                        res: {}
                    };
                    items = [];
                    self.hasMore = true;
                };

                this.search = function (watchField) {
                    self.reset();
                    return loadMore(watchField);
                };

                this.loadMore = loadMore;

                this.getFirst = function () {
                    if (items && items[0]) {
                        return $q.when(items[0]);
                    } else {
                        return deferredFirst.promise;
                    }
                };

                this.splice = function () {
                    if (arguments.length > 2) {
                        return items.splice(arguments[0], arguments[1], arguments[2]);
                    } else {
                        return items.splice(arguments[0], arguments[1]);
                    }
                };

                this.unshift = function (newVal) {
                    return items.unshift(newVal);
                };

                this.publish = function () {
                    self.items = items.slice();
                    self.count = pageInfo.res.count;
                    self.observable.onNext({
                        items: self.items,
                        newItems: pageInfo.newItems,
                        hasUnread: pageInfo.res.hasUnread,
                        count: pageInfo.res.count,
                        res: pageInfo.res,
                        hasMore: !!pageInfo.res.marker
                    });
                };
            }

            return Loader;
        }
    ])

    .factory('weixin', ['$http', 'Config', '$q', '$timeout', function ($http, Config, $q, $timeout) {
        var weixinSignatureAPIUrl = Config.ServerUrl + Config.Target.weixinSignature;

        function config() {
            return $q(function (resolve, reject) {
                $timeout(function () {
                    resolve(location.href.split('#')[0]);
                }, 50);
            }).then(function (url) {
                return $http.post(weixinSignatureAPIUrl, {url: url});
            }).then(function (res) {
                var data = res.data;
                var params = {
                    appId: data.appId,
                    timestamp: data.timestamp,
                    nonceStr: data.nonceStr,
                    signature: data.signature,
                    jsApiList: [
                        'onMenuShareTimeline',
                        'onMenuShareAppMessage',
                        'onMenuShareQQ'
                    ]
                };
                wx.config(params);
                return $q(function (resolve, reject) {
                    wx.ready(function () {
                        resolve();
                    });
                    wx.error(function (err) {
                        console.log(err);
                        reject(err);
                    });
                });
            });
        }

        function setup(shareParams) {
            return $q(function (resolve, reject) {
                wx.ready(function () {
                    wx.onMenuShareAppMessage({
                        title: shareParams.title,
                        desc: shareParams.desc,
                        link: shareParams.link,
                        imgUrl: shareParams.imgUrl
                    });
                    wx.onMenuShareTimeline({
                        title: shareParams.only,
                        link: shareParams.link,
                        imgUrl: shareParams.imgUrl
                    });
                    wx.onMenuShareQQ({
                        title: shareParams.title,
                        desc: shareParams.desc,
                        link: shareParams.link,
                        imgUrl: shareParams.imgUrl
                    });
                    resolve();
                });
            });
        }

        return config().then(function () {
            return setup(Config.WeixinShareParams);
        });
    }])
;
angular.module('summit.content', [])
    .factory('teamViewModel', ['$http', 'Config', 'loader', function ($http, Config, loader) {

        var base = Config.ServerUrl + '/summit/';

        var zhTeamsUrl = base + Config.SummitTeamFeedIds.zhTeamFeedId;
        var enTeamsUrl = base + Config.SummitTeamFeedIds.enTeamFeedId;
        var zhMediaUrl = base + Config.SummitTeamFeedIds.zhMediaFeedId;
        var enMediaUrl = base + Config.SummitTeamFeedIds.enMediaFeedId;
        var zhPartnerUrl = base + Config.SummitTeamFeedIds.zhPartnerFeedId;
        var enPartnerUrl = base + Config.SummitTeamFeedIds.enPartnerFeedId;

        var async = function (url, params) {
            if (params && params.pageSize) {
                url += '?pageSize=' + params.pageSize;
            }
            if (params && params.marker) {
                url += '&marker=' + params.marker;
            }
            return $http.get(url, params).then(function (res) {
                return res.data;
            }).catch(function (err) {
                console.error(err);
            });
        };

        var zhTeamsLoader = new loader({
            pageSize: 18,
            query: function (query) {
                return async(zhTeamsUrl, query({}));
            }
        });

        var enTeamsLoader = new loader({
            pageSize: 18,
            query: function (query) {
                return async(enTeamsUrl, query({}));
            }
        });

        var zhMediaLoader = new loader({
            pageSize: 18,
            query: function (query) {
                return async(zhMediaUrl, query({}));
            }
        });

        var enMediaLoader = new loader({
            pageSize: 18,
            query: function (query) {
                return async(enMediaUrl, query({}));
            }
        });

        var zhPartnerLoader = new loader({
            pageSize: 18,
            query: function (query) {
                return async(zhPartnerUrl, query({}));
            }
        });

        var enPartnerLoader = new loader({
            pageSize: 18,
            query: function (query) {
                return async(enPartnerUrl, query({}));
            }
        });

        return {
            zhTeamsLoader: zhTeamsLoader,
            enTeamsLoader: enTeamsLoader,
            getZhTeams: async(zhTeamsUrl + '?random=12'),
            getEnTeams: async(enTeamsUrl + '?random=12'),
            zhMediaLoader: zhMediaLoader,
            enMediaLoader: enMediaLoader,
            getZhMedia: async(zhMediaUrl),
            getEnMedia: async(enMediaUrl),
            zhPartnerLoader: zhPartnerLoader,
            enPartnerLoader: enPartnerLoader,
            getZhPartner: async(zhPartnerUrl),
            getEnPartner: async(enPartnerUrl)
        };
    }])
;
angular
    .module('summit')
    .controller('CtrlMain', [
        '$rootScope',
        '$scope',
        '$location',
        '$anchorScroll',
        '$document',
        '$mdDialog',
        'helper',
        'Config',
        '$window',
        function ($rootScope, $scope, $location, $anchorScroll, $document, $mdDialog, helper, Config, $window) {
            var vm = this;
            $scope.$on('$stateChangeSuccess', function (event, toState, toParams) {
                helper.scrollTop();
                vm.isMainSection = toState.name === 'main.section';
            });
            vm.ticketFeedUrl = Config.TicketFeedUrl;
            vm.isShowingSidebar = false;
            vm.toggleSidebar = function () {
                vm.isShowingSidebar = !vm.isShowingSidebar;
            };
            vm.closeSidebar = function () {
                vm.isShowingSidebar = false;
            };
            vm.scrollTop = function () {
                helper.scrollTopAnimated();
            };
            vm.scrollToSection = function (hashName) {
                vm.isShowingSidebar = false;
                $location.hash(hashName);
                $anchorScroll();
            };
            $rootScope.$watch('currentSection', function () {
                vm.currentSection = $rootScope.currentSection;
            });
            angular.element($window).bind('resize', function (e) {
                $scope.$apply();
                var width = e.target.innerWidth;
                if (width >= 960 && vm.isShowingSidebar) {
                    vm.isShowingSidebar = false;
                }
            });
            return vm;
        }
    ])
    .controller('CtrlMainSection', [
        '$scope',
        '$translate',
        'Locale',
        '$rootScope',
        'Speaker',
        'Team',
        'Config',
        '$window',
        '$document',
        'helper',
        'teamViewModel',
        '$interval',
        '$timeout',
        function ($scope,
                  $translate,
                  Locale,
                  $rootScope,
                  Speaker,
                  Team,
                  Config,
                  $window,
                  $document,
                  helper,
                  teamViewModel,
                  $interval,
                  $timeout) {
            var vm = this;
            vm.lang = $rootScope.lang;
            $scope.$on('lang', function (event, lang) {
                vm.lang = lang;
            });
            // var size = 12;

            vm.daySep29 = Locale[$rootScope.lang].agenda[0];
            vm.daySep30 = Locale[$rootScope.lang].agenda[1];
            vm.dayOct1 = Locale[$rootScope.lang].agenda[2];

            // vm.zhTeams = _.sampleSize(Locale.zh.zhRandomTeams, size);
            // vm.enTeams = _.sampleSize(Locale.en.enRandomTeams, size);
            // vm.zhMedia = _.sampleSize(Locale.zh.zhRandomMedia, size);
            // vm.enMedia = _.sampleSize(Locale.en.enRandomMedia, size);

            vm.currentSpeaker = 100;
            vm.switchSpeaker = function (event, key) {
                event.stopPropagation();
                event.preventDefault();
                vm.currentSpeaker = key;
            };
            vm.speaker = Speaker;
            vm.venueMapsUrl = Config.VenueMapsUrl;

            // 指示器
            var document = $document[0];
            var partner = document.getElementById('partner');
            var speaker = document.getElementById('speaker');
            var agenda = document.getElementById('agenda');
            var review = document.getElementById('review');
            var team = document.getElementById('team');
            var media = document.getElementById('media');
            var contact = document.getElementById('contact');

            function getCurrentSection() {
                $rootScope.$apply();
                var scrollY = this.scrollY + 75;
                if (scrollY > contact.offsetTop) {
                    $rootScope.currentSection = 'contact';
                } else if (scrollY > media.offsetTop) {
                    $rootScope.currentSection = 'media';
                } else if (scrollY > team.offsetTop) {
                    $rootScope.currentSection = 'team';
                } else if (scrollY > review.offsetTop) {
                    $rootScope.currentSection = 'review';
                } else if (scrollY > agenda.offsetTop) {
                    $rootScope.currentSection = 'agenda';
                } else if (scrollY > speaker.offsetTop) {
                    $rootScope.currentSection = 'speaker';
                } else if (scrollY > partner.offsetTop) {
                    $rootScope.currentSection = 'partner';
                } else {
                    $rootScope.currentSection = 'first';
                }
            }

            vm.reviewWords = [
                {contents: '王岑在分论坛二参与讨论如何抓住消费升级投资机会', title: '分论坛二', englishTitle: 'Panel 2'},
                {contents: '峰瑞资本创始人李丰在主论坛分享人工智能', title: '主论坛', englishTitle: 'Main Session'},
                {
                    contents: '阳光印网创始人 & CEO 张红梅, National Securities 资深注册投资顾问 付少远 在主论坛讨论企业出海及海外资产配置',
                    title: '主论坛',
                    englishTitle: 'Main Session'
                },
                {
                    contents:
                        '苏奇 Modelo 联合创始人 & CEO，Millie Liu Procyon Ventures 联合创始人，别西 Menusifu，Ben Tao PTC Corporate Development VP 分论坛三讨论 SaaS 企业如何突围',
                    title: '分论坛三',
                    englishTitle: 'Panel 3'
                },
                {
                    contents: '孙权 资本动力集团有限公司 CEO，梁恩泽 Marcum Bernstein & Pinchuk LLP 董事 分论坛三中国企业出海的模式探讨',
                    title: '分论坛三',
                    englishTitle: 'Panel 3'
                },
                {
                    contents: '林国宇 留学生日报创始人，崔易宁 易美留学创始人，April Huang 北美省钱快报东岸负责人 分论坛二讨论留学生如何创业',
                    title: '分论坛二',
                    englishTitle: 'Panel 2'
                },
                {
                    contents: '李轶 Amazon AI Services 项目负责人，刘亦婷 秦岭资本合伙人 分论坛二讨论海外求职及工作职场生存',
                    title: '分论坛二',
                    englishTitle: 'Panel 2'
                },
                {contents: '刘亦婷 秦岭资本合伙人 分论坛二讨论海外求职及工作职场生存', title: '分论坛二', englishTitle: 'Panel 2'},
                {contents: '分论坛二如何抓住消费升级投资机会讨论现场', title: '分论坛二', englishTitle: 'Panel 2'},
                {contents: '专场项目路演对接会, 3D Bean 负责人现场介绍', title: '分会场', englishTitle: 'Panel'},
                {contents: '海外项目人才交流会现场', title: '主会场', englishTitle: 'Main Session'},
                {contents: '海外项目人才交流会现场', title: '主会场', englishTitle: 'Main Session'},
                {contents: '陈丁鸿 好未来海外业务首席顾问 在分会场的教育科技分享会现场', title: '分会场', englishTitle: 'Panel'},
                {
                    contents:
                    '好未来海外业务首席顾问 陈丁鸿，IBIS Capital 投资银行合伙人 Vincent Fung，RoboTerra 创始人 & CEO、世界经济论坛人工智能与机器人特别委员 张尧，Sunny Crown 联合创始⼈ 王冠\n' +
                    '教育科技分享会现场',
                    title: '分会场',
                    englishTitle: 'Panel'
                },
                {contents: '海外项目人才交流会现场', title: '主会场', englishTitle: 'Main Session'},
                {
                    contents: 'NYIS Law Firm managing attorney Vera Su 分享法律专场 - 留学生创业以及创业身份',
                    title: '分会场',
                    englishTitle: 'Panel'
                },
                {contents: '做做联合创始人 & 媒体黑客 朱沈昊辰 分享创业早期如何拓展市场', title: '分会场', englishTitle: 'Panel'},
                {contents: 'Rstorm 联合创始人 姚冬伟 分享创业早期如何拓展市场', title: '分会场', englishTitle: 'Panel'},
                {contents: '红杉资本中国合伙人 王岑在主会场分享消费升级', title: '分会场', englishTitle: 'Panel'},
                {contents: '峰会现场观众热情高涨', title: '分会场', englishTitle: 'Panel'},
                {contents: '红杉资本中国合伙人 王岑在主会场分享消费升级', title: '分会场', englishTitle: 'Panel'},
                {
                    contents: '斯坦福客座教授 & 福布斯全球 30 under 30 邹昊，秦岭资本合伙人 刘亦婷，比特币及区块链行业天使投资⼈ 郭宏才 在主论坛分享科技金融的创新',
                    title: '主论坛',
                    englishTitle: 'Main Session'
                },
                {
                    contents: '斯坦福客座教授 & 福布斯全球 30 under 30 邹昊, 秦岭资本合伙人 刘亦婷, 比特币及区块链行业天使投资⼈ 郭宏才 在主论坛分享科技金融的创新',
                    title: '主论坛',
                    englishTitle: 'Main Session'
                },
                {
                    contents: '光速中国助理合伙人 潘翔,  PersonalVC 联合创始人 & 哈佛商学院的 EIR David Chang 在主论坛分享中美创新投资与趋势',
                    title: '主论坛',
                    englishTitle: 'Main Session'
                },
                {
                    contents: '美中合作发展委员会执行主席 & 上海张江波士顿企业园董事长 李建生 在主论坛分享在美创业如何获得美国政府和企业资源',
                    title: '主论坛',
                    englishTitle: 'Main Session'
                },
                {
                    contents: 'Bloomberg & Dow Jones 前 CEO、Heckyl. CEO Lex Fenwick 在主论坛分享华尔街科技金融',
                    title: '主论坛',
                    englishTitle: 'Main Session'
                },
                {
                    contents: 'Masschallenge 全球合作总监 Brittany McDonough 在主论坛分享加速孵化模式',
                    title: '主论坛',
                    englishTitle: 'Main Session'
                },
                {
                    contents:
                    //TODO: @yuanlin 这里还是有省略号
                        '万通证券 COO 郭易, 荷马金融 联合创始人 & CIO George Chen,  阳光印网创始…ational Securities 资深注册投资顾问 付少远 在主论坛分享企业出海及海外资产配置',
                    title: '主论坛',
                    englishTitle: 'Main Session'
                },
                {
                    contents:
                        '丰元资本管理合伙人 徐霄羽，Fusion Fund 创始合伙人 & 福布斯美国30/30 张璐， RoboTerra 创始人 & CEO,世界经济论坛人工智能与机器人特别委员 张尧，IDG资本 北美项目经理 王昱 在主论坛分享深度聚焦 – 人工智能',
                    title: '主论坛',
                    englishTitle: 'Main Session'
                },
                {
                    contents: 'Fusion Fund 创始合伙人 & 福布斯美国30/30 张璐在主论坛分享深度聚焦 – 人工智能',
                    title: '主论坛',
                    englishTitle: 'Main Session'
                },
                {
                    contents: 'RoboTerra 创始人 & CEO,世界经济论坛人工智能与机器人特别委员 张尧在主论坛分享深度聚焦 – 人工智能',
                    title: '主论坛',
                    englishTitle: 'Main Session'
                }
            ];

            // 精彩回顾xs相关
            vm.currentReviewXsIndex = 0;
            vm.reviewDetails = Array.apply(0, new Array(31)).map(function (val, index) {
                return {
                    title: '会场' + (index + 1),
                    description: ''
                };
            });
            var animateXsInterval;

            function animateXs(mode) {
                var currentIndex = vm.currentReviewXsIndex;
                switch (mode) {
                    case 'previous':
                        vm.currentReviewXsIndex = currentIndex === 0 ? 30 : currentIndex - 1;
                        break;
                    case 'next':
                        vm.currentReviewXsIndex = currentIndex === 30 ? 0 : currentIndex + 1;
                        break;
                    default:
                        break;
                }
            }

            function startAnimateXs() {
                animateXsInterval = $interval(function () {
                    animateXs('next');
                }, 6000);
            }

            function cancelAnimateXs() {
                if (animateXsInterval) {
                    $interval.cancel(animateXsInterval);
                }
            }

            vm.animateXs = animateXs;
            vm.startAnimateXs = startAnimateXs;
            vm.cancelAnimateXs = cancelAnimateXs;

            // 精彩回顾large相关
            var animatePhotoLargeInterval;
            var mainHiddenPhotoLarge = document.getElementById('main-hidden-photo');
            var mainPhotoLarge = document.getElementById('main-photo');
            var nextPhotoLarge = document.getElementById('next-photo');
            var nextHiddenPhotoLarge = document.getElementById('next-hidden-photo');
            var mainPhotoCover = document.getElementById('main-background-cover');
            var nextPhotoCover = document.getElementById('next-photo-background-cover');

            function increaseIndex(num) {
                return num === 31 ? 1 : num + 1;
            }

            function decreaseIndex(num) {
                return num === 1 ? 31 : num - 1;
            }

            vm.mainHiddenPhotoIndex = 31;
            vm.mainPhotoIndex = 1;
            vm.nextPhotoIndex = 2;
            vm.nextHiddenPhotoIndex = 3;
            vm.isAnimationOn = false;

            var animateLargePrev = function () {
                if (vm.isAnimationOn) return;
                vm.isAnimationOn = true;
                mainPhotoLarge.className = 'main-move';
                mainHiddenPhotoLarge.className = 'main-hidden-photo-move';
                mainPhotoCover.className = 'background-cover cover transition';
                $timeout(function () {
                    vm.isAnimationOn = false;
                    mainPhotoCover.className = 'background-cover no-cover';
                    mainPhotoLarge.className = 'main-original';
                    mainHiddenPhotoLarge.className = 'main-hidden-photo-original';
                    vm.mainHiddenPhotoIndex = decreaseIndex(vm.mainHiddenPhotoIndex);
                    vm.mainPhotoIndex = decreaseIndex(vm.mainPhotoIndex);
                    vm.nextPhotoIndex = decreaseIndex(vm.nextPhotoIndex);
                    vm.nextHiddenPhotoIndex = decreaseIndex(vm.nextHiddenPhotoIndex);
                }, 1000);
            };
            vm.animateLargePrev = function () {
                cancelAnimateLarge();
                animateLargePrev();
            };
            var animateLargeNext = function () {
                if (vm.isAnimationOn) return;
                vm.isAnimationOn = true;
                nextPhotoLarge.className = 'next-move';
                nextHiddenPhotoLarge.className = 'next-hidden-photo-move';
                nextPhotoCover.className = 'background-cover no-cover transition';
                $timeout(function () {
                    vm.isAnimationOn = false;
                    nextPhotoCover.className = 'background-cover cover';
                    nextPhotoLarge.className = 'next-original';
                    nextHiddenPhotoLarge.className = 'background-cover next-hidden-photo-original';
                    vm.mainHiddenPhotoIndex = increaseIndex(vm.mainHiddenPhotoIndex);
                    vm.mainPhotoIndex = increaseIndex(vm.mainPhotoIndex);
                    vm.nextPhotoIndex = increaseIndex(vm.nextPhotoIndex);
                    vm.nextHiddenPhotoIndex = increaseIndex(vm.nextHiddenPhotoIndex);
                }, 1000);
            };
            vm.animateLargeNext = function () {
                cancelAnimateLarge();
                animateLargeNext();
            };

            function startAnimateLarge() {
                animatePhotoLargeInterval = $interval(function () {
                    animateLargeNext();
                }, 6000);
            }

            function cancelAnimateLarge() {
                if (animatePhotoLargeInterval) {
                    $interval.cancel(animatePhotoLargeInterval);
                }
            }

            vm.startAnimateLarge = startAnimateLarge;
            vm.cancelAnimateLarge = cancelAnimateLarge;
            startAnimateLarge();

            // other
            angular.element($window).bind('scroll', getCurrentSection);
            vm.scrollTop = function () {
                helper.scrollTopAnimated();
            };
            vm.getTicket = function () {
                window.open(Config.TicketFeedUrl, '_blank');
            };
            teamViewModel.getZhTeams.then(function (res) {
                vm.zhTeams = res && res.items;
            });
            teamViewModel.getEnTeams.then(function (res) {
                vm.enTeams = res && res.items;
            });
            teamViewModel.getZhMedia.then(function (res) {
                vm.zhMedia = res && res.items;
            });
            teamViewModel.getEnMedia.then(function (res) {
                vm.enMedia = res && res.items;
            });
            teamViewModel.getZhPartner.then(function (res) {
                vm.zhPartners = res && res.items;
            });
            teamViewModel.getEnPartner.then(function (res) {
                vm.enPartners = res && res.items;
            });
            $rootScope.$watch('currentSection', function (newValue) {
                vm.currentSection = newValue;
            });
            $scope.$on('$destroy', function () {
                angular.element($window).off('scroll', getCurrentSection);
                cancelAnimateXs();
                cancelAnimateLarge();
            });
            return vm;
        }
    ])
    .controller('CtrlMainSpeaker', [
        'Speaker',
        'helper',
        function (Speaker, helper) {
            var vm = this;
            vm.currentSpeaker = 100;
            vm.speaker = Speaker;
            vm.switchSpeaker = function (event, key) {
                event.stopPropagation();
                event.preventDefault();
                vm.currentSpeaker = key;
            };
            return vm;
        }
    ])
    .controller('CtrlMainTeam', [
        'teamViewModel',
        'Locale',
        '$scope',
        function (teamViewModel, Locale, $scope) {
            var vm = this;
            var zhTeamsLoader = teamViewModel.zhTeamsLoader;
            var enTeamsLoader = teamViewModel.enTeamsLoader;
            var zhSubscription = zhTeamsLoader.observable.subscribe(function (data) {
                vm.zhTeams = data && data.items;
            });
            var enSubscription = enTeamsLoader.observable.subscribe(function (data) {
                vm.enTeams = data && data.items;
            });

            $scope.onLoadMore = function () {
                if ($scope.lang === 'en') {
                    return enTeamsLoader.loadMore();
                }
                return zhTeamsLoader.loadMore();
            };
            zhTeamsLoader.search();
            enTeamsLoader.search();
            $scope.$on('$destroy', function () {
                if (zhSubscription) {
                    zhSubscription.dispose();
                }
                if (enSubscription) {
                    enSubscription.dispose();
                }
            });
            return vm;
        }
    ])
    .controller('CtrlMainMedia', [
        'Locale',
        '$scope',
        function (Locale, $scope) {
            var vm = this;
            var zhMediaLoader = teamViewModel.zhMediaLoader;
            var enMediaLoader = teamViewModel.enMediaLoader;
            var zhSubscription = zhMediaLoader.observable.subscribe(function (data) {
                vm.zhMedia = data && data.items;
            });
            var enSubscription = enMediaLoader.observable.subscribe(function (data) {
                vm.enMedia = data && data.items;
            });

            $scope.onLoadMore = function () {
                if ($scope.lang === 'en') {
                    return enMediaLoader.loadMore();
                }
                return zhMediaLoader.loadMore();
            };
            zhMediaLoader.search();
            enMediaLoader.search();
            $scope.$on('$destroy', function () {
                if (zhSubscription) {
                    zhSubscription.dispose();
                }
                if (enSubscription) {
                    enSubscription.dispose();
                }
            });
            return vm;
        }
    ])
    .controller('CtrlMainPartner', [
        'teamViewModel',
        'Locale',
        '$scope',
        function (teamViewModel, Locale, $scope) {
            var vm = this;
            var zhPartnerLoader = teamViewModel.zhPartnerLoader;
            var enPartnerLoader = teamViewModel.enPartnerLoader;
            var zhSubscription = zhPartnerLoader.observable.subscribe(function (data) {
                vm.zhPartners = data && data.items;
            });
            var enSubscription = enPartnerLoader.observable.subscribe(function (data) {
                vm.enPartners = data && data.items;
            });

            $scope.onLoadMore = function () {
                if ($scope.lang === 'en') {
                    return enPartnerLoader.loadMore();
                }
                return zhPartnerLoader.loadMore();
            };
            zhPartnerLoader.search();
            enPartnerLoader.search();
            $scope.$on('$destroy', function () {
                if (zhSubscription) {
                    zhSubscription.dispose();
                }
                if (enSubscription) {
                    enSubscription.dispose();
                }
            });
            return vm;
        }
    ])
    .controller('CtrlMainApply', [
        '$state',
        '$rootScope',
        '$mdDialog',
        '$document',
        'Config',
        '$http',
        function ($state, $rootScope, $mdDialog, $document, Config, $http) {
            var vm = this;
            $document.scrollTop(0, 0);
            vm.applyUrl = Config.ServerUrl + Config.Target.apply;
            vm.company = {
                name: '',
                site: '',
                contactsName: '',
                position: '',
                contactsPhone: '',
                contactsEmail: ''
            };

            var validateForm = function (vm) {
                return (
                    vm.company.name &&
                    vm.company.name.length &&
                    vm.company.contactsEmail &&
                    vm.company.contactsEmail.length
                );
            };

            var sendForm = function (applyUrl, body, lang) {
                return $http.post(applyUrl, {
                    contentBody: body,
                    lang: lang
                });
            };

            var showSuccessAlert = function ($event) {
                return $mdDialog.show({
                    controller: [
                        '$state',
                        '$scope',
                        '$mdDialog',
                        function ($state, $scope, $mdDialog) {
                            $scope.close = function () {
                                $mdDialog.hide();
                                $state.go('main.section');
                            };
                        }
                    ],
                    targetEvent: $event,
                    templateUrl: 'templates/dialogs/applySubmitted.html'
                });
            };

            var showErrorAlert = function ($event) {
                return $mdDialog.show({
                    controller: [
                        '$state',
                        '$scope',
                        '$mdDialog',
                        function ($state, $scope, $mdDialog) {
                            $scope.close = function () {
                                $mdDialog.hide();
                            };
                        }
                    ],
                    targetEvent: $event,
                    templateUrl: 'templates/dialogs/applyError.html'
                });
            };

            vm.submit = function (applyUrl, body, $event, lang) {
                var formValidation = validateForm(vm);

                if (formValidation) {
                    return sendForm(applyUrl, body, lang).then(function () {
                        $state.go('main.applyConfirmation');
                    });
                }
                return showErrorAlert($event);
            };
            return vm;
        }
    ])
    .controller('CtrlMainApplyConfirmation', [
        'Config',
        '$document',
        function (Config, $document) {
            $document.scrollTop(0, 0);
            var vm = this;

            vm.lyCreateTeam = 'https://liaoyuan.io/main/team';
            vm.lySignUp = 'https://liaoyuan.io/enter';
            vm.goToUrl = function (url) {
                window.open(url, '_blank');
            };
        }
    ]);
